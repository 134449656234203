import { TipoDadoLogEnum } from '../../ts/enums/TipoDadoLogEnum';

export const recuperarTipoDadoLog = (tipoDado: TipoDadoLogEnum): string => {
	switch (tipoDado) {
		case TipoDadoLogEnum.Processo:
			return 'Processo';
		case TipoDadoLogEnum.Obrigacao:
			return 'Obrigação';
		case TipoDadoLogEnum.Anexo:
			return 'Anexo';
		case TipoDadoLogEnum.Ocorrencia:
			return 'Ocorrência';
		case TipoDadoLogEnum.Origem:
			return 'Origem';
		case TipoDadoLogEnum.Classificacao:
			return 'Classificação';
		case TipoDadoLogEnum.Usuario:
			return 'Usuário';
		case TipoDadoLogEnum.Acesso:
			return 'Acesso';
	}
};
