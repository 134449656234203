import { useEffect } from 'react';
// FreshdeskWidget.tsx ou global.d.ts
declare global {
    interface Window {
      fwSettings?: {
        widget_id: number;
      };
    }
  }
  
const FreshdeskWidget = () => {
  useEffect(() => {
    // Configura o widget do Freshdesk
    window.fwSettings = { 'widget_id': 73000003709 };

    // Cria a tag script
    const script = document.createElement('script');
    script.src = 'https://widget.freshworks.com/widgets/73000003709.js';
    script.async = true;
    script.defer = true;

    // Adiciona o script ao body
    document.body.appendChild(script);

    // Remove o script quando o componente for desmontado
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // O componente não renderiza nada na tela
};

export default FreshdeskWidget;
