import { Badge, Button, Icons } from '@jcm/design-system';
import { memo, useEffect, useState } from 'react';
import NotificacoesUsuario from '../../NotificacoesUsuario';
import { useGovernancaContext } from '../../../../../../context/GovernancaContext';
import { TNovidade } from '../../../ts/types/Novidade';

export type NotificationsIconButtonProps = { id?: string };

export const NotificationsIconButton = memo<NotificationsIconButtonProps>(({ id }) => {
	const { notificacoes } = useGovernancaContext();
	const [isOpen, setIsOpen] = useState(false);

	const isFetching = false;

	useEffect(() => {
		if (notificacoes.some((item: TNovidade) => item.descricao)) {
			setIsOpen(true);
		}
	}, [notificacoes]);

	return (
		<>
			<Badge count={notificacoes?.filter((item) => item.descricao).length}>
				<Button
					circle
					id={id}
					type='text'
					variant='default'
					icon={<Icons.Notifications variant='outlined' fill={isOpen} />}
					loading={isFetching}
					onClick={() => setIsOpen((ev) => !ev)}
				/>
			</Badge>

			<NotificacoesUsuario
				notificacoesUsuarioAberto={isOpen}
				setNotificacoesUsuarioAberto={(ev) => {
					setIsOpen(ev);
				}}
				notificacoes={notificacoes}
			/>
		</>
	);
});
