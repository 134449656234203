import React, { useState } from 'react';
import estilo from './index.module.scss';
import OperacoesTabela from './OperacoesTabela';
import BarraAcoes from './BarraAcoes';
import { TObrigacao } from '../../ts/types/Obrigacoes';

interface OperacoesEmMassaProps {
	empresaId: string | number;
	onObrigacaoChange: (obrigacaoAtualizada: TObrigacao) => void;
	obrigacoes: TObrigacao[];
	onAtualizar: () => void;
}

const OperacoesEmMassa: React.FC<OperacoesEmMassaProps> = ({
	empresaId,
	onObrigacaoChange,
	obrigacoes,
	onAtualizar,
}) => {
	const [obrigacoesSelecionadas, setObrigacoesSelecionadas] = useState<number[]>([]);
	const [loading, setLoading] = useState(false);

	const aoAlterarSelecao = (selecionadas: number[]) => {
		setObrigacoesSelecionadas(selecionadas);
	};

	const atualizarInterface = async () => {
		setLoading(true);
		await onAtualizar();
		setLoading(false);
	};

	return (
		<div className={estilo.operacoesEmMassaContainer}>
			<h1>Obrigações</h1>
			<BarraAcoes
				empresaId={empresaId}
				obrigacoesSelecionadas={obrigacoesSelecionadas}
				aoAtualizar={atualizarInterface}
			/>

			<OperacoesTabela
				empresaId={empresaId}
				obrigacoes={obrigacoes}
				onObrigacaoChange={onObrigacaoChange}
				onAtualizar={atualizarInterface}
				onSelectChange={aoAlterarSelecao}
				loading={loading}
			/>
		</div>
	);
};

export default OperacoesEmMassa;
