import { Link } from 'react-router-dom';

export default function HomeModuloObrigacoes(): JSX.Element {
	return (
		<div>
			<h1>Módulo de Obrigações</h1>

			<label>
				<Link to='/'>Voltar para o Governança</Link>
			</label>
		</div>
	);
}
