import { Button, Col, Flex, Row, Surface, Typography } from '@jcm/design-system';
import estilo from './index.module.scss';
import OrigemIndividualListagem from './OrigemIndividualListagem';
import { useState, useEffect } from 'react';
import ObrigacoesService from '../../../services/Obrigacoes/Obrigacoes.service';
import { useGovernancaContext } from '../../../../../../context/GovernancaContext';
import { TOrigem } from '../../../ts/types/Origem';
import Lottie from 'lottie-react';
import LoadingListaObrigacoes from '../../../../../../assets/loadings/CarregandoListaObrigacoes.json';

export default function Origem(): JSX.Element {
	const [origens, setOrigens] = useState<TOrigem[]>([]);
	const [isCreating, setIsCreating] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState<number | null>(null); // ID da origem sendo editada
	const [newOrigemId, setNewOrigemId] = useState<number | null>(null); // Estado para armazenar o ID do novo item
	const [loading, setLoading] = useState(true);

	const usuariosService = new ObrigacoesService();
	const { usuario, empresaAtiva } = useGovernancaContext();

	async function fetchOrigens(): Promise<TOrigem[]> {
		setLoading(true);

		if (empresaAtiva !== null) {
			const origensResponse = await usuariosService.getAllOrigem(empresaAtiva);
			setOrigens(origensResponse);
			return origensResponse;
		}

		return [];
	}

	useEffect(() => {
		async function initComponent() {
			const fetchedOrigens = await fetchOrigens();
			setOrigens(fetchedOrigens);
			setLoading(false);
		}

		if (usuario !== null && (usuario?.empresaSalvaId || usuario?.participacaoEmpresa.length)) {
			initComponent();
		}
	}, [usuario, empresaAtiva]);

	const handleCreate = () => {
		setIsCreating(true);
		setIsEditing(null);

		const id = Date.now(); // Id temporário gerado somente para listagem estática
		setNewOrigemId(id);

		const newOrigem = { origemId: id, nomeOrigem: '' };
		setOrigens((prevOrigens) => [newOrigem, ...prevOrigens]);
	};

	const handleCancel = () => {
		if (newOrigemId !== null) {
			setOrigens((prevOrigens) => prevOrigens.filter((origem) => origem.origemId !== newOrigemId));
			setNewOrigemId(null);
		}
		setIsCreating(false);
		setIsEditing(null);
	};

	return (
		<Surface variant='default' className={estilo.containerOrigem}>
			<Row gutter={[16, 16]}>
				<Col xs={24}>
					<Flex justify='space-between'>
						<Typography.Title size='large'>Origem</Typography.Title>

							<Button onClick={handleCreate} disabled={!(!isCreating && !isEditing)} style={{ height: '36px' }} type='filled-tonal' variant='default'>
								Nova origem
							</Button>
					</Flex>
				</Col>

				<Col xs={24}>
					<Row>
						<Col xs={24}>
							{loading ? (
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
									<Lottie loop animationData={LoadingListaObrigacoes} style={{ width: '50%', height: '50%' }} />
								</div>
							) : (
								<>
									{origens.length > 0 ? (
										<>
											<OrigemIndividualListagem
												origens={origens}
												setOrigens={setOrigens}
												empresaId={empresaAtiva || ''}
												handleCreate={handleCreate}
												isCreating={isCreating}
												isEditing={isEditing}
												setIsEditing={setIsEditing}
												setIsCreating={setIsCreating}
												handleCancel={handleCancel}
												newOrigemId={newOrigemId}
												setNewOrigemId={setNewOrigemId}
											/>
										</>
									) : (
										<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
											<Flex justify='center'>
												<Typography.Headline>Não há Origens a serem listadas.</Typography.Headline>
											</Flex>
										</div>
									)}
								</>
							)}
						</Col>
					</Row>
				</Col>
			</Row>
		</Surface>
	);
}
