import React, { useState } from 'react';
import { Button } from '@jcm/design-system';
import estilo from './index.module.scss';
import ModalEdicaoEmMassa from '../ModalEdicaoEmMassa';
import { Drawer, Tooltip, Popconfirm } from 'antd';
import CustomAlert from '../../../../../../shared/components/customAlert/customAlert';
import { CloseOutlined, ReloadOutlined } from '@ant-design/icons';
import ObrigacoesService from '../../../services/Obrigacoes/Obrigacoes.service';
import BarraDeProgresso from '../../../components/ProgressBar/BarraDeProgresso';
import ModalContainer from '../../../components/modalContainer/ModalContainer';

type AlertType = {
	type: 'error' | 'success' | 'info';
	content: string;
	title?: string;
	key?: number;
};

interface BarraAcoesProps {
	empresaId: number | string;
	obrigacoesSelecionadas: number[];
	aoAtualizar?: () => void;
}

const noop = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

const BarraAcoes: React.FC<BarraAcoesProps> = ({ empresaId, obrigacoesSelecionadas, aoAtualizar }) => {
	const [drawerVisivel, setDrawerVisivel] = useState(false);
	const [alerta, setAlerta] = useState<AlertType | null>(null);
	const [popconfirmVisivel, setPopconfirmVisivel] = useState(false);
	const [activeKeys, setActiveKeys] = useState<string[]>([]);
	const [resetForm, setResetForm] = useState<() => void>(noop);
	const [progresso, setProgresso] = useState(0);
	const [tempoEstimado, setTempoEstimado] = useState<number | undefined>(undefined);
	const [isExcluindo, setIsExcluindo] = useState(false);

	const obrigacoesService = new ObrigacoesService();

	const exibirAlerta = (tipo: AlertType['type'], conteudo: string, titulo?: string) => {
		setAlerta({
			type: tipo,
			content: conteudo,
			title: titulo,
			key: Date.now(),
		});
	};

	const aoClicarEmEditar = () => {
		if (obrigacoesSelecionadas.length < 1) {
			exibirAlerta('info', 'Selecione ao menos uma obrigação para editar.', 'Atenção!');
			return;
		}
		setAlerta(null);
		setDrawerVisivel(true);
	};

	const confirmarExclusao = async () => {
		setIsExcluindo(true);
		setProgresso(0);
		const totalObrigacoes = obrigacoesSelecionadas.length;
		const incremento = Math.round((100 / totalObrigacoes) * 100) / 100;

		setTempoEstimado(Number((totalObrigacoes * 0.5).toFixed(2)));

		try {
			for (let i = 0; i < totalObrigacoes; i++) {
				await obrigacoesService.excluirObrigacoesEmMassa(empresaId, [obrigacoesSelecionadas[i]]);
				setProgresso((prev) => Math.min(prev + incremento, 100));
			}

			exibirAlerta('success', 'Obrigações excluídas com sucesso!', 'Sucesso');
			aoAtualizar?.();
		} catch (error: unknown) {
			console.error('Erro ao excluir obrigações:', error);
			exibirAlerta(
				'error',
				error instanceof Error ? error.message : 'Erro desconhecido. Por favor, tente novamente.',
				'Erro',
			);
		} finally {
			setIsExcluindo(false);
			setPopconfirmVisivel(false);
		}
	};

	const aoClicarExcluir = () => {
		if (obrigacoesSelecionadas.length < 1) {
			exibirAlerta('info', 'Selecione ao menos uma obrigação para excluir.', 'Atenção!');
			return;
		}
		setPopconfirmVisivel(true);
	};

	const limparModificacoes = () => {
		resetForm();
		setActiveKeys([]);
	};

	const aoFecharModal = () => {
		if (progresso === 100) setIsExcluindo(false);
	};

	return (
		<>
			<div className={estilo.barraAcoesContainer}>
				<ExcluirPopconfirm
					visivel={popconfirmVisivel}
					onConfirm={confirmarExclusao}
					onCancel={() => setPopconfirmVisivel(false)}
				>
					<Button type='outlined' variant='error' onClick={aoClicarExcluir} className={estilo.botaoExcluir}>
						Excluir Obrigações
					</Button>
				</ExcluirPopconfirm>
				<Button type='filled' variant='default' onClick={aoClicarEmEditar} className={estilo.botaoEditar}>
					Editar Selecionados
				</Button>
			</div>

			{alerta && <CustomAlert key={alerta.key} type={alerta.type} content={alerta.content} title={alerta.title} />}

			<DrawerComponent
				visivel={drawerVisivel}
				onClose={() => setDrawerVisivel(false)}
				limparModificacoes={limparModificacoes}
			>
				<ModalEdicaoEmMassa
					empresaId={empresaId}
					selectedObrigacoes={obrigacoesSelecionadas}
					onClose={() => setDrawerVisivel(false)}
					onAtualizar={aoAtualizar ?? noop}
					resetForm={setResetForm}
					setActiveKeys={setActiveKeys}
					activeKeys={activeKeys}
				/>
			</DrawerComponent>

			{isExcluindo && (
				<ModalContainer
					isVisible={isExcluindo}
					onClose={aoFecharModal}
					title='Excluindo Obrigações...'
					fechamentoPermitido={progresso === 100}
				>
					<BarraDeProgresso cor='#38939c' progresso={progresso} tempoEstimado={tempoEstimado} />
				</ModalContainer>
			)}
		</>
	);
};

export default BarraAcoes;

// Componentes auxiliares
const ExcluirPopconfirm: React.FC<{
	visivel: boolean;
	onConfirm: () => void;
	onCancel: () => void;
	children: React.ReactNode;
}> = ({ visivel, onConfirm, onCancel, children }) => (
	<Popconfirm
		title='Você tem certeza que deseja excluir as obrigações selecionadas?'
		open={visivel}
		onConfirm={onConfirm}
		onCancel={onCancel}
		okText='Sim, excluir!'
		cancelText='Cancelar'
	>
		{children}
	</Popconfirm>
);

const DrawerComponent: React.FC<{
	visivel: boolean;
	onClose: () => void;
	limparModificacoes: () => void;
	children: React.ReactNode;
}> = ({ visivel, onClose, limparModificacoes, children }) => (
	<Drawer
		className={estilo.drawer}
		closable={false}
		destroyOnClose
		placement='right'
		open={visivel}
		onClose={onClose}
		width='42%'
		styles={{
			body: { padding: 0, height: '100%' },
			wrapper: { height: '100vh' },
		}}
	>
		<div className={estilo.drawerHeader}>
			<Tooltip title='Limpar modificações'>
				<Button
					type='text'
					icon={<ReloadOutlined className={estilo.iconButton} />}
					onClick={limparModificacoes}
					className={estilo.clearButton}
				/>
			</Tooltip>
			<Tooltip title='Fechar'>
				<Button
					type='text'
					icon={<CloseOutlined className={estilo.iconButton} />}
					onClick={onClose}
					className={estilo.closeButton}
				/>
			</Tooltip>
		</div>
		{children}
	</Drawer>
);
