import { Typography } from '@jcm/design-system';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import estilo from './index.module.scss';

const COLORS = ['#3EA26E', '#FAB35A', '#C00019', '#009697'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderTextoLegendaColorido = (nomeLegenda: string, valor: any) => {
	return (
		<Typography.Label size='large' strong className={estilo.legendaGraficoTorta}>
			{nomeLegenda}: {valor.payload.payload.value}
		</Typography.Label>
	);
};

type PieChartCompProps = {
	data: { name: string; value: number }[];
};

export default function PieChartComp({ data }: PieChartCompProps) {
	if (data.every((d) => d.value === 0)) {
		return null;
	}

	return (
		<PieChart width={250} height={300} margin={{ top: -40, bottom: 40 }}>
			<Pie
				data={data}
				cx='50%'
				cy='50%'
				labelLine={false}
				outerRadius={90}
				innerRadius={30}
				fill='#8884d8'
				dataKey='value'
			>
				{data.map((entry, index) => (
					<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
				))}
			</Pie>
			<Legend
				iconType='square'
				layout='centric'
				verticalAlign='bottom'
				align='left'
				iconSize={14}
				formatter={renderTextoLegendaColorido}
				wrapperStyle={{ top: '220px' }}
			/>
		</PieChart>
	);
}
