import { AxiosInstance } from 'axios';

/**
 * @param id Identificador do recurso
 * @param params Objeto com tipo `K`, corpo da requisição
 * @returns Promise que resolve pra `T`
 */
export type GetByIdFn<T, K = Record<string, unknown>> = (id: string | number, params?: K) => Promise<T>;

/**
 * @param id Identificador do recurso, podendo ser `null`
 * @param params Objeto com tipo K, corpo da requisição
 * @returns Promise que resolve pra `T`
 */
export type GetByNullableIdFn<T, K = Record<string, unknown>> = (id: string | number | null, params?: K) => Promise<T>;

/**
 * @param params Objeto com tipo K, corpo da requisição
 * @returns Promise que resolve pra `T`
 */
export type GetFn<T, K = Record<string, unknown>> = (params?: K) => Promise<T>;

/**
 * @param payload Payload a ser enviado, com tipo `T`.
 * @return Promise que resolve pra `T`.
 */
export type SendFn<T> = (payload: T) => Promise<T>;

/**
 * @param id Identificador do recurso.
 * @param payload Payload a ser enviado, com tipo `T`.
 * @return Promise que resolve pra `T`.
 */
export type SendByIdFn<T> = (id: string | number, payload: T) => Promise<T>;

/**
 * @param id Identificador do recurso, podendo ser `null`.
 * @param payload Payload a ser enviado, com tipo `T`.
 * @return Promise que resolve pra `T`.
 */
export type SendByNullableIdFn<T> = (id: string | number | null, payload: T) => Promise<T>;

export default class BaseApi {
	public api: AxiosInstance;
	private clienteId?: number | string;

	constructor(props: { api: AxiosInstance; clienteId?: number | string }) {
		this.api = props.api;
		this.clienteId = props.clienteId;

		return this;
	}

	/**
	 * @param url Url base.
	 * @returns Url tratado, com `clienteId` adicionado (caso necessário).
	 */
	private getRequestUrl = (url: string) => {
		// Caso clienteId seja definido, adicionamos à url
		const requestUrl = this.clienteId ? `/${this.clienteId}${url}` : url;

		return requestUrl;
	};

	/**
	 * Método que roda uma request (axios) com o verbo `GET`.
	 *
	 * @param url Endereço do recurso.
	 * @param params Parâmetros do corpo da requisição (pra uma pesquisa, por exemplo).
	 * @returns Promise que resolve para um valor `T`.
	 */
	protected getRequest = async <T>(url: string, params?: Record<string, unknown>): Promise<T> => {
		const requestUrl = this.getRequestUrl(url);

		const { data } = await this.api.get<T>(requestUrl, { params });

		return data;
	};

	/**
	 * Método que roda uma request (axios) com o verbo `DELETE`.
	 *
	 * @param url Endereço do recurso.
	 * @returns Promise que resolve para um valor `T`.
	 */
	protected deleteRequest = async <T>(url: string): Promise<T> => {
		const requestUrl = this.getRequestUrl(url);
		const { data } = await this.api.delete<T>(requestUrl);

		return data;
	};

	/**
	 * Método que roda uma request (axios) com o verbo `PUT`.
	 *
	 * @param url Endereço do recurso.
	 * @param payload Payload da requisição, com tipo `TRequest`.
	 * @returns Promise que resolve para um valor `TResponse`
	 */
	protected putRequest = async <TRequest, TResponse>(url: string, payload: TRequest): Promise<TResponse> => {
		const requestUrl = this.getRequestUrl(url);
		const { data } = await this.api.put<TResponse>(requestUrl, payload);

		return data;
	};

	/**
	 * Método que roda uma request (axios) com o verbo `POST`.
	 *
	 * @param url Endereço do recurso.
	 * @param payload Payload da requisição, com tipo `T`.
	 * @returns Promise que resolve para um valor `T`
	 */
	protected postRequest = async <T, P = unknown>(url: string, payload: P): Promise<T> => {
		const requestUrl = this.getRequestUrl(url);
		const { data } = await this.api.post<T>(requestUrl, payload);
		return data;
	};

	/**
	 * Método que roda uma request (axios) com o verbo `PATCH`.
	 *
	 * @param url Endereço do recurso.
	 * @param payload Payload da requisição, com tipo `TRequest`.
	 * @returns Promise que resolve para um valor `TResponse`
	 */
	protected patchRequest = async <TRequest, TResponse>(url: string, payload: TRequest): Promise<TResponse> => {
		const requestUrl = this.getRequestUrl(url);
		const { data } = await this.api.patch<TResponse>(requestUrl, payload);
		return data;
	};
}
