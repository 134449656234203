import { Modal } from '@jcm/design-system';
import styles from './index.module.scss';

interface ModalContainerProps {
	title?: string;
	children: React.ReactNode;
	isVisible: boolean;
	onClose: () => void;
	className?: string;
	fechamentoPermitido?: boolean; 
}

const ModalContainer: React.FC<ModalContainerProps> = ({
	title,
	children,
	isVisible,
	onClose,
	className,
	fechamentoPermitido = true, 
}) => {
	const handleOnClose = () => {
		if (fechamentoPermitido) {
			onClose();
		}
	};

	return (
		<Modal
			open={isVisible}
			onCancel={handleOnClose} 
			footer={null}
			className={`${styles.modalContainer} ${className}`}
			destroyOnClose={true}
			maskClosable={false}
			centered
		>
			{title && (
				<div className={styles.modalHeader}>
					<h2>{title}</h2>
				</div>
			)}
			<div className={styles.modalContent}>{children}</div>
		</Modal>
	);
};

export default ModalContainer;
