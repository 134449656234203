import { useEffect } from 'react';
import { notification } from 'antd';
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	InfoCircleOutlined,
	ExclamationCircleOutlined,
} from '@ant-design/icons';

const useNotification = (
	type: 'success' | 'error' | 'info' | 'warning',
	content: string,
	duration = 5,
	title?: string,
) => {
	useEffect(() => {
		if (content) {
			notification.open({
				message: title,
				description: content,
				duration,
				showProgress: true,
				icon: getIcon(type),
			});
		}
	}, [type, content, duration, title]);
};

const getIcon = (type: 'success' | 'error' | 'info' | 'warning') => {
	switch (type) {
		case 'success':
			return <CheckCircleOutlined style={{ color: '#52c41a' }} />;
		case 'error':
			return <CloseCircleOutlined style={{ color: '#f5222d' }} />;
		case 'info':
			return <InfoCircleOutlined style={{ color: '#1890ff' }} />;
		case 'warning':
			return <ExclamationCircleOutlined style={{ color: '#faad14' }} />;
		default:
			return null;
	}
};

export default useNotification;
