/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { Avatar, Col, Icons, Row, Typography, Flex } from '@jcm/design-system';
import { Popover } from 'antd';
import estilo from './index.module.scss';
import { TLogObrigacao } from '../../../../../ts/types/logs/LogObrigacao';
import { formatDescricao } from '../../../../../utils/logs/formatarLog';

export default function RegistroAtividade({ descricao, data, funcionario }: TLogObrigacao) {
	function formatApiDateTime(apiDateTime: string): string {
		const date = new Date(apiDateTime);

		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = String(date.getFullYear()).slice(-2);
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');

		return `${day}/${month}/${year} | ${hours}:${minutes}:${seconds}`;
	}

	function formatNomeAbreviado(nomeCompleto: string): string {
		const nomes = nomeCompleto.trim().split(' ');

		if (nomes.length === 1) {
			return nomes[0];
		}

		const primeiroNome = nomes[0];
		const ultimoNome = nomes[nomes.length - 1];

		return `${primeiroNome} ${ultimoNome[0]}.`;
	}

	return (
		<Row className={estilo.containerRegistroAtividade} gutter={[8, 8]}>
			<Col className={estilo.containerHeaderAtividades}>
				<Flex gap={6} align='center'>
					<Icons.CalendarMonth className={estilo.iconeLog} />
					<Typography.Body size='large'>{formatApiDateTime(data)}</Typography.Body>
				</Flex>
			</Col>
			<Col className={estilo.containerTextoAtividade}>
				<div className={estilo.descricao} dangerouslySetInnerHTML={{ __html: formatDescricao(descricao) }} />
			</Col>
			<Col>
				<Flex gap={8} align='center'>
					<Popover placement='bottom' content={funcionario.nome}>
						<Avatar
							style={{ backgroundColor: '#80D4D5', color: '#2F2E2E', width: '28px', height: '28px' }}
							className={estilo.avatars}
						>
							{funcionario.nome.charAt(0)}
						</Avatar>
					</Popover>

					<Typography.Label>
						{formatNomeAbreviado(funcionario.nome)}{' '}
						{funcionario.cargo && <span className={estilo.cargoSpan}>&#8226; {funcionario.cargo}</span>}
					</Typography.Label>
				</Flex>
			</Col>
		</Row>
	);
}
