import { Col, Flex } from '@jcm/design-system';
import { TLoginLayout } from './types';
import logoSistema from '../../assets/images/logos/logoSecretarias.png';
import estilos from './index.module.scss';

const LoginLayout: React.FC<TLoginLayout> = ({ children }) => {
	return (
		<Flex className={estilos.loginWrapper} vertical gap={32}>
			<Col>
				<img src={logoSistema} />
			</Col>
			<Col>{children}</Col>
		</Flex>
	);
};

export default LoginLayout;
