export function listarIntervaloAnoAtual(): { dataInicio: string; dataFim: string } {
	const hoje = new Date();

	// Data de início: 1º de janeiro do ano atual
	const dataInicio = new Date(hoje.getFullYear(), 0, 1);

	// Data de fim: 31 de dezembro do ano atual
	const dataFim = new Date(hoje.getFullYear(), 11, 31);

	const formatarData = (data: Date): string => {
		const dia = String(data.getDate()).padStart(2, '0');
		const mes = String(data.getMonth() + 1).padStart(2, '0');
		const ano = data.getFullYear();
		return `${ano}-${mes}-${dia}`;
	};

	return {
		dataInicio: formatarData(dataInicio),
		dataFim: formatarData(dataFim),
	};
}
