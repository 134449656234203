import { useGovernancaContext } from '../../../../context/GovernancaContext';

export function determinarAcaoUsuarioBotoesObrigacao(usuarioResponsavel: boolean, usuarioAprovador: boolean) {
	if (usuarioResponsavel && usuarioAprovador) {
		return 'Concluir obrigação';
	} else if (usuarioResponsavel) {
		return 'Solicitar conclusão';
	} else if (usuarioAprovador) {
		return 'Aprovar conclusão';
	} else {
		return 'Solicitar conclusão';
	}
}

export function usuarioApenasAprovador(usuarioAprovador: boolean, usuarioResponsavel: boolean): boolean {
	if (!usuarioAprovador && !usuarioResponsavel) {
		return true;
	}

	return usuarioAprovador && !usuarioResponsavel ? true : false;
}

export function usuarioEhAdmin(funcao: number | undefined, usuarioResponsavel: boolean): boolean {
	if (funcao === 1 || funcao === 2 || usuarioResponsavel) {
		return false;
	}

	return true;
}

export function obrigacaoEstaConcluida(estadoStatusAprovada: number): boolean {
	return estadoStatusAprovada === 2 ? true : false;
}

export const verificarAprovadorPrincipal = (idAprovadorObrigacao: number, aprovadoresSelecionados: number[]) => {
	const { usuario } = useGovernancaContext();

	if (!usuario || !usuario.funcionarioId) {
		return false;
	}

	const usuarioEhAprovadorPrincipal = usuario.funcionarioId === idAprovadorObrigacao;

	const usuarioEstaNaListaAprovadores = aprovadoresSelecionados.includes(usuario.funcionarioId);

	return usuarioEhAprovadorPrincipal || usuarioEstaNaListaAprovadores;
};

export const verificarResponsavelPrincipal = (idResponsavelPrincipal: number, responsaveisSelecionados: number[]) => {
	const { usuario } = useGovernancaContext();

	if (!usuario || !usuario.funcionarioId) {
		return false;
	}

	const usuarioEhResponsavelPrincipal = usuario.funcionarioId === idResponsavelPrincipal;

	const usuarioEstaNaListaResponsaveis = responsaveisSelecionados.includes(usuario.funcionarioId);

	return usuarioEhResponsavelPrincipal || usuarioEstaNaListaResponsaveis;
};

export const verificaPermissao = (
	funcao: number | undefined,
	usuarioResponsavel: boolean,
	estadoStatusAprovada: number,
	usuarioAprovador: boolean,
	isEdit: boolean,
): boolean => {
	return !usuarioEhAdmin(funcao, usuarioResponsavel)
		? false
		: obrigacaoEstaConcluida(estadoStatusAprovada) ||
				(usuarioApenasAprovador(usuarioAprovador, usuarioResponsavel) && isEdit);
};
