import React from 'react';
import { FieldError } from 'react-hook-form';
import estilo from './style.module.scss';
import { Icons } from '@jcm/design-system';

interface CampoComAvisoProps {
	rotulo: string;
	erro?: FieldError;
	children: React.ReactNode;
	isOptional?: boolean;
}

const getErrorMessage = (nomeDoCampo: string) => `${nomeDoCampo} é obrigatório`;

const CampoComAviso: React.FC<CampoComAvisoProps> = ({ rotulo, erro, children, isOptional = false }) => (
	<div className={estilo['campo-com-aviso']}>
		<label>{rotulo}</label>
		{children}

		{erro && (
			<span className={estilo['mensagem-erro']}>
				<div className={estilo['icon']}>{erro.message || getErrorMessage(rotulo)}</div>
			</span>
		)}

		{isOptional && (
			<span className={estilo['mensagem-aviso']}>
				<div className={estilo['icon']}>
					<Icons.Error variant='outlined' />
				</div>
				A informação deste campo será removida se não for preenchido
			</span>
		)}
	</div>
);

export default CampoComAviso;
