import React, { Key, useEffect, useState } from 'react';
import { Table, Input } from 'antd';
import type { TableColumnsType } from 'antd';
import ObrigacoesService from '../../../services/Obrigacoes/Obrigacoes.service';
import { useGovernancaContext } from '../../../../../../context/GovernancaContext';
import { TipoAcaoEnum } from '../../../ts/enums/TipoAcaoEnum';
import { TipoDadoLogEnum } from '../../../ts/enums/TipoDadoLogEnum';
import { recuperarTipoAcaoLog } from '../../../utils/logs/recuperarTipoAcaoLog';
import { recuperarTipoDadoLog } from '../../../utils/logs/recuperarTipoDadoLog';
import { Surface, Typography, Flex, Row, Col, Icons, Button } from '@jcm/design-system';
import estilos from './index.module.scss';
import { formatDescricao, formatDescricaoExcel } from '../../../utils/logs/formatarLog';
import { createDateFilter } from '../../Relatorios/filtros';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import UserAvatar from '../../../../../../shared/components/userProfileIcon/userProfileIcon';
import { formatarDataEHoraPtBr } from '../../../utils/datas/formatarDataEHoraPtBr';

export const gerarCampoDataLog =
	(dataField: keyof TLog) =>
	(value: Key | boolean | [string, string], record: TLog): boolean => {
		if (!Array.isArray(value) || value.length !== 2) return true;
		const [start, end] = value as [string, string];
		const recordDateValue = record[dataField];

		if (!recordDateValue) return false;

		const recordDate = dayjs(record[dataField], 'YYYY-MM-DD');
		return recordDate.isBetween(dayjs(start), dayjs(end), 'day', '[]');
	};

const RegistrosAtividades: React.FC = () => {
	const obrigacoesService = new ObrigacoesService();
	const [logs, setLogs] = useState<TLog[]>([]);
	const [logsFiltrados, setLogsFiltrados] = useState<TLog[]>([]);
	const { empresaAtiva } = useGovernancaContext();
	const [carregando, setCarregando] = useState<boolean>(false);

	useEffect(() => {
		const carregarLogs = async () => {
			if (!empresaAtiva) return;
			setCarregando(true);
			try {
				const response = await obrigacoesService.getAllLogs(empresaAtiva);
				setLogs(response.data.result);
				setLogsFiltrados(response.data.result);
			} catch (error) {
				console.error('Erro ao carregar logs:', error);
			} finally {
				setCarregando(false);
			}
		};
		carregarLogs();
	}, [empresaAtiva]);

	const exportarParaExcel = () => {
		const colunasFiltradas = colunas
			.filter((coluna) => !coluna.hidden)
			.map((coluna) => (typeof coluna.title === 'string' ? coluna.title : 'Coluna Sem Nome'));

		const dados = logsFiltrados.map((log) => {
			const dadosLinha: Record<string, string> = {}; // Definindo como Record<string, string>
			colunas.forEach((coluna) => {
				if ('dataIndex' in coluna && coluna.dataIndex) {
					dadosLinha[coluna.title as string] = obterValorColuna(coluna, log);
				}
			});
			return dadosLinha;
		});

		const workbook = XLSX.utils.book_new();
		const worksheet = XLSX.utils.json_to_sheet(dados, { header: colunasFiltradas });
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Registros');
		XLSX.writeFile(workbook, 'RegistrosAtividades.xlsx');
	};

	const obterValorColuna = (coluna: TableColumnsType<TLog>[number], log: TLog): string => {
		if ('dataIndex' in coluna) {
			switch (coluna.dataIndex) {
				case 'descricao':
					return formatDescricaoExcel(log.descricao);
				case 'tipoAcao':
					return recuperarTipoAcaoLog(log.tipoAcao);
				case 'tipoDado':
					return recuperarTipoDadoLog(log.tipoDado);
				case 'funcionario':
					return log.funcionario?.nome || '';
				default:
					return log[coluna.dataIndex as keyof TLog] || '';
			}
		}

		return '';
	};

	const colunas: TableColumnsType<TLog> = [
		{
			title: 'Usuário',
			dataIndex: 'funcionario',
			filters: Array.from(new Set(logs.map((log) => log.funcionario.nome))).map((usuario) => ({
				text: usuario,
				value: usuario,
			})),
			onFilter: (value, record) => record.funcionario.nome.includes(value as string),
			render: (funcionario) => (
				<Col className={estilos.colunaUsuario}>
					<UserAvatar nome={funcionario.nome} ativo={funcionario.ativo} />
					<Flex vertical gap={4}>
						<Typography.Body size='medium'>{funcionario.nome}</Typography.Body>
						<Typography.Body size='small'>{funcionario.email}</Typography.Body>
					</Flex>
				</Col>
			),
		},
		{
			title: 'Descrição',
			dataIndex: 'descricao',
			render: (descricao) => <span dangerouslySetInnerHTML={{ __html: formatDescricao(descricao) }} />,
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
				<div className={estilos.dropdownFiltro}>
					<Input.TextArea
						rows={3}
						placeholder='Buscar descrição'
						value={selectedKeys[0]}
						onChange={(e) => {
							const novoValor = e.target.value ? [e.target.value] : [];
							setSelectedKeys(novoValor);
						}}
						onPressEnter={() => confirm({ closeDropdown: true })}
						className={estilos.areaTexto}
					/>
					<div className={estilos.acoesFiltro}>
						<Button onClick={() => confirm({ closeDropdown: true })} className={estilos.botaoFiltro}>
							Filtrar
						</Button>
						<Button
							onClick={() => {
								clearFilters?.();
								setSelectedKeys([]);
							}}
							className={estilos.botaoFiltro}
						>
							Limpar
						</Button>
					</div>
				</div>
			),
			onFilter: (value, record) => formatDescricao(record.descricao).includes(value as string),
		},
		{
			title: 'Ação',
			dataIndex: 'tipoAcao',
			render: (tipoAcao: TipoAcaoEnum) => recuperarTipoAcaoLog(tipoAcao),
			filters: Object.entries(TipoAcaoEnum)
				.filter(([, value]) => typeof value === 'number')
				.map(([, value]) => ({
					text: recuperarTipoAcaoLog(value as TipoAcaoEnum),
					value,
				})),
			onFilter: (value, record) => record.tipoAcao === value,
		},
		{
			title: 'Dado',
			dataIndex: 'tipoDado',
			render: (tipoDado: TipoDadoLogEnum) => recuperarTipoDadoLog(tipoDado),
			filters: Object.values(TipoDadoLogEnum)
				.filter((value) => typeof value === 'number')
				.map((value) => ({
					text: recuperarTipoDadoLog(value as TipoDadoLogEnum),
					value,
				})),
			onFilter: (value, record) => record.tipoDado === value,
		},
		{
			title: 'Data e Hora',
			dataIndex: 'data',
			width: 200,
			render: (data: string) => formatarDataEHoraPtBr(data),
			sorter: (a: TLog, b: TLog) => (dayjs(a.data).isBefore(dayjs(b.data)) ? -1 : 1),
			filterDropdown: createDateFilter(),
			onFilter: gerarCampoDataLog('data'),
		},
	];

	return (
		<Surface className={estilos.containerLogs}>
			<Row gutter={[16, 16]}>
				<Col xs={24}>
					<Flex justify='space-between'>
						<Typography.Title size='large'>Registro de Atividades</Typography.Title>
						<Button onClick={exportarParaExcel}>
							<Flex align='center' gap={6}>
								<Icons.Download style={{ fontSize: '20px' }} />
								Baixar relatório
							</Flex>
						</Button>
					</Flex>
				</Col>
				<Col xs={24}>
					<Row>
						<Col xs={24}>
							<Table<TLog>
								columns={colunas}
								dataSource={logsFiltrados}
								showSorterTooltip={{ target: 'sorter-icon' }}
								pagination={{ position: ['bottomCenter'] }}
								loading={carregando}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</Surface>
	);
};

export default RegistrosAtividades;
