import React, { createContext, useState, useEffect, useContext } from 'react';
import ObrigacoesService from '../modules/modulo-obrigacoes/src/services/Obrigacoes/Obrigacoes.service';
import { TFuncionario } from '../modules/modulo-obrigacoes/src/ts/types/Funcionarios';
import { TNovidade, TNovidadeLocalStorage } from '../modules/modulo-obrigacoes/src/ts/types/Novidade';

interface Empresa {
	id: string | number;
	nome: string;
	selected: boolean;
}

interface GovernancaContextType {
	usuario: TFuncionario | null;
	carregando: boolean;
	empresas: Empresa[];
	empresaAtiva: string | number | null;
	setEmpresaAtiva: (empresaId: string | number) => void;
	setNotificacoes: (notificacao: TNovidade[]) => void;
	carregarEmpresas: () => Promise<void>;
	notificacoes: TNovidade[];
}

export const GovernancaContext = createContext<GovernancaContextType | undefined>(undefined);

interface DataProviderProps {
	children: React.ReactNode;
}

export const GovernancaContextProvider: React.FC<DataProviderProps> = ({ children }) => {
	const [usuario, setUsuario] = useState<TFuncionario | null>(null);
	const [carregando, setCarregando] = useState<boolean>(true);
	const [empresas, setEmpresas] = useState<Empresa[]>([]);
	const [empresaAtiva, setEmpresaAtivaState] = useState<string | number | null>(null);
	const [notificacoes, setNotificacoes] = useState<TNovidade[]>([]); // Adiciona o estado das notificações
	const _obrigacoesService = new ObrigacoesService();

	const setEmpresaAtiva = async (empresaId: string | number) => {
		try {
			setEmpresaAtivaState(empresaId);
			const requestBody = {
				idEmpresa: empresaId,
				idUsuario: usuario?.funcionarioId || 0,
			};

			await _obrigacoesService.updateEmpresaAtiva(empresaId, requestBody.idUsuario);
		} catch (error) {
			console.error('Erro ao atualizar empresa ativa:', error);
		}
	};

	const carregarNotificacoes = async (idEmpresa: number) => {
		try {
			if (usuario) {
				const notificacoesResponse = await _obrigacoesService.getAllNovidades(idEmpresa);

				const notificacoesApi: TNovidade[] = notificacoesResponse.data.result;

				if (notificacoesApi.length > 0) {
					const notificacoesLocalStorage: TNovidadeLocalStorage[] = JSON.parse(
						localStorage.getItem('notificacao') ?? '[]',
					);

					const novasNotificacoes = notificacoesApi.filter((notificacaoApi) => {
						return !notificacoesLocalStorage.some(
							(localItem) => localItem.novidadeId === notificacaoApi.novidadeId && localItem.visualizado,
						);
					});

					const notificacoesAtualizadas = [...notificacoesLocalStorage, ...novasNotificacoes];
					localStorage.setItem('notificacao', JSON.stringify(notificacoesAtualizadas));

					setNotificacoes(novasNotificacoes);
				}
			}
		} catch (error) {
			console.error('Erro ao carregar notificações:', error);
		}
	};

	useEffect(() => {
		const carregarUsuario = async () => {
			try {
				const usuarioResponse: TFuncionario = await _obrigacoesService.getUsuarioAutenticado();
				setUsuario(usuarioResponse);
			} catch (error) {
				console.error('Erro ao obter usuário:', error);
			} finally {
				setCarregando(false);
			}
		};
		carregarUsuario();
	}, []);

	interface EmpresaDisponivel {
		value: string | number;
		text: string;
		selected: boolean;
	}

	const carregarEmpresas = async () => {
		try {
			if (usuario) {
				const empresaId = usuario.empresaSalvaId || usuario.participacaoEmpresa?.[0]?.empresaId;
				const response = await _obrigacoesService.getEmpresasListagem(empresaId);

				if (response?.entidadesDisponiveis && Array.isArray(response.entidadesDisponiveis)) {
					const empresasList = response.entidadesDisponiveis.map((empresa: EmpresaDisponivel) => ({
						id: empresa.value,
						nome: empresa.text,
						selected: empresa.selected,
					}));
					setEmpresas(empresasList);
					const empresaSelecionada = empresasList.find((empresa: EmpresaDisponivel) => empresa.selected);

					setEmpresaAtivaState(empresaSelecionada?.id || empresasList[0]?.id);
					carregarNotificacoes(empresaSelecionada?.id || empresasList[0]?.id);
				}
			}
		} catch (error) {
			console.error('Erro ao buscar empresas:', error);
		}
	};

	useEffect(() => {
		if (usuario) {
			carregarEmpresas();
		}
	}, [usuario]);

	return (
		<GovernancaContext.Provider
			value={{
				usuario,
				carregando,
				empresas,
				empresaAtiva,
				setEmpresaAtiva,
				carregarEmpresas,
				setNotificacoes,
				notificacoes, // Fornece as notificações para os consumidores do contexto
			}}
		>
			{children}
		</GovernancaContext.Provider>
	);
};

export const useGovernancaContext = () => {
	const context = useContext(GovernancaContext);
	if (context === undefined) {
		throw new Error('useGovernancaContext deve ser usado dentro de um GovernancaContextProvider');
	}
	return context;
};
