import React, { useState } from 'react';
import { Row, Col, Typography, Flex, Icons } from '@jcm/design-system';
import { EnumNovidade } from '../../../ts/enums/NovidadesEnum';
import { formatarDataPtBr } from '../../../utils/datas/formatarDataPtBr';
import estilos from './index.module.scss';
import { TNovidadeLocalStorage } from '../../../ts/types/Novidade';
import { useGovernancaContext } from '../../../../../../context/GovernancaContext';

interface PropsNotificacao {
	tipo: EnumNovidade;
	titulo: string;
	descricao: string;
	dataInserida: string;
	novidadeId: number;
}

const iconeNotificacao = (tipo: EnumNovidade) => {
	switch (tipo) {
		case EnumNovidade.Bug:
			return (
				<>
					<Icons.EmergencyHome className={estilos.icone} style={{ color: 'red' }} />
					<Typography.Title className={estilos.titulo}>Inconsistência no sistema</Typography.Title>
				</>
			);
		case EnumNovidade.Sistema:
			return (
				<>
					<Icons.Info className={estilos.icone} style={{ color: '#fab35a' }} />
					<Typography.Title className={estilos.titulo}>Notificação</Typography.Title>
				</>
			);
		case EnumNovidade.Informativo:
			return (
				<>
					<Icons.Campaign className={estilos.icone} style={{ color: 'green' }} />
					<Typography.Title className={estilos.titulo}>Anúncio</Typography.Title>
				</>
			);
		default:
			return null;
	}
};

const CartaoNotificacao: React.FC<PropsNotificacao> = ({ tipo, descricao, dataInserida, novidadeId }) => {
	const { setNotificacoes } = useGovernancaContext();
	const [visivel, setVisivel] = useState(true);
	const [saindo, setSaindo] = useState(false);

	const fecharCartao = (novidadeId: number) => {
		setSaindo(true);

		const notificacoesLocalStorage: TNovidadeLocalStorage[] = JSON.parse(localStorage.getItem('notificacao') ?? '[]');

		const notificacoesAtualizadas = notificacoesLocalStorage.map((item) =>
			item.novidadeId === novidadeId ? { ...item, visualizado: true } : item,
		);

		localStorage.setItem('notificacao', JSON.stringify(notificacoesAtualizadas));

		const notificacoesNaoVisualizadas = notificacoesAtualizadas.filter((item) => !item.visualizado);
		setNotificacoes(notificacoesNaoVisualizadas);

		setTimeout(() => setVisivel(false), 300);
	};

	if (!visivel) return null;

	return (
		<Row className={`${estilos.notificationExit} ${saindo ? estilos.notificationExitActive : ''}`}>
			<Col span={22}>
				<Row gutter={[40, 10]}>
					<Col span={24}>
						<Flex gap={10} align='center'>
							{iconeNotificacao(tipo)}
						</Flex>
					</Col>
					<Col span={22}>
						<Typography.Body>{descricao}</Typography.Body>
					</Col>
					<Col>
						<Typography.Body className={estilos.data}>{formatarDataPtBr(dataInserida)}</Typography.Body>
					</Col>
				</Row>
			</Col>
			<Col span={2}>
				<Flex align='center'>
					<Icons.Close
						className={estilos.iconeFechar}
						onClick={() => {
							fecharCartao(novidadeId);
						}}
					/>
				</Flex>
			</Col>
		</Row>
	);
};

export default CartaoNotificacao;
