import { memo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import GovernancaLayout from '../layouts/GovernancaLayout';
import { QueryClientContextProvider } from '../context/QueryClientContext';
import ModuloObrigacaoAppLayout from '../modules/modulo-obrigacoes/src/layouts/ModuloObrigacaoAppLayout';
import ModuloObrigacoesAppRoute from '../modules/modulo-obrigacoes/src/routes/moduloObrigacoes.router';
import { NotFound } from '@jcm/design-system';
import { KeycloakContextProvider } from '../context/KeycloakContext';
import { AppConfigModuloObrigacoes } from '../modules/modulo-obrigacoes/configs/config';
import CadastrarUsuario from '../shared/pages/CadastrarUsuario';
import { GovernancaContextProvider } from '../context/GovernancaContext';
import HeadManager from '../shared/components/headManager';

export default function AppRoutes() {
	const ModuloObrigacoesElement = memo(() => {
		const { keycloakConfig } = AppConfigModuloObrigacoes;

		return (
			<KeycloakContextProvider clientId={keycloakConfig.clientId}>
				<QueryClientContextProvider>
					<GovernancaContextProvider>
						<ModuloObrigacaoAppLayout />
					</GovernancaContextProvider>
				</QueryClientContextProvider>
			</KeycloakContextProvider>
		);
	});

	return (
		<>
			<HeadManager /> {/* Gerenciador de FavIcon e Título do Head */}
			<Routes>
				{/* Rota para o Governanca */}
				<Route path='/' element={<GovernancaLayout />}>
					{/* <Route index element={<GovernancaHome />} /> */}
					<Route index element={<Navigate to='/obrigacoes/listagem' />} />

					<Route path='cadastrar-usuario' index element={<CadastrarUsuario />} />
				</Route>

				{/* Rota para o Módulo cObrigações */}
				<Route path='/obrigacoes/*' element={<ModuloObrigacoesElement />}>
					<Route path='*' element={<ModuloObrigacoesAppRoute />} />
				</Route>

				{/* Rota não encontrada */}
				<Route path='*' element={<NotFound />} />
			</Routes>
		</>
	);
}
