import React from 'react';
import { InputVariantType, Select } from '@jcm/design-system';
import TruncatedTextTooltip from '../truncatedTextTooltip/TruncatedTextTooltip';

interface MultiSelectProps {
	value: number[];
	onChange: (value: number[]) => void;
	options: { label: string; value: number }[];
	variant?: InputVariantType;
	label: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({ value, onChange, options, variant = 'filled', label }) => {
	const formattedOptions = options.map((option) => ({
		label: <TruncatedTextTooltip text={option.label} />,
		value: option.value,
	}));

	return (
		<Select
			value={value}
			label={label}
			onChange={onChange}
			mode='multiple'
			variant={variant}
			placeholder='Selecione empresas'
			style={{ width: '100%' }}
			optionLabelProp='label'
			options={formattedOptions}
			maxTagCount='responsive'
			maxTagPlaceholder={(omittedValues) => <span>+{omittedValues.length}...</span>}
		/>
	);
};

export default MultiSelect;
