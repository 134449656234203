import React, { useEffect, useState } from 'react';
import { Drawer, Timeline } from 'antd';
import { Icons, Typography, useTheme, Flex } from '@jcm/design-system';
import ObrigacaoService from '../../../../services/Obrigacoes/Obrigacoes.service';
import { TLogObrigacao } from '../../../../ts/types/logs/LogObrigacao';
import RegistroAtividade from './RegistroAtividade';
import { TipoAcaoEnum } from '../../../../ts/enums/TipoAcaoEnum';
import './styles.scss';
import { Tooltip } from 'antd';

interface LogsModalProps {
	setIsModalLogs: (value: boolean) => void;
	isModalLogs: boolean;
	obrigacaoId?: number;
	empresaId: number | string;
}

const LogsModal: React.FC<LogsModalProps> = ({ isModalLogs, setIsModalLogs, obrigacaoId, empresaId }) => {
	const [logsObrigacao, setLogsObrigacao] = useState<TLogObrigacao[]>([]);
	const obrigacoesService = new ObrigacaoService();
	const theme = useTheme();

	useEffect(() => {
		async function fetchLogsObrigacao() {
			if (obrigacaoId) {
				const response = await obrigacoesService.getLogsObrigacao(empresaId, obrigacaoId);
				setLogsObrigacao(response.data.result);
			}
		}
		fetchLogsObrigacao();
	}, [obrigacaoId, empresaId]);

	const onClose = () => setIsModalLogs(false);

	const getIconByTipoDado = (tipoDado: number | undefined) => {
		switch (tipoDado) {
			case TipoAcaoEnum.Insercao:
				return (
					<Tooltip title='Inserção' mouseLeaveDelay={0}>
						<Icons.Add style={{ fontSize: '26px', padding: '6px', backgroundColor: '#cce8e7', borderRadius: '100%' }} />
					</Tooltip>
				);
			case TipoAcaoEnum.Alteracao:
				return (
					<Tooltip title='Alteração' mouseLeaveDelay={0}>
						<Icons.Edit
							style={{ fontSize: '26px', padding: '6px', backgroundColor: '#ffddb0', borderRadius: '100%' }}
						/>
					</Tooltip>
				);
			case TipoAcaoEnum.Exclusao:
				return (
					<Tooltip title='Exclusão' mouseLeaveDelay={0}>
						<Icons.Delete
							style={{ fontSize: '26px', padding: '6px', backgroundColor: '#ffdad6', borderRadius: '100%' }}
						/>
					</Tooltip>
				);
			default:
				return (
					<Icons.Person style={{ fontSize: '26px', padding: '6px', backgroundColor: 'gray', borderRadius: '100%' }} />
				);
		}
	};

	const generateTimelineItems = () => {
		let lastFuncionario = '';
		let isLeft = true;

		return logsObrigacao.map(({ descricao, data, funcionario, tipoAcao }) => {
			if (funcionario.nome !== lastFuncionario) {
				isLeft = !isLeft;
				lastFuncionario = funcionario.nome;
			}

			return {
				children: <RegistroAtividade descricao={descricao} data={data} funcionario={funcionario} />,
				dot: getIconByTipoDado(tipoAcao),
				color: theme.colors.onSurfaceVariant,
				position: isLeft ? 'left' : 'right',
			};
		});
	};

	return (
		<Drawer
			title='Registro de atividades'
			placement='right'
			closable={true}
			onClose={onClose}
			open={isModalLogs}
			getContainer={false}
			width={700}
			mask={false}
		>
			{logsObrigacao.length > 0 ? (
				<Timeline mode='alternate' items={generateTimelineItems()} />
			) : (
				<Flex justify='center'>
					<Typography.Body size='large'>Não há Registros a serem listados.</Typography.Body>
				</Flex>
			)}
		</Drawer>
	);
};

export default LogsModal;
