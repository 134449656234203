import dayjs from 'dayjs';
import { CriarObrigacaoPayload, TObrigacao } from './Obrigacoes';

export function transformObrigacaoToPayload(obrigacao: TObrigacao): CriarObrigacaoPayload {
	const aprovadorPrincipal = obrigacao?.aprovadores?.find((a) => a.principal)?.funcionario.funcionarioId || 0;
	const aprovadoresSelecionados = obrigacao?.aprovadores
		?.filter((a) => a.funcionario.funcionarioId !== aprovadorPrincipal)
		?.map((a) => a.funcionario.funcionarioId);

	const responsavelPrincipal = obrigacao?.responsaveis?.find((r) => r.principal)?.funcionario.funcionarioId || 0;
	const responsaveisSelecionados = obrigacao?.responsaveis
		?.filter((r) => r.funcionario.funcionarioId !== responsavelPrincipal)
		?.map((r) => r.funcionario.funcionarioId);

	const listaEmailSelecionados = obrigacao?.listaEmail
		?.filter((email) => !email.somenteAtraso)
		?.map((email) => email.funcionario.funcionarioId);
	const listaEmailSomenteAtrasoSelecionados = obrigacao?.listaEmail
		?.filter((email) => email.somenteAtraso)
		?.map((email) => email.funcionario.funcionarioId);

	const justificativaDataAlterada =
		obrigacao?.pedidoAlteracaoDataFinal?.length > 0
			? obrigacao?.pedidoAlteracaoDataFinal[0]?.justificativaDataFinal
			: '';
	const novaDataFinal =
		obrigacao?.pedidoAlteracaoDataFinal?.length > 0
			? dayjs(obrigacao?.pedidoAlteracaoDataFinal[0]?.novaDataFinal).format('YYYY-MM-DD')
			: null;

	const justificativaCancelamento =
		obrigacao?.pedidoCancelarObrigacao?.length > 0
			? obrigacao?.pedidoCancelarObrigacao[0]?.justificativaCancelamento
			: '';

	const justificativaNaoAprovacaoCancelamento =
		obrigacao?.pedidoCancelarObrigacao?.length > 0
			? obrigacao?.pedidoCancelarObrigacao[obrigacao?.pedidoCancelarObrigacao.length - 1]?.justificativaNaoAprovacao
			: '';

	const justificativaNaoAprovacaoAlteracaoData =
		obrigacao?.pedidoAlteracaoDataFinal?.length > 0
			? obrigacao?.pedidoAlteracaoDataFinal[obrigacao?.pedidoAlteracaoDataFinal.length - 1]?.justificativaNaoAprovacao
			: '';

	const justificativaNaoAprovacao =
		obrigacao?.justificativasAprovacao?.length > 0
			? obrigacao?.justificativasAprovacao[obrigacao?.justificativasAprovacao.length - 1]?.justificativaAprovador
			: '';

	return {
		id: obrigacao?.id,
		nome: obrigacao?.nome,
		codigo: parseInt(obrigacao?.codigo),
		dataCriacao: obrigacao?.dataCriacao,
		dataVencimento: obrigacao?.dataVencimento,
		ativa: obrigacao?.ativa,
		tipo: obrigacao?.tipo,
		aprovadoresSelecionados,
		aprovadorPrincipal,
		responsaveisSelecionados,
		responsavelPrincipal,
		children: obrigacao?.children,
		ehLegal: obrigacao.ehLegal,
		acompanhamento: obrigacao?.acompanhamento,
		prazoLegal: obrigacao?.prazoLegal,
		temporalidade: obrigacao?.temporalidade,
		datas: obrigacao?.datas,
		formaCumprimento: obrigacao?.formaCumprimento,
		fundamentacaoLegal: obrigacao?.fundamentacaoLegal,
		diaUtil: obrigacao?.diaUtil,

		frequenciaSelecionada: obrigacao?.regraEnvioEmail?.frequenciaAntecedencia,
		antecedenciaSelecionada: obrigacao?.regraEnvioEmail?.antecedencia,
		frequenciaVencidaSelecionada: obrigacao?.regraEnvioEmail?.frequenciaVencida,
		justificativaAtraso: obrigacao.justificativaAtrasoConclusao,

		origemSelecionada: obrigacao?.origemId,

		estadoStatusAprovada: obrigacao?.estadoStatusAprovada,
		estadoStatusCancelamento:
			obrigacao?.pedidoCancelarObrigacao?.[obrigacao?.pedidoCancelarObrigacao.length - 1]?.estadoStatusAprovada ?? 0,

		estadoStatusAlterarData:
			obrigacao?.pedidoAlteracaoDataFinal?.[obrigacao?.pedidoAlteracaoDataFinal.length - 1]?.estadoStatusAprovada ?? 0,

		justificativaCancelar: justificativaCancelamento || '',
		justificativaNaoAprovarCancelamento: justificativaNaoAprovacaoCancelamento,
		justificativaNaoAprovacaoDataAlterada: justificativaNaoAprovacaoAlteracaoData,
		justificativaNaoAprovacao: justificativaNaoAprovacao,

		listaEmailSelecionados: listaEmailSelecionados,
		listaEmailSomenteAtrasoSelecionados: listaEmailSomenteAtrasoSelecionados,
		dataFinal: obrigacao?.dataFinal,
		dataConclusaoObrigacao: new Date(),
		dataHoje: new Date(),
		observacao: obrigacao?.observacao,
		justificativaDataAlterada: justificativaDataAlterada || '',
		novaDataFinal: novaDataFinal || null,
		dataCancelamento: new Date() || null,
	};
}
