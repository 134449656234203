import React from 'react';
import { Button, Popconfirm } from 'antd';
import { ButtonProps } from 'antd/es/button';
import { QuestionCircleOutlined } from '@ant-design/icons';

interface PopConfirmButtonProps {
	onConfirm: () => void;
	onCancel?: () => void;
	title: string;
	okText?: string;
	cancelText?: string;
	buttonText: React.ReactNode;
	buttonProps?: ButtonProps; // Permite passar qualquer propriedade válida para o Button
	disabled?: boolean; // Adiciona a prop disabled para controlar se o botão deve ser desabilitado
}

const PopConfirmButton: React.FC<PopConfirmButtonProps> = ({
	onConfirm,
	onCancel,
	title,
	okText = 'Confirmar',
	cancelText = 'Cancelar',
	buttonText,
	buttonProps = {},
	disabled = false, // Define a prop disabled com valor padrão false
}) => {
	return (
		<Popconfirm
			title={title}
			onConfirm={onConfirm}
			onCancel={onCancel}
			okText={okText}
			cancelText={cancelText}
			icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
			// Remova o disabled daqui para que o Popconfirm seja mostrado, mesmo se o botão estiver desativado
		>
			<Button
				style={{ backgroundColor: 'transparent', border: 'none' }}
				{...buttonProps}
				disabled={disabled} // Desativa apenas o botão
			>
				{buttonText}
			</Button>
		</Popconfirm>
	);
};

export default PopConfirmButton;
