import { Flex, Typography } from '@jcm/design-system';
import { TipoRotulo } from '../RotuloContagemObrigacoes/types';
import RotuloContagemObrigacoes from '../RotuloContagemObrigacoes';

interface TabMenuObrigacoesInterface {
	tituloPagina: string;
	rotuloContagemObrigações: TipoRotulo;
	quantidade: number;
}

const TabMenuObrigacoes: React.FC<TabMenuObrigacoesInterface> = ({
	tituloPagina,
	rotuloContagemObrigações,
	quantidade,
}) => {
	return (
		<Flex align='center' gap={4} style={{ cursor: 'pointer' }}>
			<Typography.Label size='large' style={{ cursor: 'pointer' }}>
				{tituloPagina}
			</Typography.Label>
			<RotuloContagemObrigacoes quantidade={quantidade} tipo={rotuloContagemObrigações} />
		</Flex>
	);
};

export default TabMenuObrigacoes;
