// CustomAlert.tsx
import React from 'react';
import useNotification from './useNotification';

interface CustomAlertProps {
	type: 'success' | 'error' | 'info' | 'warning';
	content: string;
	duration?: number;
	title?: string;
}

const CustomAlert: React.FC<CustomAlertProps> = ({ type, content, duration = 5, title }) => {
	useNotification(type, content, duration, title);

	return null;
};

export default CustomAlert;
