/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Box } from '@mui/material';
import styles from './index.module.scss';
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormSetValue, useWatch } from 'react-hook-form';
import { Col, DatePicker, Row, Select, TextInput, Typography } from '@jcm/design-system';
import './index.module.scss';
import dayjs from 'dayjs';

interface CalculoPeriodicidadeProps {
	control: Control<any>;
	errors: FieldErrors<any>;
	setValue: UseFormSetValue<any>;
	getValues: UseFormGetValues<any>;
}

const CalculoPeriodicidade: React.FC<CalculoPeriodicidadeProps> = ({ control, errors, getValues, setValue }) => {
	const tipoVencimento = useWatch({
		control,
		name: 'tipoVencimento',
	});
	const periodicidadeSelecionada = useWatch({
		control,
		name: 'periodicidadeSelecionada',
	});

	return (
		<Box className={styles.calculoPeriodicidadeContainer}>
			<Typography.Body size='large' strong>
				Regra para cálculo da periodicidade
			</Typography.Body>
			<Row gutter={[16, 16]} className={styles.espacamentoTop}>
				<Col className={styles.rowBackground} xl={24}>
					<Row gutter={[16, 16]}>
						<Col xs={24} sm={24} md={24} lg={14} xl={8} xxl={6}>
							<Typography.Body>
								Tipo de vencimento <span style={{ color: 'red' }}>*</span>
							</Typography.Body>

							<Controller
								name='tipoVencimento'
								control={control}
								render={({ field }) => (
									<>
										<Select
											{...field}
											className={styles.selectFormObrigacao}
											variant='outlined'
											placeholder='Selecione'
											options={[
												{
													label: 'Data de vencimento',
													value: 0,
												},
												{
													label: 'Dia útil',
													value: 1,
												},
											]}
										/>
										{errors.tipoVencimento && (
											<Typography.Label variant='error'>{errors.tipoVencimento.message as string}</Typography.Label>
										)}
									</>
								)}
							/>
						</Col>

						<Col xs={24} sm={24} md={24} lg={10} xl={8} xxl={6}>
							<Typography.Body>
								Data de Vencimento <span style={{ color: 'red' }}>*</span>
							</Typography.Body>

							<Controller
								name='dataVencimento'
								control={control}
								render={({ field }) => (
									<>
										<DatePicker
											placeholder='AAAA/MM/DD'
											allowClear={false}
											variant='outlined'
											className={styles.calendarInputObrigacoes}
											onChange={(e) => {
												field.onChange(dayjs(e).format('YYYY-MM-DD'));
												setValue('dataVencimento', dayjs(e).format('YYYY-MM-DD'));
											}}
											onFocus={(e) => e.target.blur()}
										/>
										{errors.dataVencimento && (
											<Typography.Label variant='error'>{errors.dataVencimento.message as string}</Typography.Label>
										)}
									</>
								)}
							/>
						</Col>

						<Col xs={24} sm={24} md={24} lg={10} xl={8} xxl={6}>
							<Typography.Body>
								Dia útil do mês {tipoVencimento === 1 && <span style={{ color: 'red' }}>*</span>}
							</Typography.Body>
							<Controller
								name='diaUtil'
								control={control}
								render={({ field }) => (
									<>
										<TextInput
											{...field}
											variant='outlined'
											type='text'
											disabled={tipoVencimento === 0}
											className={`${styles.calendarInputObrigacoes}`}
											placeholder='Digite o dia útil'
											onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
												const value = e.target.value.replace(/[^0-9]/g, '');

												const dataVencimento = getValues('dataVencimento');
												const month = dataVencimento ? dayjs(dataVencimento).month() : dayjs().month();
												const maxDay = month === 1 ? 28 : [3, 5, 8, 10].includes(month) ? 30 : 31;

												if (Number(value) > maxDay) {
													e.target.value = maxDay.toString();
												} else {
													e.target.value = value;
												}

												field.onChange(e);
											}}
										/>
										{errors.diaUtil && (
											<Typography.Label variant='error'>{errors.diaUtil.message as string}</Typography.Label>
										)}
									</>
								)}
							/>
						</Col>
					</Row>
				</Col>

				<Col className={styles.rowBackground} xl={24}>
					<Row gutter={[16, 16]}>
						<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
							<Typography.Body>
								Periodicidade <span style={{ color: 'red' }}>*</span>
							</Typography.Body>

							<Controller
								name='periodicidadeSelecionada'
								control={control}
								render={({ field }) => (
									<>
										<Select
											{...field}
											className={styles.selectFormObrigacao}
											variant='outlined'
											placeholder='Selecione'
											options={[
												{
													label: 'Unica',
													value: 0,
												},
												{
													label: 'Diaria',
													value: 1,
												},
												{
													label: 'Semanal',
													value: 2,
												},
												{
													label: 'Quinzenal',
													value: 3,
												},
												{
													label: 'Mensal',
													value: 4,
												},
												{
													label: 'Bimestral',
													value: 5,
												},
												{
													label: 'Trimestral',
													value: 6,
												},
												{
													label: 'Quadrimestral',
													value: 7,
												},
												{
													label: 'Semestral',
													value: 8,
												},
												{
													label: 'Anual',
													value: 9,
												},
											]}
										/>
										{errors.periodicidadeSelecionada && (
											<Typography.Label variant='error'>
												{errors.periodicidadeSelecionada.message as string}
											</Typography.Label>
										)}
									</>
								)}
							/>
						</Col>

						<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
							<Typography.Body>
								Duração da Obrigação {periodicidadeSelecionada !== 0 && <span style={{ color: 'red' }}>*</span>}
							</Typography.Body>
							<Controller
								name='periodoSelecionado'
								control={control}
								render={({ field }) => (
									<>
										<Select
											{...field}
											className={styles.selectFormObrigacao}
											variant='outlined'
											placeholder='Selecione'
											disabled={periodicidadeSelecionada === 0}
											options={[
												{
													label: 'Semana',
													value: 1,
												},
												{
													label: 'Mês',
													value: 2,
												},
												{
													label: 'Bimestre',
													value: 3,
												},
												{
													label: 'Trimestre',
													value: 4,
												},
												{
													label: 'Quadrimestre',
													value: 5,
												},
												{
													label: 'Semestre',
													value: 6,
												},
												{
													label: '1 Ano',
													value: 7,
												},
												{
													label: '2 Anos',
													value: 8,
												},
												{
													label: '3 Anos',
													value: 9,
												},
											]}
										/>
										{errors.periodoSelecionado && (
											<Typography.Label variant='error'>{errors.periodoSelecionado.message as string}</Typography.Label>
										)}
									</>
								)}
							/>
						</Col>

						<Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={9}>
							<Typography.Body>
								Vencimento dia não útil <span style={{ color: 'red' }}>*</span>
							</Typography.Body>
							<Controller
								name='casoNaoUtilSelecionado'
								control={control}
								render={({ field }) => (
									<>
										<Select
											{...field}
											className={styles.selectFormObrigacao}
											variant='outlined'
											placeholder='Selecione'
											disabled={tipoVencimento === 1}
											options={[
												{
													label: 'Postergar',
													value: 1,
												},
												{
													label: 'Manter',
													value: 2,
												},
												{
													label: 'Antecipar',
													value: 3,
												},
											]}
										/>
										{errors.casoNaoUtilSelecionado && (
											<Typography.Label variant='error'>
												{errors.casoNaoUtilSelecionado.message as string}
											</Typography.Label>
										)}
									</>
								)}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</Box>
	);
};

export default CalculoPeriodicidade;
