import React, { useMemo, useState } from 'react';
import { Flex, ITabsProps, Icons, Switch, Tabs, Typography } from '@jcm/design-system';
import TabMenuObrigacoes from './TabMenuObrigacoes';
import estilo from './index.module.scss';
import { PosicaoType } from './types';
import { Permissoes } from '../../../ts/enums/PermissoesEnum';
import usePermissao from '../../../hooks';
import { Popover } from 'antd';
import { useNavigateWithSearchParams } from '../../../hooks';

type ObrigacoesTabMenuProps = {
	concluidasNoPrazo: number;
	concluidasComAtraso: number;
	vencidasNaoConcluidas: number;
	naoConcluidasNoPrazo: number;
	aguardandoAprovacao: number;
	todasObrigacoes: number;
	activeTabKey: string;
	onTabChange: (tabKey: string) => void;
	onSwitchChange: (checked: boolean) => void;
	showCompleted: boolean;
	loading: boolean;
};

const ObrigacoesTabMenu: React.FC<ObrigacoesTabMenuProps> = (props) => {
	const [posicao] = useState<PosicaoType[]>(['left', 'right']);
	const temPermissaoAdministrador = usePermissao([Permissoes.AdministradorEmpresa, Permissoes.AdministradorSistema]);
	const navigateWithSearchParams = useNavigateWithSearchParams();

	const tituloTodasObrigacoes = props.showCompleted ? 'Minhas Obrigações' : 'Todas Obrigações';
	const tituloVencida = props.showCompleted ? 'Minhas Obrigações Vencidas' : 'Obrigações Vencidas';

	const isDisabled = props.loading;

	const OperationsSlot: Partial<Record<PosicaoType, React.ReactNode>> = useMemo(() => {
		const slots: Partial<Record<PosicaoType, React.ReactNode>> = {};

		if (posicao.includes('left')) {
			slots.left = (
				<Flex align='center' gap={8} className={estilo.MinhasObrigacoesTab}>
					<Typography.Body size='medium' strong>
						Minhas Obrigações
					</Typography.Body>

					<Switch
						disabled={!temPermissaoAdministrador || isDisabled}
						onChange={(e) => props.onSwitchChange(e)}
						checked={props.showCompleted}
					/>
				</Flex>
			);
		}

		if (posicao.includes('right')) {
			slots.right = (
				<Popover content='Ir para operações em massa'>
					<Icons.DisplaySettings
						className={estilo.visualizacaoListagemIcone}
						onClick={() => navigateWithSearchParams('/obrigacoes/operacoes-em-massa')}
					/>
				</Popover>
			);
		}

		return slots;
	}, [posicao, temPermissaoAdministrador, isDisabled, props.showCompleted, navigateWithSearchParams]);

	const paginas: ITabsProps['items'] = useMemo(
		() => [
			{
				key: 'todasObrigacoes',
				label: (
					<TabMenuObrigacoes
						tituloPagina={tituloTodasObrigacoes}
						rotuloContagemObrigações='todos'
						quantidade={props.todasObrigacoes}
					/>
				),
				children: null,
				disabled: isDisabled,
			},
			{
				key: 'obrigacoesVencidas',
				label: (
					<TabMenuObrigacoes
						tituloPagina={tituloVencida}
						rotuloContagemObrigações='vencidos'
						quantidade={props.vencidasNaoConcluidas}
					/>
				),
				children: null,
				disabled: isDisabled,
			},
			{
				key: 'obrigacoesAguardandoAprovacoes',
				label: (
					<TabMenuObrigacoes
						tituloPagina='Aguardando Aprovação'
						rotuloContagemObrigações='aguardando'
						quantidade={props.aguardandoAprovacao}
					/>
				),
				children: null,
				disabled: isDisabled,
			},
		],
		[props],
	);

	const handleTabChange = (key: string) => {
		props.onTabChange(key);
	};

	return (
		<Tabs
			tabBarExtraContent={OperationsSlot}
			items={paginas}
			activeKey={props.activeTabKey}
			onChange={handleTabChange}
		/>
	);
};

export default ObrigacoesTabMenu;
