import { Button, Flex, Icons, TextInput, Typography } from '@jcm/design-system';
import { useForm, Controller } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import ObrigacoesService from '../../../../services/Obrigacoes/Obrigacoes.service';
import estilo from './index.module.scss';
import { TOrigem } from '../../../../ts/types/Origem';
import CustomAlert from '../../../../../../../shared/components/customAlert/customAlert';
import React, { useState, useEffect } from 'react';
import PopConfirmButton from '../../../../../../../shared/components/popConfirmButton/popConfirmButton';
import {Tooltip} from 'antd';

// Define o schema de validação com Zod
const origemSchema = z.object({
	nomeOrigem: z.string().min(1, 'Nome é obrigatório'),
});

type OrigemFormData = z.infer<typeof origemSchema>;

interface OrigemIndividualListagemProps {
	origens: TOrigem[];
	setOrigens: React.Dispatch<React.SetStateAction<TOrigem[]>>;
	empresaId: string | number;
	handleCreate: () => void;
	setIsEditing: React.Dispatch<React.SetStateAction<number | null>>;
	setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
	isEditing: number | null;
	isCreating: boolean;
	handleCancel: () => void;
	newOrigemId: number | null; // Receber o newOrigemId
	setNewOrigemId: React.Dispatch<React.SetStateAction<number | null>>; // Receber o setNewOrigemId
}

export default function OrigemIndividualListagem({
	origens,
	setOrigens,
	empresaId,
	setIsEditing,
	setIsCreating,
	isEditing,
	isCreating,
	handleCancel,
	newOrigemId, // Desestruturar o newOrigemId
	setNewOrigemId, // Desestruturar o setNewOrigemId
}: OrigemIndividualListagemProps) {
	const _obrigacoesService = new ObrigacoesService();

	const { handleSubmit, control, reset } = useForm<OrigemFormData>({
		resolver: zodResolver(origemSchema),
		defaultValues: { nomeOrigem: '' },
	});

	const handleEdit = (id: number, currentName: string) => {
		setIsCreating(false);
		setIsEditing(id);
		reset({ nomeOrigem: currentName });
	};

	const [alert, setAlert] = useState<{ type: 'success' | 'error'; content: string; title?: string } | null>(null);
	useEffect(() => {
		if (alert) {
			const timer = setTimeout(() => setAlert(null), 1000);
			return () => clearTimeout(timer);
		}
	}, [alert]);

	const handleSave = async (data: OrigemFormData) => {
		if (isCreating) {
			try {
				await _obrigacoesService.criarOrigem(empresaId, { ...data, origemId: newOrigemId } as TOrigem).then((res) => {
					setOrigens((prev) =>
						prev.map((origem) =>
							origem.origemId === newOrigemId
								? { ...origem, origemId: res.data.result.origemId, nomeOrigem: data.nomeOrigem }
								: origem,
						),
					);
					setIsCreating(false);
					setNewOrigemId(null);
				});

				setAlert({ type: 'success', content: 'Origem criada com sucesso!', title: 'Sucesso!' });
			} catch (error) {
				console.error('Erro ao salvar a origem:', error);
				setAlert({ type: 'error', content: 'Erro ao salvar a origem. Tente novamente.', title: 'Erro!' });
			}
		} else if (isEditing) {
			try {
				await _obrigacoesService
					.atualizarOrigem(empresaId, isEditing, { ...data, origemId: isEditing } as TOrigem)
					.then((res) => {
						setOrigens((prev) =>
							prev.map((origem) =>
								origem.origemId === isEditing
									? { ...origem, origemId: res.data.result.origemId, nomeOrigem: data.nomeOrigem }
									: origem,
							),
						);
					});
				setAlert({ type: 'success', content: 'Origem atualizada com sucesso!', title: 'Sucesso!' });

				setIsEditing(null);
			} catch (error) {
				setAlert({ type: 'error', content: 'Erro ao salvar a origem. Tente novamente.', title: 'Erro!' });
				console.error('Erro ao salvar a origem:', error);
			}
		}
		reset();
	};

	const handleDelete = (index: number) => {
		// Utiliza o PopConfirmButton para confirmar a exclusão
		const onConfirm = async () => {
			try {
				setAlert({ type: 'success', content: 'Origem excluída com sucesso!', title: 'Sucesso' });
				await _obrigacoesService.deletarOrigem(empresaId, index);
				setOrigens((prev) => prev.filter((origem) => origem.origemId !== index));
			} catch (error) {
				setAlert({ type: 'error', content: 'Erro ao excluir origem. Tente novamente.', title: 'Erro' });
			}
		};

		// Renderiza o botão de confirmação
		return (
			<PopConfirmButton
				onConfirm={onConfirm}
				title='Atenção: Ao excluir essa origem, ela será removida de todas as obrigações vinculadas. Deseja continuar?'
				okText='Sim, excluir!'
				cancelText='Cancelar'
				buttonText={<Icons.Delete style={{ fontSize: '20px', cursor: 'pointer' }} />}
			/>
		);
	};
	return (
		<div>
			{origens.map((origem) => (
				<div key={origem.origemId} className={estilo.itemContainer}>
					{isEditing === origem.origemId || (isCreating && origem.origemId === newOrigemId) ? (
						<Flex style={{ gap: '1rem', padding: '1rem 0', borderBottom: '1px solid #D9D9D9' }}>
							<Controller
								name='nomeOrigem'
								control={control}
								render={({ field }) => (
									<TextInput
										variant='outlined'
										type='text'
										{...field}
										placeholder='Digite o nome da origem'
										className={estilo.inlineInput}
										style={{ width: '100%' }}
									/>
								)}
							/>
							<Flex justify='end'>
								<Button onClick={handleSubmit(handleSave)} style={{ marginRight: '8px' }}>
									Salvar
								</Button>
								<Button onClick={handleCancel} variant='default' type='outlined'>
									Cancelar
								</Button>
							</Flex>
						</Flex>
					) : (
						<Flex
							justify='space-between'
							style={{ alignItems: 'center', borderBottom: '1px solid #D9D9D9', padding: '.5rem' }}
						>
							<Typography.Body>{origem.nomeOrigem}</Typography.Body>
							<Flex>
								{' '}
								<Tooltip title='Editar' mouseLeaveDelay={0}>
									<Button
										circle
										icon={<Icons.Edit style={{ fontSize: '20px', cursor: 'pointer' }} />}
										type='text'
										variant='default'
										onClick={() => handleEdit(origem.origemId, origem.nomeOrigem)}
									/>
								</Tooltip>
								{handleDelete(origem.origemId)}
							</Flex>
						</Flex>
					)}
				</div>
			))}
			{alert && <CustomAlert type={alert.type} content={alert.content} />}
		</div>
	);
}
