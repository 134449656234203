import React from 'react';
import { Drawer } from 'antd';
import { TNovidade } from '../../ts/types/Novidade';
import { Row, Col, Typography } from '@jcm/design-system';
import CartaoNotificacao from './CartaoNotificacao';
import { Flex } from 'antd/lib';

interface PropsNotificacoesUsuario {
	notificacoesUsuarioAberto: boolean;
	setNotificacoesUsuarioAberto: (valor: boolean) => void;
	notificacoes: TNovidade[];
}

const NotificacoesUsuario: React.FC<PropsNotificacoesUsuario> = ({
	notificacoesUsuarioAberto,
	setNotificacoesUsuarioAberto,
	notificacoes,
}) => {
	return (
		<Drawer
			closable
			destroyOnClose
			title='Notificações'
			placement='right'
			open={notificacoesUsuarioAberto}
			loading={false}
			onClose={() => setNotificacoesUsuarioAberto(false)}
			mask={false}
			width='540px'
		>
			<Row gutter={[0, 20]}>
				{notificacoes.length > 0 ? (
					notificacoes.map((item: TNovidade) => (
						<Col key={item.novidadeId}>
							<CartaoNotificacao
								descricao={item.descricao}
								key={item.novidadeId}
								titulo={item.descricao}
								tipo={item.tipoNovidade}
								dataInserida={item.dataInserida}
								novidadeId={item.novidadeId}
							/>
						</Col>
					))
				) : (
					<Flex justify='center'>
						<Typography.Body>Não há notificações a serem listadas.</Typography.Body>
					</Flex>
				)}
			</Row>
		</Drawer>
	);
};

export default NotificacoesUsuario;
