/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box, Radio } from '@mui/material';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import RestoreIcon from '@mui/icons-material/Restore';
import classNames from 'classnames';
import styles from './CustomHeader.module.scss';
import { Button, Flex, Icons, Typography, useTheme } from '@jcm/design-system';
import { Popover } from 'antd';
import { obrigacaoEstaConcluida } from '../../../../utils/determinarAcessoObrigacoes';
import './CustomHeader.css';
import { Tooltip } from 'antd';

interface CustomHeaderProps {
	isEdit: boolean;
	empresaNome: string;
	dataVencimento?: string;
	obrigacao: any;
	openAlterarVencimentoModal: () => void;
	openConcluirObrigacaoModal: () => void;
	openModalLogs: (value: boolean) => void;
	usuarioAprovador: boolean;
	usuarioResponsavel: boolean;
	isModalLogs: boolean;
}

const CustomHeader: React.FC<CustomHeaderProps> = ({
	isEdit,
	dataVencimento,
	obrigacao,
	openAlterarVencimentoModal,
	openConcluirObrigacaoModal,
	openModalLogs,
	usuarioAprovador,
	usuarioResponsavel,
	isModalLogs,
}) => {
	const { colors } = useTheme();
	const formattedDate = dataVencimento ? new Date(dataVencimento).toLocaleDateString('pt-BR') : '';

	const statusData = obrigacao.estadoStatusAlterarData;
	const aprovacao = obrigacao.estadoStatusAprovada;
	const cancelar = obrigacao.estadoStatusCancelamento;

	const textoStatus: string[] = [];

	switch (statusData) {
		case 0:
			// Nenhuma alteração necessária para o caso "0"
			break;
		case 1:
			textoStatus.push('Aguardando aprovação da alteração da data de vencimento');
			break;
		case 2:
			textoStatus.push('Alteração da data de vencimento aprovada');
			break;
		case 3:
			textoStatus.push('Alteração da data de vencimento não aprovada');
			break;
		default:
			break;
	}

	// Lógica para aprovação
	switch (aprovacao) {
		case 1:
			textoStatus.push('Aguardando aprovação da conclusão');
			break;
		case 2:
			textoStatus.push('Conclusão aprovada');
			break;
		case 3:
			textoStatus.push('Conclusão não aprovada');
			break;
		default:
			break;
	}

	// Lógica para cancelar
	switch (cancelar) {
		case 1:
			textoStatus.push('Aguardando aprovação do cancelamento');
			break;
		case 2:
			textoStatus.push('Cancelamento aprovado');
			break;
		case 3:
			textoStatus.push('Cancelamento não aprovado');
			break;
		default:
			break;
	}

	const textoStatusConcatenado = textoStatus.join(' | ');
	return (
		<Box className={styles.customHeader}>
			{!isEdit ? (
				<Box style={{ gap: '.5rem' }} className={styles.headerContent}>
					<Flex gap={6} align='center' className={styles.espacadorR}>
						<Radio checked className={classNames(styles.headerRadio, styles.novaObrigacao)} />
						<Typography.Title size='small'>Nova obrigação</Typography.Title>
					</Flex>
					<Typography.Title size='small'>Criar obrigação</Typography.Title>
				</Box>
			) : (
				<Flex style={{ gap: '.5rem' }} className={styles.headerContent}>
					<Flex gap={6} align='center' className={styles.espacadorR}>
						<Radio checked className={classNames(styles.headerRadio, styles.emProgresso)} />
						<Typography.Title size='small'>
							{textoStatusConcatenado.length ? textoStatusConcatenado : 'Em progresso'}
						</Typography.Title>
					</Flex>
					<Typography.Title size='small'>Editar obrigação {obrigacao.codigo}</Typography.Title>
					<Box className={styles.headerIconGroup}>
						<Popover content={'Alterar data de vencimento'}>
							<Button
								type='text'
								variant='default'
								className={styles.dateButton}
								disabled={
									(!usuarioResponsavel && !(usuarioAprovador && statusData > 0 && statusData === 1)) ||
									obrigacaoEstaConcluida(aprovacao)
								}
								onClick={(ev) => {
									ev.preventDefault();
									openAlterarVencimentoModal();
								}}
							>
								<Flex style={{ alignItems: 'center' }}>
									<Typography.Body
										style={{ display: 'flex', alignItems: 'center', gap: '.25rem', margin: '.5rem .75rem .5rem .5rem' }}
									>
										<EditCalendarOutlinedIcon fontSize='small' />
										{formattedDate}
									</Typography.Body>
								</Flex>
							</Button>
						</Popover>
						<Flex className={styles.espacadorL}>
							<Popover content={'Em desenvolvimento'}>
								<Button
									type='text'
									variant='default'
									icon={<Icons.AttachFile color={colors.secondary} />}
									circle
									disabled={true}
								></Button>
							</Popover>
							<Popover content={'Concluir obrigação'}>
								<Button
									type='text'
									variant='default'
									disabled={
										(!usuarioResponsavel && !(usuarioAprovador && aprovacao > 0 && aprovacao === 1)) ||
										obrigacaoEstaConcluida(aprovacao)
									}
									onClick={(ev) => {
										ev.preventDefault();
										openConcluirObrigacaoModal();
									}}
									icon={<Icons.CheckCircle color={colors.secondary} />}
									circle
								></Button>
							</Popover>
							<Tooltip title='Registro de atividades' mouseLeaveDelay={0}>
								<Button
									type='text'
									variant='default'
									onClick={(ev) => {
										ev.preventDefault();
										openModalLogs(!isModalLogs);
									}}
									icon={<RestoreIcon fontSize='small' />}
									circle
								></Button>
							</Tooltip>
						</Flex>
					</Box>
				</Flex>
			)}
		</Box>
	);
};

export default CustomHeader;
