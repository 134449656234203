import React from 'react';
import { Icons, Typography } from '@jcm/design-system';

interface UserAvatarProps {
	nome?: string;
	ativo?: boolean;
	tamanho?: 'small' | 'medium' | 'large';
}

const getInitials = (nome?: string): string => {
	if (!nome) return '';

	const partes = nome.split(' ').filter((parte) => parte);

	if (partes.length === 1) {
		return partes[0][0].toUpperCase();
	} else {
		const primeiraLetra = partes[0][0].toUpperCase();
		const ultimaLetra = partes[partes.length - 1][0].toUpperCase();

		return primeiraLetra + ultimaLetra;
	}
};

const tamanhoStyles = {
	small: {
		width: '28px',
		height: '28px',
		iconSize: '16px',
		size: 'small' as 'small' | 'medium' | 'large',
	},
	medium: {
		width: '40px',
		height: '40px',
		iconSize: '20px',
		size: 'medium' as 'small' | 'medium' | 'large',
	},
	large: {
		width: '60px',
		height: '60px',
		iconSize: '32px',
		size: 'large' as 'small' | 'medium' | 'large',
	},
};

const UserAvatar: React.FC<UserAvatarProps> = ({ nome = '', ativo = true, tamanho = 'medium' }) => {
	const initials = getInitials(nome);
	const backgroundColor = ativo ? '#80D4D5' : '#FFDAD6';
	const { width, height, iconSize, size } = tamanhoStyles[tamanho];
	return (
		<div
			style={{
				width,
				height,
				minWidth: width,
				minHeight: height,
				borderRadius: '50%',
				backgroundColor,
				color: '#2F2E2E',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				textTransform: 'uppercase',
			}}
		>
			<Typography.Title size={size} variant='default' id='email' style={{ lineHeight: '2rem' }}>
				{initials ? initials : <Icons.Person className='userEdit' style={{ fontSize: iconSize, lineHeight: 'normal' }} />}
			</Typography.Title>
		</div>
	);
};

export default UserAvatar;
