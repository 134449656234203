import { z } from 'zod';

export const ModalEdicaoEmMassaZodSchema = z.object({
	responsavelPrincipal: z.number().optional(),
	aprovadorPrincipal: z.number().optional(),
	responsaveisSecundarios: z.array(z.number()).optional(),
	aprovadoresSecundarios: z.array(z.number()).optional(),
	descricao: z.string().optional(),
	ehLegal: z.boolean().optional(),
	acompanhamento: z.string().optional().or(z.literal('')),
	fundamentacaoLegal: z.string().optional().or(z.literal('')),
	prazoLegal: z.string().optional().or(z.literal('')),
	datas: z.string().optional().or(z.literal('')),
	temporalidade: z.string().optional().or(z.literal('')),
	formaCumprimento: z.string().optional(),
	frequenciaSelecionada: z.number().optional(),
	antecedenciaSelecionada: z.number().optional(),
	frequenciaVencidaSelecionada: z.number().optional(),
});

export type ModalEdicaoEmMassaFormValues = z.infer<typeof ModalEdicaoEmMassaZodSchema>;
