/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Button, Checkbox, Flex, Typography } from '@jcm/design-system';
import styles from './ModalCancelarObrigacao.module.scss';
import ModalContainer from '../../../../../components/modalContainer/ModalContainer';
import { Control, Controller, UseFormGetValues, UseFormSetValue, UseFormUnregister, useWatch } from 'react-hook-form';
import { Input } from 'antd';

const { TextArea } = Input;
interface ModalCancelarObrigacaoProps {
	isVisible: boolean;
	onClose: () => void;
	setValue: UseFormSetValue<any>;
	getValues: UseFormGetValues<any>;
	control: Control<any>;
	unregister: UseFormUnregister<any>;
	usuarioAprovador: boolean;
	usuarioResponsavel: boolean;
	handleSubmit: (data: any) => void;
}

const ModalCancelarObrigacao: React.FC<ModalCancelarObrigacaoProps> = ({
	isVisible,
	onClose,
	control,
	getValues,
	setValue,
	unregister,
	usuarioAprovador,
	usuarioResponsavel,
	handleSubmit,
}) => {
	const justificativaNaoAprovarCancelamento = useWatch({
		control,
		name: 'justificativaNaoAprovarCancelamento',
	});
	const justificativaCancelar = useWatch({
		control,
		name: 'justificativaCancelar',
	});
	const statusAprovarCancelamento = useWatch({
		control,
		name: 'statusAprovarCancelamento',
	});

	return (
		<ModalContainer
			title='Cancelar obrigação'
			isVisible={isVisible}
			onClose={onClose}
			className={styles.modalCancelarObrigacao}
		>
			<Controller
				name='justificativaCancelar'
				control={control}
				render={({ field }) => (
					<Flex vertical gap={2}>
						<Typography.Body>
							Justificativa para o cancelamento <span style={{ color: 'red' }}>*</span>
						</Typography.Body>
						<TextArea
							{...field}
							disabled={!usuarioResponsavel}
							placeholder='Justificativa para o cancelamento'
							allowClear
							variant='filled'
						/>
					</Flex>
				)}
			/>

			{usuarioAprovador && !(usuarioAprovador && usuarioResponsavel) && (
				<>
					<Controller
						name='statusAprovarCancelamento'
						control={control}
						render={({ field }) => (
							<Flex gap={16}>
								<Flex gap={4}>
									<Checkbox
										type='checkbox'
										checked={field.value === 1}
										onChange={() => {
											setValue('statusAprovarCancelamento', field.value === 1 ? null : 1);
										}} // 1 Status Aprovada
									/>
									<Typography.Body>Aprovar</Typography.Body>
								</Flex>
								<Flex gap={4}>
									<Checkbox
										type='checkbox'
										checked={field.value === 2}
										onChange={() => {
											setValue('statusAprovarCancelamento', field.value === 2 ? null : 2);
										}} // 2 Status Reprovada
									/>
									<Typography.Body>Reprovar</Typography.Body>
								</Flex>
							</Flex>
						)}
					/>
					{getValues('statusAprovarCancelamento') === 2 && (
						<Controller
							name='justificativaNaoAprovarCancelamento'
							control={control}
							render={({ field }) => (
								<Flex vertical gap={2}>
									<Typography.Body>
										Justificativa de Reprovação <span style={{ color: 'red' }}>*</span>
									</Typography.Body>
									<TextArea {...field} placeholder='Justificativa de Reprovação' allowClear variant='filled' />
								</Flex>
							)}
						/>
					)}
				</>
			)}

			<Flex style={{ gap: '1rem', justifySelf: 'center' }}>
				<Button
					type='filled-tonal'
					variant='error'
					onClick={() => {
						unregister('solicitacaoCancelamento');
						unregister('pedidoDeCancelamentoClicado');
						unregister('dataCancelamento');
						unregister('justificativaCancelar');

						onClose();
					}}
				>
					Cancelar
				</Button>
				<Button
					type='filled-tonal'
					variant='default'
					disabled={
						(usuarioAprovador &&
							statusAprovarCancelamento !== 1 &&
							!usuarioResponsavel &&
							!justificativaNaoAprovarCancelamento?.trim()) ||
						(!justificativaCancelar?.trim() && usuarioResponsavel)
					}
					onClick={() => {
						setValue('solicitacaoCancelamento', true);
						setValue('pedidoDeCancelamentoClicado', true);

						if (getValues('statusAprovarCancelamento') === 1) {
							setValue('dataCancelamento', getValues('dataHoje'));
							setValue('bCancelar', true);
						} else {
							setValue('bCancelar', false);
						}

						handleSubmit(getValues());
						onClose();
					}}
				>
					{usuarioResponsavel && usuarioAprovador
						? 'Cancelar obrigação e salvar'
						: usuarioResponsavel
							? 'Solicitar cancelamento e salvar'
							: usuarioAprovador
								? 'Responder cancelamento e salvar'
								: 'Solicitar cancelamento e salvar'}
				</Button>
			</Flex>
		</ModalContainer>
	);
};

export default ModalCancelarObrigacao;
