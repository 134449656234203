import React, { useState, useEffect } from 'react';
import { Button, Col, Flex, Icons, PasswordInput, Steps, TextInput, Typography } from '@jcm/design-system';
import LoginLayout from '../../../layouts/LoginLayout';
import estilo from './index.module.scss';
import { IFormularioSimularTributacao, ValidacoesSenha } from './types';

const ItemValidacao: React.FC<{ id: string; children: React.ReactNode }> = ({ id, children }) => (
	<li id={id} style={{ color: 'black' }}>
		<Flex gap={2}>
			<Icons.CheckCircle />
			<Typography.Label>{children}</Typography.Label>
		</Flex>
	</li>
);

const perguntasSimuladorResgate: IFormularioSimularTributacao[] = [
	{
		componenteInput: (
			<Flex vertical gap={32} align='center'>
				<Col>
					<Typography.Title size='large'>Vamos criar sua senha</Typography.Title>
				</Col>
				<Col>
					<PasswordInput type='password' variant='outlined' id='senha-input' />
					<ul className={estilo.validacaoSenha}>
						<ItemValidacao id='minLength'>Min 8 caracteres</ItemValidacao>
						<ItemValidacao id='upperCase'>Um maiúsculo</ItemValidacao>
						<ItemValidacao id='number'>Um número</ItemValidacao>
						<ItemValidacao id='specialChar'>Um caractere especial</ItemValidacao>
					</ul>
				</Col>
			</Flex>
		),
	},
	{
		componenteInput: (
			<Flex vertical gap={32} align='center'>
				<Col xl={24}>
					<Typography.Title size='large'>Qual o seu nome?</Typography.Title>
				</Col>
				<Col xl={24}>
					<TextInput label='Nome' placeholder='Nome' type='text' variant='outlined' />
				</Col>
				<Col xl={24}>
					<TextInput label='Sobrenome' placeholder='Sobrenome' type='text' variant='outlined' />
				</Col>
			</Flex>
		),
	},
	{
		componenteInput: (
			<Flex vertical gap={32} align='center'>
				<Col xl={24}>
					<Typography.Body size='large'>Que bom te ver aqui, Fulano</Typography.Body>
				</Col>
				<Col xl={24}>
					<Flex vertical gap={32}>
						<Col>
							<Flex vertical gap={32} align='center'>
								<Typography.Title size='large'>Em que setor você trabalha?</Typography.Title>
								<TextInput
									label='Setor'
									suffix={<Icons.Search variant='outlined' />}
									type='text'
									variant='outlined'
									className={estilo.botoesCadastrarUsuarios}
								/>
							</Flex>
						</Col>
						<Col>
							<Flex vertical gap={32} align='center'>
								<Typography.Title size='large'>Qual sua função?</Typography.Title>
								<TextInput
									label='Função'
									suffix={<Icons.Search variant='outlined' />}
									type='text'
									variant='outlined'
									className={estilo.botoesCadastrarUsuarios}
								/>
							</Flex>
						</Col>
					</Flex>
				</Col>
			</Flex>
		),
	},
	{
		componenteInput: (
			<Flex vertical gap={32} align='center'>
				<h1>Ainda não foi implementado.</h1>
			</Flex>
		),
	},
	{
		componenteInput: (
			<Flex vertical gap={32} align='center'>
				<h1>Ainda não foi implementado.</h1>
			</Flex>
		),
	},
	{
		componenteInput: (
			<Flex vertical gap={32} align='center'>
				<h1>Ainda não foi implementado.</h1>
			</Flex>
		),
	},
];

const CadastrarUsuario: React.FC = () => {
	const [etapaAtual, setEtapaAtual] = useState<number>(0);
	const [validacoes, setValidacoes] = useState<ValidacoesSenha>({
		minLength: false,
		upperCase: false,
		number: false,
		specialChar: false,
	});

	const validarSenha = (senha: string) => {
		setValidacoes({
			minLength: senha.length >= 8,
			upperCase: /[A-Z]/.test(senha),
			number: /[0-9]/.test(senha),
			specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(senha),
		});
	};

	useEffect(() => {
		const senhaInput = document.getElementById('senha-input') as HTMLInputElement;

		const handleInput = (e: Event) => {
			const target = e.target as HTMLInputElement;
			validarSenha(target.value);
		};

		senhaInput?.addEventListener('input', handleInput);

		return () => {
			senhaInput?.removeEventListener('input', handleInput);
		};
	}, []);

	useEffect(() => {
		const updateValidationStyles = (id: string, isValid: boolean) => {
			const element = document.getElementById(id);
			if (element) {
				element.style.color = isValid ? 'green' : 'black';
			}
		};

		Object.entries(validacoes).forEach(([key, value]) => {
			updateValidationStyles(key, value);
		});
	}, [validacoes]);

	const avancarProximaPergunta = () => {
		setEtapaAtual(etapaAtual + 1);
	};

	return (
		<LoginLayout>
			<Flex vertical align='center' gap={56}>
				<Col xl={24}>
					<Steps
						className={estilo.step}
						current={etapaAtual}
						items={perguntasSimuladorResgate.map((item, index) => ({
							icon: <div className={estilo.numerosStepComponente}>{index + 1}</div>,
						}))}
					/>
				</Col>
				<Col>
					<Flex vertical gap={32}>
						{perguntasSimuladorResgate[etapaAtual]?.componenteInput}
						<Col>
							<Button
								variant='default'
								type='filled'
								onClick={avancarProximaPergunta}
								className={estilo.botaoConfirmarCadastroUsuario}
								disabled={etapaAtual === 0 && !Object.values(validacoes).every(Boolean)}
							>
								Confirmar
							</Button>
						</Col>
					</Flex>
				</Col>
			</Flex>
		</LoginLayout>
	);
};

export default CadastrarUsuario;
