import { Popover } from 'antd';
import { Avatar, Button, Card, Flex, Icons, Typography, useTheme } from '@jcm/design-system';
import { memo, useState } from 'react';
// import { useNavigate } from 'react-router-dom';

// import { useKeycloakContext } from 'context';
// import { useNavigateWithSearchParams } from '../../../hooks';

import styles from './index.module.scss';
import { useKeycloakContext } from '../../../../../../context/KeycloakContext';

type AvatarPopoverProps = { children: React.ReactNode; isOpen: boolean; onOpenChange: (newValue: boolean) => void };
const AvatarPopover = memo<AvatarPopoverProps>(({ children, isOpen, onOpenChange }) => {
	// const navigate = useNavigate();
	// const navigateWithSearchParams = useNavigateWithSearchParams();
	const { user, logout } = useKeycloakContext();

	// const { user, login, logout } = useKeycloakContext();
	const { colors, shapes } = useTheme();

	// const doNewLogin = async () => {
	// 	await logout();
	// 	await login({ loginHint: '' });
	// };

	// const doLogout = async () => {
	// 	navigate('/');
	// 	await logout();
	// };

	const content = (
		<Flex gap={shapes.sizes.medium} vertical>
			<Flex gap={shapes.sizes.extraSmall} vertical>
				<Card
					id='avatar-popover-content-card'
					variant='default'
					className={styles.card}
					style={{
						padding: shapes.sizes.large,
						minWidth: '300px',
						borderEndStartRadius: 0,
						borderEndEndRadius: 0,
					}}
				>
					<Flex gap={shapes.sizes.large} style={{ width: '100%' }} vertical>
						<Flex align='center' gap={shapes.sizes.medium}>
							<Avatar
								size='large'
								className={styles.avatar}
								style={{
									backgroundColor: colors.secondaryContainer,
									color: colors.onSecondaryContainer,
								}}
							>
								{/* {user?.name
									? user?.name.slice(0, 2).toLocaleUpperCase()
									: user?.preferred_username.slice(0, 2).toLocaleUpperCase()} */}
								Nome temporário
							</Avatar>
							<Flex gap='0.25rem' className={styles.userInfoSpace} vertical>
								<Typography.Body variant='default' size='large' className={styles.userName}>
									{/* {user?.name ?? user?.preferred_username ?? 'Usuário'} */}
									{user?.name}
								</Typography.Body>
								<Typography.Body variant='default' size='small' className={styles.userEmail}>
									{/* {user?.email ?? 'usuario@email.com'} */}
									{user?.email}
								</Typography.Body>
							</Flex>
						</Flex>

						{/* <Flex justify='end'>
							<Button
								id='gerenciar-perfil-btn'
								type='outlined'
								variant='default'
								onClick={() => {
									onOpenChange(false);
									navigateWithSearchParams('dados-cadastrais');
								}}
							>
								Alterar senha
							</Button>
						</Flex> */}
					</Flex>
				</Card>
			</Flex>

			<Flex style={{ padding: `0 ${shapes.sizes.large}` }}>
				<Button
					id='logout-btn'
					type='text'
					variant='default'
					className={styles.btnJustifyStart}
					icon={<Icons.Logout variant='outlined' style={{ fontSize: '24px' }} />}
					style={{ width: '100%' }}
					onClick={() => {
						logout();
					}}
				>
					Sair
				</Button>
			</Flex>
		</Flex>
	);

	return (
		<Popover
			overlayClassName={styles.popover}
			color={colors.secondaryContainer}
			placement='rightBottom'
			trigger='click'
			content={content}
			open={isOpen}
			onOpenChange={onOpenChange}
		>
			{children}
		</Popover>
	);
});

type UserAvatarProps = { nome?: string; status?: string };
export const UserAvatar = memo<UserAvatarProps>(({ nome, status }) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const { colors, shapes } = useTheme();

	return (
		<AvatarPopover isOpen={isPopoverOpen} onOpenChange={(newValue) => setIsPopoverOpen(newValue)}>
			<Flex align='flex-start' justify='center' gap={shapes.sizes.small}>
				<Avatar
					size='large'
					className={`${styles.avatar} botaoPerfil`}
					style={{
						backgroundColor: colors.secondaryContainer,
						color: colors.onSecondaryContainer,
					}}
				>
					<Icons.Person variant='outlined' />
				</Avatar>
				<Flex gap={shapes.sizes.extraSmall} vertical style={{ userSelect: 'none' }}>
					<Typography.Title size='medium' variant='primary' style={{ fontWeight: 500 }}>
						{nome}
					</Typography.Title>
					<Typography.Body size='large' variant='default' style={{ color: colors.onSurfaceVariant }}>
						{status}
					</Typography.Body>
				</Flex>
			</Flex>
		</AvatarPopover>
	);
});
