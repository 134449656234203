import React, { useState, useEffect } from 'react';
import { Button, TextInput, Row, Col, Select, Switch } from '@jcm/design-system';
import estilo from './style.module.scss';
import ObrigacoesService from '../../../services/Obrigacoes/Obrigacoes.service';
import { TFuncionario } from '../../../ts/types/Funcionarios';
import { FieldErrors, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGovernancaContext } from '../../../../../../context/GovernancaContext';
import CustomCollapse from './CustomCollapse';
import CampoComAviso from './CampoComAviso';
import {
	ModalEdicaoEmMassaZodSchema,
	ModalEdicaoEmMassaFormValues,
} from '../../../zod/validations/ModalEdicaoEmMassaZodSchema';
import { EnumTipoAntencedenciaEmail, EnumTipoEmailFrequenciaEnvio } from '../../../ts/enums/RegrasEnvioEmailEnum';
import { CloseOutlined } from '@mui/icons-material';
import CustomAlert from '../../../../../../shared/components/customAlert/customAlert';
import { EditarObrigacoesEmMassaPayload } from '../../../ts/types/Obrigacoes';
import ModalContainer from '../../../components/modalContainer/ModalContainer';
import BarraDeProgresso from '../../../components/ProgressBar/BarraDeProgresso';

interface ModificacaoSelect {
	Ids: number[];
	LimparCampo: boolean;
}

interface ModificacaoText {
	Text: string;
	LimparCampo: boolean;
}

interface ModalEdicaoEmMassaProps {
	empresaId: number | string;
	selectedObrigacoes: number[];
	onClose: () => void;
	onAtualizar: () => void;
	resetForm: (reset: () => void) => void;
	setActiveKeys: React.Dispatch<React.SetStateAction<string[]>>;
	activeKeys: string[];
}

const ModalEdicaoEmMassa: React.FC<ModalEdicaoEmMassaProps> = ({
	empresaId,
	selectedObrigacoes,
	onClose,
	onAtualizar,
	resetForm,
	setActiveKeys,
	activeKeys,
}) => {
	const obrigacoesService = new ObrigacoesService();
	const [funcionarios, setFuncionarios] = useState<TFuncionario[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [progresso, setProgresso] = useState(0);
	const [ehLegalEditado, setEhLegalEditado] = useState<boolean>(false);
	const { usuario } = useGovernancaContext();
	const [alert, setAlert] = useState<{ type: 'error' | 'success' | 'info'; content: string; title?: string } | null>(
		null,
	);
	const [tempoEstimado, setTempoEstimando] = useState<number | undefined>(undefined);

	useEffect(() => {
		obrigacoesService
			.getAllUsuarios(empresaId)
			.then(setFuncionarios)
			.catch((error) => console.error('Erro ao buscar funcionários:', error));
	}, [empresaId]);

	const {
		handleSubmit,
		formState: { errors },
		setValue,
		watch,
		reset,
	} = useForm<ModalEdicaoEmMassaFormValues>({
		resolver: zodResolver(ModalEdicaoEmMassaZodSchema),
		defaultValues: {
			responsavelPrincipal: undefined,
			aprovadorPrincipal: undefined,
			ehLegal: undefined,
		},
	});

	useEffect(() => {
		resetForm(() => reset);
		setActiveKeys([]);
	}, [reset, resetForm, setActiveKeys]);

	const handleToggle = (key: string) => {
		setActiveKeys((prevKeys) => {
			const newKeys = prevKeys.includes(key) ? prevKeys.filter((k) => k !== key) : [...prevKeys, key];

			const camposVerificar = {
				'7': 'acompanhamento',
				'8': 'fundamentacaoLegal',
				'9': 'prazoLegal',
				'10': 'datas',
				'11': 'temporalidade',
			} as const;

			if (!prevKeys.includes(key) && (key as keyof typeof camposVerificar) in camposVerificar) {
				const campo = camposVerificar[key as keyof typeof camposVerificar];
				const valorAtual = watch(campo);
				if (!valorAtual) {
					setValue(campo, '');
				}
			}

			return newKeys;
		});
	};

	const onSubmit = async (formData: ModalEdicaoEmMassaFormValues) => {
		setIsLoading(true);
		setProgresso(0);

		const camposVerificar = {
			'7': 'acompanhamento',
			'8': 'fundamentacaoLegal',
			'9': 'prazoLegal',
			'10': 'datas',
			'11': 'temporalidade',
		} as const;

		(Object.keys(camposVerificar) as Array<keyof typeof camposVerificar>).forEach((key) => {
			const campo = camposVerificar[key];
			if (activeKeys.includes(key) && !formData[campo]) {
				formData[campo] = '';
			}
		});

		try {
			const totalObrigacoes = selectedObrigacoes.length;
			const incremento = Math.round((100 / totalObrigacoes) * 100) / 100;

			setTempoEstimando(Number((totalObrigacoes * 0.5).toFixed(2)));

			for (let i = 0; i < totalObrigacoes; i++) {
				const payload: EditarObrigacoesEmMassaPayload = {
					IdsObrigacoes: [selectedObrigacoes[i]],
					ResponsavelPrincipal: formData.responsavelPrincipal ?? 0,
					AprovadorPrincipal: formData.aprovadorPrincipal ?? 0,
					Nome: formData.descricao?.trim() || '',
					EhLegal: ehLegalEditado ? (formData.ehLegal ?? false) : false,
					EhLegalEditado: ehLegalEditado,
					FrequenciaSelecionada: formData.frequenciaSelecionada ?? 0,
					AntecedenciaSelecionada: formData.antecedenciaSelecionada ?? 0,
					FrequenciaVencidaSelecionada: formData.frequenciaVencidaSelecionada ?? 0,
					ModificacoesSelect: ensureArrayLength<ModificacaoSelect>(
						[
							{
								Ids: formData.responsaveisSecundarios || [],
								LimparCampo: formData.responsaveisSecundarios?.length === 0,
							},
							{
								Ids: formData.aprovadoresSecundarios || [],
								LimparCampo: formData.aprovadoresSecundarios?.length === 0,
							},
						],
						4,
						{ Ids: [], LimparCampo: false },
					),
					ModificacoesText: ensureArrayLength<ModificacaoText>(
						[
							{ Text: formData.acompanhamento || '', LimparCampo: !formData.acompanhamento },
							{ Text: formData.datas || '', LimparCampo: !formData.datas },
							{ Text: formData.formaCumprimento || '', LimparCampo: !formData.formaCumprimento },
							{ Text: formData.temporalidade || '', LimparCampo: !formData.temporalidade },
							{ Text: formData.prazoLegal || '', LimparCampo: !formData.prazoLegal },
							{ Text: formData.fundamentacaoLegal || '', LimparCampo: !formData.fundamentacaoLegal },
						],
						8,
						{ Text: '', LimparCampo: true },
					),
					Username: usuario ? usuario.nome : 'Nome não disponível',
				};

				const response = await obrigacoesService.editarObrigacoesEmMassa(empresaId, payload);
				if (!response.isSuccess) {
					console.error('Erro ao salvar obrigação:', response.errors); // Log de erro
					setAlert({
						type: 'error',
						content: response.errors ?? 'Erro desconhecido.',
						title: 'Erro ao salvar',
					});
					break;
				}

				setProgresso((prev) => Math.round((prev + incremento) * 100) / 100);
			}

			setProgresso(100);
			setAlert({
				type: 'success',
				content: 'Edição em massa salva com sucesso!',
				title: 'Sucesso',
			});
			onAtualizar();
			onClose();
		} catch (error) {
			console.error('Erro capturado:', error);
			setAlert({
				type: 'error',
				content: error instanceof Error ? error.message : 'Erro ao salvar edição em massa.',
				title: 'Erro',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const onInvalid = (errors: FieldErrors<ModalEdicaoEmMassaFormValues>) => {
		const missingFields = Object.keys(errors)
			.filter((field) => ['responsavelPrincipal', 'aprovadorPrincipal'].includes(field))
			.map((field) => {
				switch (field) {
					case 'responsavelPrincipal':
						return 'Responsável Principal';
					case 'aprovadorPrincipal':
						return 'Aprovador Principal';
					default:
						return field;
				}
			})
			.join(', ');

		if (missingFields) {
			const formattedFields = missingFields.replace(/,([^,]*)$/, ' e$1');

			setAlert(null);

			setTimeout(() => {
				setAlert({
					type: 'info',
					content: `Por favor, preencha os seguintes campos obrigatórios: ${formattedFields}.`,
					title: 'Preenchimento obrigatório',
				});
			}, 10);
		}
	};

	function ensureArrayLength<T>(array: T[], length: number, defaultValue: T): T[] {
		while (array.length < length) {
			array.push(defaultValue);
		}
		return array;
	}

	const handleCollapseChange = (key: string) => {
		setEhLegalEditado(key === 'ehLegal');
	};

	const handleClose = (key: string) => {
		setActiveKeys((prevKeys) => prevKeys.filter((k) => k !== key));

		const campos: { [key: string]: keyof ModalEdicaoEmMassaFormValues } = {
			'3': 'responsaveisSecundarios',
			'4': 'aprovadoresSecundarios',
			'5': 'descricao',
			'7': 'acompanhamento',
			'8': 'fundamentacaoLegal',
			'9': 'prazoLegal',
			'10': 'datas',
			'11': 'temporalidade',
			'12': 'formaCumprimento',
		};

		if (campos[key]) {
			const valorLimpo = Array.isArray(watch(campos[key])) ? [] : '';
			setValue(campos[key], valorLimpo);
		}
	};

	const createLabelWithClose = (label: string, key: string) => (
		<div className={estilo.labelComFechar}>
			<div
				className={estilo.headerClickable}
				onClick={(e) => {
					e.stopPropagation();
					handleToggle(key);
				}}
			>
				<span>{label}</span>
			</div>

			{activeKeys.includes(key) && (
				<CloseOutlined
					className={estilo.iconeFechar}
					onClick={(e) => {
						e.stopPropagation();
						handleClose(key);
					}}
				/>
			)}
		</div>
	);

	const getErrorMessage = (nomeDoCampo: string): string => `${nomeDoCampo} é obrigatório(a)`;

	const handleCloseModalFromButton = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		handleCloseModal();
	};

	const handleCloseModal = () => {
		setIsLoading(false);
		onClose();
	};

	return (
		<div className={estilo.modalEdicaoEmMassa}>
			{isLoading && (
				<ModalContainer
					isVisible={isLoading}
					onClose={handleCloseModal}
					title='Salvando...'
					fechamentoPermitido={progresso === 100}
				>
					<BarraDeProgresso cor='#38939c' progresso={progresso} tempoEstimado={tempoEstimado} />
				</ModalContainer>
			)}

			<form onSubmit={handleSubmit(onSubmit, onInvalid)}>
				{alert && <CustomAlert type={alert.type} content={alert.content} title={alert.title} />}

				<div className='responsaveis-principais'>
					<h4>Responsáveis e Aprovadores Principais</h4>
					<Row gutter={16}>
						<Col span={12}>
							<CustomCollapse
								activeKey={activeKeys.includes('1') ? '1' : undefined}
								onChange={() => handleToggle('1')}
								items={[
									{
										key: 1,
										label: createLabelWithClose('Editar responsável principal', '1'),
										children: (
											<CampoComAviso
												rotulo='Responsável principal'
												erro={
													errors.responsavelPrincipal
														? { type: 'required', message: getErrorMessage('Responsável principal') }
														: undefined
												}
											>
												<Select
													options={
														funcionarios?.map((func: TFuncionario) => ({
															label: func.nome,
															value: func.funcionarioId,
														})) ?? []
													}
													value={watch('responsavelPrincipal')}
													onChange={(value: number) => setValue('responsavelPrincipal', value)}
													placeholder='Selecione o responsável principal'
												/>
											</CampoComAviso>
										),
									},
								]}
							/>
						</Col>
						<Col span={12}>
							<CustomCollapse
								activeKey={activeKeys.includes('2') ? '2' : undefined}
								onChange={() => handleToggle('2')}
								items={[
									{
										key: 2,
										label: createLabelWithClose('Editar aprovador principal', '2'),

										children: (
											<CampoComAviso
												rotulo='Aprovador principal'
												erro={
													errors.responsavelPrincipal
														? { type: 'required', message: getErrorMessage('Aprovador principal') }
														: undefined
												}
											>
												<Select
													options={
														funcionarios?.map((func: TFuncionario) => ({
															label: func.nome,
															value: func.funcionarioId,
														})) ?? []
													}
													value={watch('aprovadorPrincipal')}
													onChange={(value: number) => setValue('aprovadorPrincipal', value)}
													placeholder='Selecione o aprovador principal'
												/>
											</CampoComAviso>
										),
									},
								]}
							/>
						</Col>
					</Row>
				</div>
				<div className='responsaveis-secundarios'>
					<h4>Responsáveis e Aprovadores Secundários</h4>
					<Row gutter={16}>
						<Col span={12}>
							<CustomCollapse
								activeKey={activeKeys.includes('3') ? '3' : undefined}
								onChange={() => handleToggle('3')}
								items={[
									{
										key: 3,

										label: createLabelWithClose('Editar responsáveis secundários', '3'),

										children: (
											<CampoComAviso rotulo='Responsáveis secundários'>
												<Select
													options={funcionarios.map((func: TFuncionario) => ({
														label: func.nome,
														value: func.funcionarioId,
													}))}
													value={watch('responsaveisSecundarios')}
													onChange={(value: number[]) => setValue('responsaveisSecundarios', value)}
													mode='multiple'
													placeholder='Selecione os responsáveis secundários'
												/>
											</CampoComAviso>
										),
									},
								]}
							/>
						</Col>

						<Col span={12}>
							<CustomCollapse
								activeKey={activeKeys.includes('4') ? '4' : undefined}
								onChange={() => handleToggle('4')}
								items={[
									{
										key: 4,
										label: createLabelWithClose('Editar aprovadores secundários', '4'),

										children: (
											<CampoComAviso rotulo='Aprovadores secundários'>
												<Select
													options={funcionarios.map((func) => ({
														label: func.nome,
														value: func.funcionarioId,
													}))}
													value={watch('aprovadoresSecundarios')}
													onChange={(value) => setValue('aprovadoresSecundarios', value)}
													mode='multiple'
													placeholder='Selecione os aprovadores secundários'
												/>
											</CampoComAviso>
										),
									},
								]}
							/>
						</Col>
					</Row>
				</div>
				<div className={estilo.descricaoOutrasInfo}>
					<h4>Descrição e outros</h4>

					<Row gutter={[16, 16]}>
						<Col span={12}>
							<CustomCollapse
								activeKey={activeKeys.includes('5') ? '5' : undefined}
								onChange={() => handleToggle('5')}
								items={[
									{
										key: 5,
										label: createLabelWithClose('Editar Descrição', '5'),

										children: (
											<CampoComAviso rotulo='Descrição' erro={errors.descricao}>
												<TextInput
													type='text'
													value={watch('descricao')}
													onChange={(e) => setValue('descricao', e.target.value)}
												/>
											</CampoComAviso>
										),
									},
								]}
							/>
						</Col>

						<Col span={12}>
							<CustomCollapse
								activeKey={activeKeys.includes('ehLegal') ? 'ehLegal' : undefined}
								onChange={() => {
									handleToggle('ehLegal');
									handleCollapseChange('ehLegal');
								}}
								items={[
									{
										key: 'ehLegal',
										label: createLabelWithClose('Editar Obrigação Legal', 'ehLegal'),
										children: (
											<CampoComAviso rotulo='' erro={errors.ehLegal}>
												<div style={{ display: 'flex', alignItems: 'center', gap: '8px', margin: '10px 0' }}>
													<Switch
														id='Switch'
														checked={watch('ehLegal')}
														onChange={(checked) => setValue('ehLegal', checked, { shouldValidate: true })}
													/>
													<span>{watch('ehLegal') ? 'Legal' : 'Própria'}</span>
												</div>
											</CampoComAviso>
										),
									},
								]}
							/>
						</Col>

						<Col span={12}>
							<CustomCollapse
								activeKey={activeKeys.includes('7') ? '7' : undefined}
								onChange={() => handleToggle('7')}
								items={[
									{
										key: 7,

										label: createLabelWithClose('Editar Acompanhamento', '7'),

										children: (
											<CampoComAviso isOptional rotulo='Acompanhamento'>
												<TextInput
													type='text'
													value={watch('acompanhamento')}
													onChange={(e) => setValue('acompanhamento', e.target.value)}
												/>
											</CampoComAviso>
										),
									},
								]}
							/>
						</Col>

						<Col span={12}>
							<CustomCollapse
								activeKey={activeKeys.includes('8') ? '8' : undefined}
								onChange={() => handleToggle('8')}
								items={[
									{
										key: 8,
										label: createLabelWithClose('Editar Fundamentação legal', '8'),

										children: (
											<CampoComAviso isOptional rotulo='Fundamentação Legal'>
												<TextInput
													type='text'
													value={watch('fundamentacaoLegal')}
													onChange={(e) => setValue('fundamentacaoLegal', e.target.value)}
												/>
											</CampoComAviso>
										),
									},
								]}
							/>
						</Col>

						<Col span={12}>
							<CustomCollapse
								activeKey={activeKeys.includes('9') ? '9' : undefined}
								onChange={() => handleToggle('9')}
								items={[
									{
										key: 9,

										label: createLabelWithClose('Editar Prazo legal', '9'),

										children: (
											<CampoComAviso isOptional rotulo='Prazo legal'>
												<TextInput
													type='text'
													value={watch('prazoLegal')}
													onChange={(e) => setValue('prazoLegal', e.target.value)}
												/>
											</CampoComAviso>
										),
									},
								]}
							/>
						</Col>

						<Col span={12}>
							<CustomCollapse
								activeKey={activeKeys.includes('10') ? '10' : undefined}
								onChange={() => handleToggle('10')}
								items={[
									{
										key: 10,

										label: createLabelWithClose('Editar Datas', '10'),

										children: (
											<CampoComAviso isOptional rotulo='Datas'>
												<TextInput
													type='text'
													value={watch('datas')}
													onChange={(e) => setValue('datas', e.target.value)}
												/>
											</CampoComAviso>
										),
									},
								]}
							/>
						</Col>

						<Col span={12}>
							<CustomCollapse
								activeKey={activeKeys.includes('11') ? '11' : undefined}
								onChange={() => handleToggle('11')}
								items={[
									{
										key: 11,
										label: createLabelWithClose('Editar Temporalidade', '11'),

										children: (
											<CampoComAviso isOptional rotulo='Temporalidade'>
												<TextInput
													type='text'
													value={watch('temporalidade')}
													onChange={(e) => setValue('temporalidade', e.target.value)}
												/>
											</CampoComAviso>
										),
									},
								]}
							/>
						</Col>

						<Col span={12}>
							<CustomCollapse
								activeKey={activeKeys.includes('12') ? '12' : undefined}
								onChange={() => handleToggle('12')}
								items={[
									{
										key: 12,

										label: createLabelWithClose('Editar Forma de cumprimentol', '12'),

										children: (
											<CampoComAviso isOptional rotulo='Forma de cumprimento'>
												<TextInput
													type='text'
													value={watch('formaCumprimento')}
													onChange={(e) => setValue('formaCumprimento', e.target.value)}
												/>
											</CampoComAviso>
										),
									},
								]}
							/>
						</Col>
					</Row>
				</div>
				<div className='configuracoes-envio'>
					<h4>Regra de Envio de Email</h4>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<CustomCollapse
								activeKey={activeKeys.includes('13') ? '13' : undefined}
								onChange={() => handleToggle('13')}
								items={[
									{
										key: 13,
										label: createLabelWithClose('Editar regra de envio de email', '13'),
										children: (
											<Row gutter={[16, 16]}>
												<Col span={24}>
													<CampoComAviso rotulo='Frequência de envio'>
														<Select
															value={watch('frequenciaSelecionada') as EnumTipoEmailFrequenciaEnvio}
															onChange={(value: EnumTipoEmailFrequenciaEnvio) =>
																setValue('frequenciaSelecionada', value)
															}
															options={[
																{ label: 'Diário', value: EnumTipoEmailFrequenciaEnvio.Diario },
																{ label: 'Semanal', value: EnumTipoEmailFrequenciaEnvio.Semanal },
																{ label: 'Quinzenal', value: EnumTipoEmailFrequenciaEnvio.Quinzenal },
																{ label: 'Mensal', value: EnumTipoEmailFrequenciaEnvio.Mensal },
																{ label: 'Única', value: EnumTipoEmailFrequenciaEnvio.Unica },
															]}
														/>
													</CampoComAviso>
												</Col>

												<Col span={24}>
													<CampoComAviso rotulo='Antecedência de envio'>
														<Select
															value={watch('antecedenciaSelecionada') as EnumTipoAntencedenciaEmail}
															onChange={(value: EnumTipoAntencedenciaEmail) =>
																setValue('antecedenciaSelecionada', value)
															}
															options={[
																{ label: '1 dia', value: EnumTipoAntencedenciaEmail.UmDia },
																{ label: '3 dias', value: EnumTipoAntencedenciaEmail.TresDias },
																{ label: '1 semana', value: EnumTipoAntencedenciaEmail.UmaSemana },
																{ label: '2 semanas', value: EnumTipoAntencedenciaEmail.DuasSemanas },
																{ label: '1 mês', value: EnumTipoAntencedenciaEmail.UmMes },
																{ label: 'Criação', value: EnumTipoAntencedenciaEmail.Criacao },
																{ label: 'Somente Vencimento', value: EnumTipoAntencedenciaEmail.SomenteVencimento },
															]}
														/>
													</CampoComAviso>
												</Col>

												<Col span={24}>
													<CampoComAviso rotulo='Frequência após vencimento'>
														<Select
															value={watch('frequenciaVencidaSelecionada') as EnumTipoEmailFrequenciaEnvio}
															onChange={(value: EnumTipoEmailFrequenciaEnvio) =>
																setValue('frequenciaVencidaSelecionada', value)
															}
															options={[
																{ label: 'Diário', value: EnumTipoEmailFrequenciaEnvio.Diario },
																{ label: 'Semanal', value: EnumTipoEmailFrequenciaEnvio.Semanal },
																{ label: 'Quinzenal', value: EnumTipoEmailFrequenciaEnvio.Quinzenal },
																{ label: 'Mensal', value: EnumTipoEmailFrequenciaEnvio.Mensal },
															]}
														/>
													</CampoComAviso>
												</Col>
											</Row>
										),
									},
								]}
							/>
						</Col>
					</Row>
				</div>
				<div className={estilo.buttonsContainer}>
					<Button type='outlined' onClick={handleCloseModalFromButton}>
						Cancelar
					</Button>
					<Button type='filled-tonal' variant='default' htmlType='submit' disabled={isLoading}>
						{isLoading ? 'Salvando...' : 'Salvar'}
					</Button>
				</div>
			</form>
		</div>
	);
};

export default ModalEdicaoEmMassa;
