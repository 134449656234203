import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import logoABrapp from '../../../modules/modulo-obrigacoes/src/assets/images/logos/AbrappMini.svg';

const HeadManager: React.FC = () => {
	const location = useLocation();

	useEffect(() => {
		const path = location.pathname;

		if (path.startsWith('/obrigacoes')) {
			document.title = 'Calendário Obrigações | Abrapp';
			setFavicon(logoABrapp);
		}
	}, [location]);

	const setFavicon = (iconPath: string) => {
		const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
		link.type = 'image/x-icon';
		link.rel = 'shortcut icon';
		link.href = iconPath;
		document.getElementsByTagName('head')[0].appendChild(link);
	};

	return (
		<Helmet>
			<meta charSet='utf-8' />
			<title>{document.title}</title>
		</Helmet>
	);
};

export default HeadManager;
