import React, { useState, useEffect } from 'react';
import ObrigacoesService from '../../../services/Obrigacoes/Obrigacoes.service';
import { useGovernancaContext } from '../../../../../../context/GovernancaContext';
import { TOrigem } from '../../../ts/types/Origem';
import { Select, Typography, Button, Tag, TextInput, Icons } from '@jcm/design-system';
import { Control, Controller } from 'react-hook-form';
import './FiltrosObrigacoes.scss';
import { FiltrosObrigacoesEnum } from '../../../ts/enums/FiltrosObrigacoesEnum';
import { Menu, MenuItem } from '@mui/material';
import { ClearOutlined } from '@ant-design/icons';
import ModalContainer from '../../../components/modalContainer/ModalContainer';

interface FiltrosObrigacoesFormValues {
	[FiltrosObrigacoesEnum.Origem]: string | number;
	[FiltrosObrigacoesEnum.Responsavel]: string | number;
	[FiltrosObrigacoesEnum.Aprovador]: string | number;
	[FiltrosObrigacoesEnum.Situacao]: string;
	[FiltrosObrigacoesEnum.Tipo]: string;
	[FiltrosObrigacoesEnum.Descricao]: string;
}

interface FiltrosObrigacoesProps {
	aoAplicarFiltros: (filtrosSelecionados: Record<string, string | number>) => void;
	listaResponsaveis: { funcionarioId: number; nome: string }[];
	listaAprovadores: { funcionarioId: number; nome: string }[];
	control: Control<FiltrosObrigacoesFormValues>;
}

const FiltrosObrigacoes: React.FC<FiltrosObrigacoesProps> = ({
	aoAplicarFiltros,
	listaResponsaveis,
	listaAprovadores,
	control,
}) => {
	const [filtrosSelecionados, setFiltrosSelecionados] = useState<Record<string, string | number>>({
		[FiltrosObrigacoesEnum.Origem]: '',
		[FiltrosObrigacoesEnum.Responsavel]: '',
		[FiltrosObrigacoesEnum.Aprovador]: '',
		[FiltrosObrigacoesEnum.Situacao]: '',
		[FiltrosObrigacoesEnum.Tipo]: '',
		[FiltrosObrigacoesEnum.Descricao]: '',
	});
	const [tagsAtivas, setTagsAtivas] = useState<Record<string, boolean>>({});
	const [origens, setOrigens] = useState<TOrigem[]>([]);
	const [carregandoOrigens, setCarregandoOrigens] = useState(true);
	const [elementoAncora, setElementoAncora] = useState<null | HTMLElement>(null);
	const [selecaoAberta, setSelecaoAberta] = useState<string | null>(null);
	const [descricaoTemporaria, setDescricaoTemporaria] = useState<string>('');
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [repositionModal, setRepositionModal] = useState(false);

	const obrigacoesService = new ObrigacoesService();
	const { empresaAtiva } = useGovernancaContext();

	async function buscarOrigens(): Promise<void> {
		if (empresaAtiva !== null) {
			try {
				const respostaOrigens = await obrigacoesService.getAllOrigem(empresaAtiva);
				setOrigens(respostaOrigens);
			} catch (erro) {
				console.error('Erro ao buscar origens:', erro);
			} finally {
				setCarregandoOrigens(false);
			}
		}
	}

	useEffect(() => {
		if (empresaAtiva) {
			buscarOrigens();
		}
	}, [empresaAtiva]);

	const abrirModal = (filtro: FiltrosObrigacoesEnum, tagRef: HTMLElement | null) => {
		if (tagRef) {
			const rect = tagRef.getBoundingClientRect();
			const topPosition = `${rect.bottom + window.scrollY + 5}px`;
			const leftPosition = `${rect.left + window.scrollX}px`;

			document.documentElement.style.setProperty('--top-position', topPosition);
			document.documentElement.style.setProperty('--left-position', leftPosition);
		}
		setSelecaoAberta(filtro);
		setIsModalVisible(true);
		setRepositionModal(true);
	};

	useEffect(() => {
		if (repositionModal) {
			const tagElement = document.querySelector('.custom-tag[data-active="true"]');
			if (tagElement) {
				const rect = tagElement.getBoundingClientRect();
				const topPosition = `${rect.bottom + window.scrollY + 5}px`;
				const leftPosition = `${rect.left + window.scrollX}px`;

				document.documentElement.style.setProperty('--top-position', topPosition);
				document.documentElement.style.setProperty('--left-position', leftPosition);
			}
			setRepositionModal(false);
		}
	}, [repositionModal]);

	const fecharModal = () => {
		if (selecaoAberta && !tagsAtivas[selecaoAberta]) {
			alterarFiltro(selecaoAberta as FiltrosObrigacoesEnum, '');
			setTagsAtivas((prev) => ({ ...prev, [selecaoAberta]: false }));
		}
		setSelecaoAberta(null);
		setIsModalVisible(false);
	};

	const alterarFiltro = (chave: FiltrosObrigacoesEnum, valor: string | number) => {
		const novosFiltros = { ...filtrosSelecionados };

		if (chave === FiltrosObrigacoesEnum.Descricao) {
			novosFiltros[FiltrosObrigacoesEnum.Nome] = valor;
			novosFiltros[FiltrosObrigacoesEnum.Descricao] = valor;
		} else {
			novosFiltros[chave] = valor;
		}

		setFiltrosSelecionados(novosFiltros);
		aoAplicarFiltros(novosFiltros);
		setTagsAtivas({ ...tagsAtivas, [chave]: true });
		setSelecaoAberta(null);
		setIsModalVisible(false);
	};

	const clicarBotaoFiltros = (evento: React.MouseEvent<HTMLButtonElement>) => {
		setElementoAncora(evento.currentTarget);
	};

	const selecionarFiltro = (filtro: FiltrosObrigacoesEnum) => {
		setFiltrosSelecionados({ ...filtrosSelecionados, [filtro]: 'Selecionar' });
		setElementoAncora(null);
		abrirModal(filtro, null);
	};

	const alterarDescricao = (evento: React.ChangeEvent<HTMLInputElement>) => {
		const valor = evento.target.value;
		setDescricaoTemporaria(valor);
	};

	const aplicarDescricaoFiltro = () => {
		alterarFiltro(FiltrosObrigacoesEnum.Descricao, descricaoTemporaria);
	};

	const limparFiltros = () => {
		setFiltrosSelecionados({
			[FiltrosObrigacoesEnum.Origem]: '',
			[FiltrosObrigacoesEnum.Responsavel]: '',
			[FiltrosObrigacoesEnum.Aprovador]: '',
			[FiltrosObrigacoesEnum.Situacao]: '',
			[FiltrosObrigacoesEnum.Tipo]: '',
			[FiltrosObrigacoesEnum.Descricao]: '',
		});
		setTagsAtivas({});
		aoAplicarFiltros({
			[FiltrosObrigacoesEnum.Origem]: '',
			[FiltrosObrigacoesEnum.Responsavel]: '',
			[FiltrosObrigacoesEnum.Aprovador]: '',
			[FiltrosObrigacoesEnum.Situacao]: '',
			[FiltrosObrigacoesEnum.Tipo]: '',
			[FiltrosObrigacoesEnum.Descricao]: '',
		});
		setDescricaoTemporaria('');
	};

	const obterNomeFormatado = (chave: FiltrosObrigacoesEnum) => {
		switch (chave) {
			case FiltrosObrigacoesEnum.Origem:
				return 'Origem';
			case FiltrosObrigacoesEnum.Responsavel:
				return 'Responsável';
			case FiltrosObrigacoesEnum.Aprovador:
				return 'Aprovador';
			case FiltrosObrigacoesEnum.Situacao:
				return 'Situação';
			case FiltrosObrigacoesEnum.Tipo:
				return 'Tipo';
			case FiltrosObrigacoesEnum.Descricao:
				return 'Descrição';
			default:
				return chave;
		}
	};

	return (
		<div className='filtros-obrigacoes-container'>
			<div className='filtros-wrapper'>
				<div className='button-container'>
					<Button
						variant='default'
						type='outlined'
						icon={<Icons.FilterAlt />}
						onClick={clicarBotaoFiltros}
						className='filtros-button'
					>
						Filtros
					</Button>
				</div>
				{Object.values(filtrosSelecionados).some((filtro) => filtro !== '') && (
					<div className='tags-container'>
						<div className='tags-content'>
							{Object.keys(filtrosSelecionados)
								.filter((chave) => chave !== FiltrosObrigacoesEnum.Nome)
								.map(
									(chave) =>
										filtrosSelecionados[chave] &&
										filtrosSelecionados[chave] !== '' && (
											<Tag
												data-active={selecaoAberta === chave ? 'true' : 'false'}
												className='custom-tag'
												key={chave}
												bordered
												closable
												icon={
													chave === FiltrosObrigacoesEnum.Origem ? (
														<Icons.Home />
													) : chave === FiltrosObrigacoesEnum.Responsavel ? (
														<Icons.Person />
													) : chave === FiltrosObrigacoesEnum.Aprovador ? (
														<Icons.CheckCircle />
													) : chave === FiltrosObrigacoesEnum.Situacao ? (
														<Icons.Info />
													) : chave === FiltrosObrigacoesEnum.Tipo ? (
														<Icons.Help />
													) : chave === FiltrosObrigacoesEnum.Descricao ? (
														<Icons.Edit />
													) : null
												}
												variant='default'
												onClick={(event) =>
													abrirModal(chave as FiltrosObrigacoesEnum, event.currentTarget as HTMLElement)
												}
												onClose={() => {
													alterarFiltro(chave as FiltrosObrigacoesEnum, '');
													setTagsAtivas((prev) => ({ ...prev, [chave]: false }));
												}}
											>
												{obterNomeFormatado(chave as FiltrosObrigacoesEnum)}
											</Tag>
										),
								)}
						</div>
					</div>
				)}

				{Object.values(tagsAtivas).some((value) => value === true) && (
					<Button
						type='outlined'
						variant='default'
						icon={<ClearOutlined />}
						onClick={limparFiltros}
						className='limpar-filtros-button'
					>
						Limpar Filtros
					</Button>
				)}
			</div>

			<Menu anchorEl={elementoAncora} open={Boolean(elementoAncora)} onClose={() => setElementoAncora(null)}>
				<MenuItem
					onClick={() => {
						if (!tagsAtivas[FiltrosObrigacoesEnum.Origem]) {
							selecionarFiltro(FiltrosObrigacoesEnum.Origem);
						}
					}}
					disabled={tagsAtivas[FiltrosObrigacoesEnum.Origem]}
				>
					Origem
				</MenuItem>
				<MenuItem
					onClick={() => {
						if (!tagsAtivas[FiltrosObrigacoesEnum.Responsavel]) {
							selecionarFiltro(FiltrosObrigacoesEnum.Responsavel);
						}
					}}
					disabled={tagsAtivas[FiltrosObrigacoesEnum.Responsavel]}
				>
					Responsável
				</MenuItem>
				<MenuItem
					onClick={() => {
						if (!tagsAtivas[FiltrosObrigacoesEnum.Aprovador]) {
							selecionarFiltro(FiltrosObrigacoesEnum.Aprovador);
						}
					}}
					disabled={tagsAtivas[FiltrosObrigacoesEnum.Aprovador]}
				>
					Aprovador
				</MenuItem>
				<MenuItem
					onClick={() => {
						if (!tagsAtivas[FiltrosObrigacoesEnum.Situacao]) {
							selecionarFiltro(FiltrosObrigacoesEnum.Situacao);
						}
					}}
					disabled={tagsAtivas[FiltrosObrigacoesEnum.Situacao]}
				>
					Situação
				</MenuItem>
				<MenuItem
					onClick={() => {
						if (!tagsAtivas[FiltrosObrigacoesEnum.Tipo]) {
							selecionarFiltro(FiltrosObrigacoesEnum.Tipo);
						}
					}}
					disabled={tagsAtivas[FiltrosObrigacoesEnum.Tipo]}
				>
					Tipo
				</MenuItem>
				<MenuItem
					onClick={() => {
						if (!tagsAtivas[FiltrosObrigacoesEnum.Descricao]) {
							selecionarFiltro(FiltrosObrigacoesEnum.Descricao);
						}
					}}
					disabled={tagsAtivas[FiltrosObrigacoesEnum.Descricao]}
				>
					Descrição
				</MenuItem>
			</Menu>

			{selecaoAberta === FiltrosObrigacoesEnum.Origem && (
				<ModalContainer isVisible={isModalVisible} className='modal-position-top-left' onClose={fecharModal}>
					<Typography.Body>Origem</Typography.Body>
					<Controller
						name={FiltrosObrigacoesEnum.Origem}
						control={control}
						render={({ field }) => (
							<Select
								showSearch
								{...field}
								placeholder='Selecione a Origem'
								options={origens.map((origem) => ({ label: origem.nomeOrigem, value: origem.origemId }))}
								loading={carregandoOrigens}
								onChange={(valor) => alterarFiltro(FiltrosObrigacoesEnum.Origem, valor)}
								filterOption={(input, option) =>
									(option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
								}
							/>
						)}
					/>
				</ModalContainer>
			)}

			{selecaoAberta === FiltrosObrigacoesEnum.Responsavel && (
				<ModalContainer isVisible={isModalVisible} className='modal-position-top-left' onClose={fecharModal}>
					<Typography.Body>Responsável</Typography.Body>
					<Controller
						name={FiltrosObrigacoesEnum.Responsavel}
						control={control}
						render={({ field }) => (
							<Select
								showSearch
								{...field}
								placeholder='Selecione o Responsável'
								options={listaResponsaveis.map((responsavel) => ({
									label: responsavel.nome,
									value: responsavel.funcionarioId,
								}))}
								onChange={(valor) => alterarFiltro(FiltrosObrigacoesEnum.Responsavel, valor)}
								filterOption={(input, option) =>
									(option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
								}
							/>
						)}
					/>
				</ModalContainer>
			)}

			{selecaoAberta === FiltrosObrigacoesEnum.Aprovador && (
				<ModalContainer isVisible={isModalVisible} className='modal-position-top-left' onClose={fecharModal}>
					<Typography.Body>Aprovador</Typography.Body>
					<Controller
						name={FiltrosObrigacoesEnum.Aprovador}
						control={control}
						render={({ field }) => (
							<Select
								showSearch
								{...field}
								placeholder='Selecione o Aprovador'
								options={listaAprovadores.map((aprovador) => ({
									label: aprovador.nome,
									value: aprovador.funcionarioId,
								}))}
								onChange={(valor) => alterarFiltro(FiltrosObrigacoesEnum.Aprovador, valor)}
								filterOption={(input, option) =>
									(option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
								}
							/>
						)}
					/>
				</ModalContainer>
			)}

			{selecaoAberta === FiltrosObrigacoesEnum.Situacao && (
				<ModalContainer isVisible={isModalVisible} className='modal-position-top-left' onClose={fecharModal}>
					<Typography.Body>Situação</Typography.Body>
					<Controller
						name={FiltrosObrigacoesEnum.Situacao}
						control={control}
						render={({ field }) => (
							<Select
								showSearch
								{...field}
								placeholder='Selecione a Situação'
								options={[
									{ label: 'Concluída', value: 'concluida' },
									{ label: 'Em Andamento', value: 'emAndamento' },
								]}
								onChange={(valor) => alterarFiltro(FiltrosObrigacoesEnum.Situacao, valor)}
							/>
						)}
					/>
				</ModalContainer>
			)}

			{selecaoAberta === FiltrosObrigacoesEnum.Tipo && (
				<ModalContainer isVisible={isModalVisible} className='modal-position-top-left' onClose={fecharModal}>
					<Typography.Body>Tipo</Typography.Body>
					<Controller
						name={FiltrosObrigacoesEnum.Tipo}
						control={control}
						render={({ field }) => (
							<Select
								showSearch
								{...field}
								placeholder='Selecione o Tipo'
								options={[
									{ label: 'Legal', value: 'legal' },
									{ label: 'Própria', value: 'propria' },
								]}
								onChange={(valor) => alterarFiltro(FiltrosObrigacoesEnum.Tipo, valor)}
							/>
						)}
					/>
				</ModalContainer>
			)}

			{selecaoAberta === FiltrosObrigacoesEnum.Descricao && (
				<ModalContainer isVisible={isModalVisible} className='modal-position-top-left' onClose={fecharModal}>
					<TextInput
						id='descricao'
						label='Descrição'
						placeholder='Digite a descrição'
						type='text'
						variant='filled'
						value={descricaoTemporaria}
						onChange={alterarDescricao}
					/>
					<Button type='filled' variant='default' onClick={aplicarDescricaoFiltro}>
						Aplicar
					</Button>
				</ModalContainer>
			)}
		</div>
	);
};

export default FiltrosObrigacoes;
