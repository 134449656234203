/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box, Grid } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Button, Flex, Icons, Space, Typography, useTheme } from '@jcm/design-system';
import { Control, UseFormGetValues, UseFormSetValue, UseFormUnregister } from 'react-hook-form';
import { CriarObrigacaoPayload } from '../../../../ts/types/Obrigacoes';
import styles from './index.module.scss';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import { obrigacaoEstaConcluida } from '../../../../utils/determinarAcessoObrigacoes';

interface AcoesProps {
	setValue: UseFormSetValue<any>;
	getValues: UseFormGetValues<any>;
	control: Control<any>;
	unregister: UseFormUnregister<any>;
	usuarioAprovador: boolean;
	usuarioResponsavel: boolean;
	openAlterarVencimentoModal: () => void;
	openConcluirObrigacaoModal: () => void;
	openCancelarObrigacaoModal: () => void;
	data: CriarObrigacaoPayload;
	empresaId: string | number;
	onAttachClick?: () => void;
	aprovadoresSelecionados: number[];
	responsaveisSelecionados: number[];
}

const AcoesAnexo: React.FC<AcoesProps> = ({
	getValues,
	usuarioAprovador,
	usuarioResponsavel,
	openAlterarVencimentoModal,
	openConcluirObrigacaoModal,
	openCancelarObrigacaoModal,
	data,
	onAttachClick,
}) => {
	const { colors } = useTheme();
	const aprovacao = getValues('estadoStatusAprovada');
	const statusData = getValues('estadoStatusAlterarData');
	const cancelar = getValues('estadoStatusCancelamento');

	const isObrigacaoConcluida = data.obrigacaoConcluida || aprovacao === 1;
	const anexosLabel = usuarioResponsavel && !isObrigacaoConcluida ? 'Adicionar anexos' : 'Visualizar anexos';

	return (
		<Box className={styles.acoesContainer}>
			<Typography.Body size='large' strong>
				Ações
			</Typography.Body>

			<Grid container spacing={2} className={styles.espacamentoTop}>
				<Grid item>
					<Button
						className={styles.concluir}
						variant='default'
						type='filled-tonal'
						icon={<Icons.CheckCircle style={{ fontSize: '24px' }} />}
						onClick={(ev) => {
							ev.preventDefault();
							openConcluirObrigacaoModal();
						}}
						disabled={
							(!usuarioResponsavel && !(usuarioAprovador && aprovacao > 0 && aprovacao === 1)) ||
							obrigacaoEstaConcluida(aprovacao)
						}
					>
						{!usuarioAprovador || (usuarioAprovador && usuarioResponsavel)
							? 'Concluir obrigação'
							: 'Analisar a conclusão da obrigação'}
					</Button>
				</Grid>

				<Grid item>
					<Button
						className={styles.alterarVencimento}
						variant='default'
						type='filled-tonal'
						icon={<EditCalendarOutlinedIcon />}
						onClick={(ev) => {
							ev.preventDefault();
							openAlterarVencimentoModal();
						}}
						disabled={
							(!usuarioResponsavel && !(usuarioAprovador && statusData > 0 && statusData === 1)) ||
							obrigacaoEstaConcluida(aprovacao)
						}
					>
						{!usuarioAprovador || (usuarioAprovador && usuarioResponsavel)
							? 'Alterar data de vencimento'
							: 'Analisar alteração de vencimento'}
					</Button>
				</Grid>
				<Grid item>
					<Button
						className={styles.cancelar}
						variant='error'
						type='filled-tonal'
						icon={<CancelOutlinedIcon />}
						onClick={(ev) => {
							openCancelarObrigacaoModal();
							ev.preventDefault();
						}}
						disabled={
							(!usuarioResponsavel && !(usuarioAprovador && cancelar > 0 && cancelar === 1)) ||
							obrigacaoEstaConcluida(aprovacao)
						}
					>
						{!usuarioAprovador || (usuarioAprovador && usuarioResponsavel)
							? 'Cancelar obrigação'
							: 'Analisar cancelamento'}
					</Button>
				</Grid>
				<Grid item>
					<Button
						className={styles.anexos}
						style={{ backgroundColor: colors.roxoContainer }}
						type='filled-tonal'
						icon={<Icons.AttachFile style={{ fontSize: '24px' }} />}
						onClick={onAttachClick}
					>
						{anexosLabel}
					</Button>
				</Grid>
			</Grid>

			<Space direction='vertical' style={{ marginTop: 16 }}>
				{data.justificativaNaoAprovacao && data.estadoStatusAprovada === 3 && (
					<Flex gap={8} align='center'>
						<Icons.Error style={{ color: '#ff4d4f' }} />
						<Typography.Body> Conclusão não aprovada: {data.justificativaNaoAprovacao}</Typography.Body>
					</Flex>
				)}
				{data.justificativaNaoAprovacaoDataAlterada && data.estadoStatusAlterarData === 3 && (
					<Flex gap={8} align='center'>
						<Icons.Error style={{ color: '#ff4d4f' }} />
						<Typography.Body>
							Alteração de prazo não aprovada: {data.justificativaNaoAprovacaoDataAlterada}
						</Typography.Body>
					</Flex>
				)}

				{data.justificativaNaoAprovarCancelamento && data.estadoStatusCancelamento === 3 && (
					<Flex gap={8} align='center'>
						<Icons.Error style={{ color: '#ff4d4f' }} />
						<Typography.Body>Cancelamento não aprovado: {data.justificativaNaoAprovarCancelamento}</Typography.Body>
					</Flex>
				)}
			</Space>
		</Box>
	);
};

export default AcoesAnexo;
