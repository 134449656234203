/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import { Col, Flex, Row, Typography, Button } from '@jcm/design-system';
import PieChartComp from './GraficoTorta';
import CustomDateCalendar from './Calendar/CustomDateCalendar/CustomDateCalendar';
import CartaoObrigacao from './CartaoObrigacao';
import BarraFerramentasObrigacoes from './BarraFerramentasObrigacoes';
import estilo from './index.module.scss';
import { TObrigacao } from '../../ts/types/Obrigacoes';
import ObrigacaoService from '../../services/Obrigacoes/Obrigacoes.service';
import TotalObrigacoesCard from './TotalObrigacoesCard/TotalObrigacoesCard';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useGovernancaContext } from '../../../../../context/GovernancaContext';
import { Pagination } from '@mui/material';
import { TApiResponse } from '../../services/helpers/ApiResponse';
import { TEstatisticasObrigacoes } from '../../ts/types/EstatisticasObrigacoes';
import EnumSituacaoAcaoObrigacao from '../../ts/enums/SituacaoAcaoObrigacaoEnum';
import Lottie from 'lottie-react';
import LoadingListaObrigacoes from '../../../../../assets/loadings/CarregandoListaObrigacoes.json';
import ObrigacoesTabMenu from './ObrigacoesTabMenu';
import { listarIntervaloAnoAtual } from '../../utils/listarIntervaloAnoAtras';
import usePermissao from '../../hooks';
import { Permissoes } from '../../ts/enums/PermissoesEnum';
import FiltrosObrigacoes from './FiltrosObrigacoes';
import { useForm } from 'react-hook-form';

interface FiltrosObrigacoesFormValues {
	origem: string | number;
	responsavel: string | number;
	aprovador: string | number;
	situacao: string;
	tipo: string;
	descricao: string;
}
import { Layout, Tooltip } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import OperacoesEmMassa from '../OperacoesEmMassa';

const { Sider } = Layout;
export default function Obrigacoes() {
	const { empresaAtiva } = useGovernancaContext();
	const [activeTabKey, setActiveTabKey] = useState<string>('todasObrigacoes');
	const [loading, setLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(10);
	const [totalPages, setTotalPages] = useState(1);
	const [filtroSelecionado, setFiltroSelecionado] = useState<string>('todasObrigacoes');
	const [obrigacoesQuery, setObrigacoesQuery] = useState<TObrigacao[]>([]);
	const [todasObrigacoesQuery, setTodasObrigacoesQuery] = useState<TObrigacao[]>([]);
	const [tabMenuFiltro, setTabMenuFiltro] = useState<{ name: string; value: number }[]>([]);
	const [graficoData, setGraficoData] = useState<{ name: string; value: number }[]>([]);
	const [dadosCard, setDadosCard] = useState({ total: 0, legais: 0, proprias: 0 });
	const [simplifiedData, setSimplifiedData] = useState<
		{ id: number; dataCriacao: string; dataVencimento: string; dataConclusao: string | null; concluida: boolean }[]
	>([]);
	const [selectedDate, setSelectedDate] = useState<string | null>(null);
	const [showCompleted, setShowCompleted] = useState(true);
	const [rangeDateWorking, setRangeDateWorking] = useState(false);
	const [rangeDate, setRangeDate] = useState<{ startDate: string; endDate: string }>();
	const [collapsed, setCollapsed] = useState(false);
	const [funcionarios, setFuncionarios] = useState<any[]>([]);
	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};
	const _obrigacoesService = new ObrigacaoService();

	const temPermissaoAdministrador = usePermissao([Permissoes.AdministradorEmpresa, Permissoes.AdministradorSistema]);
	const { control } = useForm<FiltrosObrigacoesFormValues>();

	useEffect(() => {
		if (empresaAtiva) {
			_obrigacoesService.getAllUsuarios(empresaAtiva).then((response) => {
				setFuncionarios(response);
			});
		}
	}, [empresaAtiva]);

	const aplicarFiltros = (filtrosSelecionados: Record<string, string | number>) => {
		let filteredObrigacoes = todasObrigacoesQuery;

		if (filtrosSelecionados.origem) {
			filteredObrigacoes = filteredObrigacoes.filter(
				(obrigacao: TObrigacao) => obrigacao.origemId === filtrosSelecionados.origem,
			);
		}

		if (filtrosSelecionados.responsavel && filtrosSelecionados.responsavel !== '') {
			filteredObrigacoes = filteredObrigacoes.filter((obrigacao: TObrigacao) =>
				obrigacao.responsaveis.some(
					(responsavel) => responsavel.funcionario?.funcionarioId === Number(filtrosSelecionados.responsavel),
				),
			);
		}

		if (filtrosSelecionados.aprovador && filtrosSelecionados.aprovador !== '') {
			filteredObrigacoes = filteredObrigacoes.filter((obrigacao: TObrigacao) =>
				obrigacao.aprovadores.some(
					(aprovador) => aprovador.funcionario?.funcionarioId === Number(filtrosSelecionados.aprovador),
				),
			);
		}

		if (filtrosSelecionados.situacao) {
			if (filtrosSelecionados.situacao === 'concluida') {
				filteredObrigacoes = filteredObrigacoes.filter(
					(obrigacao: TObrigacao) =>
						obrigacao.situacao === EnumSituacaoAcaoObrigacao.ConcluidaNoPrazo ||
						obrigacao.situacao === EnumSituacaoAcaoObrigacao.ConcluidaComAtraso,
				);
			} else if (filtrosSelecionados.situacao === 'emAndamento') {
				filteredObrigacoes = filteredObrigacoes.filter(
					(obrigacao: TObrigacao) => obrigacao.situacao === EnumSituacaoAcaoObrigacao.NoPrazo,
				);
			}
		}

		if (filtrosSelecionados.tipo) {
			filteredObrigacoes = filteredObrigacoes.filter((obrigacao: TObrigacao) =>
				filtrosSelecionados.tipo === 'legal' ? obrigacao.ehLegal : !obrigacao.ehLegal,
			);
		}

		if (typeof filtrosSelecionados.nome === 'string' && filtrosSelecionados.nome.trim() !== '') {
			const nomeFiltro = filtrosSelecionados.nome.toLowerCase();
			filteredObrigacoes = filteredObrigacoes.filter((obrigacao: TObrigacao) =>
				obrigacao.nome.toLowerCase().includes(nomeFiltro),
			);
		}

		setObrigacoesQuery(filteredObrigacoes);
	};

	useEffect(() => {
		getOperacoesEmMassa();
		if (temPermissaoAdministrador) {
			setShowCompleted(false);
		}
	}, [temPermissaoAdministrador]);

	useEffect(() => {
		initializeComponent();
	}, [empresaAtiva, showCompleted]);

	useEffect(() => {
		handleFiltragensListagem(todasObrigacoesQuery);
	}, [currentPage, selectedDate, filtroSelecionado, rangeDateWorking]);

	const initializeComponent = () => {
		setLoading(true);

		if (empresaAtiva !== undefined) {
			fetchObrigacoesData();
		}
	};

	const fetchObrigacoesData = async () => {
		try {
			const datas = listarIntervaloAnoAtual();
			const [obrigacoesResponse, estatisticasResponse] = await fetchObrigacoesAndEstatisticas(datas);
			setTodasObrigacoesQuery(obrigacoesResponse.data.result);
			updatePagination(obrigacoesResponse.data.result);
			setGraficoData(processarDadosParaGrafico(estatisticasResponse.data.result));
			setTabMenuFiltro(calcularTabMenuFiltro(obrigacoesResponse.data.result));
			setDadosCard(capturarDadosParaCard(obrigacoesResponse.data.result));
			setSimplifiedData(extrairDadosSimplificados(obrigacoesResponse.data.result));
		} catch (error) {
			console.error('Erro ao buscar obrigações:', error);
		} finally {
			setLoading(false);
		}
		
	};

	const fetchObrigacoesAndEstatisticas = (datas: {
		dataInicio: string;
		dataFim: string;
	}): Promise<[TApiResponse<TObrigacao[]>, TApiResponse<TEstatisticasObrigacoes>]> => {
		if (showCompleted) {
			return Promise.all([
				_obrigacoesService.getMinhasObrigacoes(empresaAtiva as string | number, {
					dataInicio: datas.dataInicio,
					dataFim: datas.dataFim,
				}),
				_obrigacoesService.getMinhasEstatisticasObrigacoes(empresaAtiva as string | number, {
					dataInicio: datas.dataInicio,
					dataFim: datas.dataFim,
				}),
			]);
		}
		return Promise.all([
			_obrigacoesService.getAllObrigacoesEssenciais(empresaAtiva as string | number, {
				dataInicio: datas.dataInicio,
				dataFim: datas.dataFim,
			}),
			_obrigacoesService.getAllEstatisticaObrigacoesEmpresa(empresaAtiva as string | number, {
				dataInicio: datas.dataInicio,
				dataFim: datas.dataFim,
			}),
		]);
	};

	const updatePagination = (obrigacoes: TObrigacao[]) => {
		const totalItems = obrigacoes.length;
		setTotalPages(Math.ceil(totalItems / pageSize));
		const paginatedData = obrigacoes.slice((currentPage - 1) * pageSize, currentPage * pageSize);
		setObrigacoesQuery(paginatedData);
	};

	const processarDadosParaGrafico = (estatisticas: TEstatisticasObrigacoes) => [
		{ name: 'Concluídas no prazo', value: estatisticas.concluidasNoPrazo || 0 },
		{ name: 'Concluídas com atraso', value: estatisticas.concluidasComAtraso || 0 },
		{ name: 'Vencidas e não concluídas', value: estatisticas.atrasadas || 0 },
		{ name: 'Não concluídas e no Prazo', value: estatisticas.noPrazo || 0 },
	];

	const calcularTabMenuFiltro = (obrigacoes: TObrigacao[]) => {
		const aguardandoAprovacao = obrigacoes.filter((obrigacao) => {
			const hasCancelamentoAprovado = obrigacao.pedidoCancelarObrigacao.some(
				(pedido) => pedido.estadoStatusAprovada === 1,
			);
			const hasAlteracaoAprovada = obrigacao?.pedidoAlteracaoDataFinal.some(
				(pedido) => pedido.estadoStatusAprovada === 1,
			);
			const hasObrigacaoAprovada = obrigacao.estadoStatusAprovada === 1;

			return hasCancelamentoAprovado || hasAlteracaoAprovada || hasObrigacaoAprovada;
		});

		return [
			{ name: 'Aguardando Aprovação', value: aguardandoAprovacao.length },
			{ name: 'Todas Obrigações', value: obrigacoes.length },
		];
	};

	const capturarDadosParaCard = (obrigacoes: TObrigacao[]) => {
		let total = 0,
			legais = 0,
			proprias = 0;
		obrigacoes.forEach((obrigacao) => {
			total++;
			if (obrigacao.ehLegal) legais++;
			else proprias++;
		});
		return { total, legais, proprias };
	};

	const extrairDadosSimplificados = (obrigacoes: TObrigacao[]) =>
		obrigacoes.map((obrigacao) => ({
			id: obrigacao.id,
			dataCriacao: obrigacao.dataCriacao,
			dataVencimento: obrigacao.dataVencimento,
			dataConclusao: obrigacao.dataConclusao,
			concluida: obrigacao.concluida,
		}));

	const handleFiltragensListagem = (obrigacoes: TObrigacao[]) => {
		let filteredObrigacoes = obrigacoes;

		if (selectedDate) {
			setRangeDate(undefined);
			setRangeDateWorking(false);
		}

		if (filtroSelecionado === 'todasObrigacoes') {
			if (selectedDate && !rangeDateWorking) {
				filteredObrigacoes = todasObrigacoesQuery.filter(
					(obrigacao) => formatDate(obrigacao.dataVencimento) === selectedDate,
				);
			}

			if (rangeDateWorking && rangeDate) {
				filteredObrigacoes = todasObrigacoesQuery.filter((obrigacao) => {
					const dataVencimento = dayjs(obrigacao.dataVencimento).format('YYYY-MM-DD');
					return dataVencimento >= rangeDate?.startDate && dataVencimento <= rangeDate?.endDate;
				});
			}

			updatePagination(filteredObrigacoes);
			setGraficoData(processarDadosParaGraficoPreSelecionado(filteredObrigacoes));
			setDadosCard(capturarDadosParaCard(filteredObrigacoes));
		}

		if (filtroSelecionado === 'obrigacoesVencidas') {
			const now = new Date();
			const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());

			filteredObrigacoes = todasObrigacoesQuery.filter((obrigacao) => {
				const dataVencimento = new Date(obrigacao.dataVencimento);

				return (
					dataVencimento < startOfToday &&
					!obrigacao.concluida &&
					(!selectedDate || formatDate(obrigacao.dataVencimento) === selectedDate)
				);
			});

			if (rangeDateWorking && rangeDate) {
				filteredObrigacoes = todasObrigacoesQuery.filter((obrigacao) => {
					const dataVencimento = dayjs(obrigacao.dataVencimento).format('YYYY-MM-DD');
					const dataVencimentoAgora = new Date(obrigacao.dataVencimento);

					return (
						dataVencimentoAgora < startOfToday &&
						!obrigacao.concluida &&
						dataVencimento >= rangeDate?.startDate &&
						dataVencimento <= rangeDate?.endDate
					);
				});
			}

			updatePagination(filteredObrigacoes);
		}

		if (filtroSelecionado === 'obrigacoesAguardandoAprovacoes') {
			const obrigacoesAguardandoEstado = selectedDate ? obrigacoesQuery : todasObrigacoesQuery;

			filteredObrigacoes = obrigacoesAguardandoEstado.filter((obrigacao) => {
				const hasCancelamentoAprovado = obrigacao.pedidoCancelarObrigacao.some(
					(pedido) => pedido.estadoStatusAprovada === 1,
				);
				const hasAlteracaoAprovada = obrigacao?.pedidoAlteracaoDataFinal.some(
					(pedido) => pedido.estadoStatusAprovada === 1,
				);
				const hasObrigacaoAprovada = obrigacao.estadoStatusAprovada === 1;

				return (
					hasCancelamentoAprovado ||
					hasAlteracaoAprovada ||
					(hasObrigacaoAprovada && (!selectedDate || formatDate(obrigacao.dataVencimento) === selectedDate))
				);
			});

			updatePagination(filteredObrigacoes);
		}
	};

	const handleDateChange = (date: string | null) => {
		setActiveTabKey('todasObrigacoes');
		setFiltroSelecionado('todasObrigacoes');
		setSelectedDate(date);
		setCurrentPage(1);
	};

	const formatDate = (dateString: string) => dayjs(dateString).format('YYYY-MM-DD');

	const handlePageChange = (_: unknown, page: number) => setCurrentPage(page);

	const handleTabChange = (tabKey: string) => {
		setActiveTabKey(tabKey);
		setFiltroSelecionado(tabKey);
		setCurrentPage(1);
	};

	const processarDadosParaGraficoPreSelecionado = (obrigacoes: TObrigacao[]) => {
		let concluidasNoPrazo = 0;
		let concluidasComAtraso = 0;
		let vencidasNaoConcluidas = 0;
		let naoConcluidasNoPrazo = 0;
		let aguardandoAprovadores = 0;

		const aguardandoAprovacao = obrigacoes.filter((obrigacao) => {
			const hasCancelamentoAprovado =
				obrigacao.pedidoCancelarObrigacao?.some((pedido) => pedido.estadoStatusAprovada === 1) || false;

			const hasAlteracaoAprovada =
				obrigacao.pedidoAlteracaoDataFinal?.some((pedido) => pedido.estadoStatusAprovada === 1) || false;

			const hasObrigacaoAprovada = obrigacao.estadoStatusAprovada === 1;

			return hasCancelamentoAprovado || hasAlteracaoAprovada || hasObrigacaoAprovada;
		});

		aguardandoAprovadores = aguardandoAprovacao.length;
		setTabMenuFiltro([
			{ name: 'Aguardando Aprovação', value: aguardandoAprovadores },
			{ name: 'Todas Obrigações', value: obrigacoes.length },
		]);

		obrigacoes.forEach((obrigacao) => {
			const { situacao } = obrigacao;

			switch (situacao) {
				case EnumSituacaoAcaoObrigacao.ConcluidaNoPrazo:
					concluidasNoPrazo++;
					break;
				case EnumSituacaoAcaoObrigacao.ConcluidaComAtraso:
					concluidasComAtraso++;
					break;
				case EnumSituacaoAcaoObrigacao.Atrasada:
					vencidasNaoConcluidas++;
					break;
				case EnumSituacaoAcaoObrigacao.NoPrazo:
					naoConcluidasNoPrazo++;
					break;
				default:
					break;
			}
		});

		return [
			{ name: 'Concluídas no prazo', value: concluidasNoPrazo },
			{ name: 'Concluídas com atraso', value: concluidasComAtraso },
			{ name: 'Vencidas e não concluídas', value: vencidasNaoConcluidas },
			{ name: 'Não concluídas e no Prazo', value: naoConcluidasNoPrazo },
		];
	};

	const handleDateRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
		if (dates && dates[0] && dates[1]) {
			setSelectedDate(null);
			setRangeDateWorking(true);
			const [startDate, endDate] = dates.map((date) => date!.format('YYYY-MM-DD'));
			setRangeDate({ startDate, endDate });

			const filteredObrigacoes = todasObrigacoesQuery.filter((obrigacao) => {
				const dataVencimento = dayjs(obrigacao.dataVencimento).format('YYYY-MM-DD');
				return dataVencimento >= startDate && dataVencimento <= endDate;
			});

			updatePagination(filteredObrigacoes);
			setGraficoData(processarDadosParaGraficoPreSelecionado(filteredObrigacoes));
			setDadosCard(capturarDadosParaCard(filteredObrigacoes));
		} else {
			setRangeDateWorking(false);
		}
	};

	const handleSwitchChange = (checked: boolean) => {
		setShowCompleted(checked);
		setLoading(true);
	};

	const atualizarDados = () => fetchObrigacoesData();

	const handleObrigacaoChange = (obrigacaoAtualizada: TObrigacao) => {
		setTodasObrigacoesQuery((prevObrigacoes) => {
			const index = prevObrigacoes.findIndex((obrigacao) => obrigacao.id === obrigacaoAtualizada.id);
			if (index !== -1) {
				const novasObrigacoes = [...prevObrigacoes];
				novasObrigacoes[index] = obrigacaoAtualizada;
				return novasObrigacoes;
			} else {
				atualizarDados();
				return prevObrigacoes;
			}
		});

		setObrigacoesQuery((prevObrigacoes) => {
			const index = prevObrigacoes.findIndex((obrigacao) => obrigacao.id === obrigacaoAtualizada.id);
			if (index !== -1) {
				const novasObrigacoes = [...prevObrigacoes];
				novasObrigacoes[index] = obrigacaoAtualizada;
				return novasObrigacoes;
			} else {
				atualizarDados();
				return prevObrigacoes;
			}
		});
	};

	const getOperacoesEmMassa = () => {
		return (
			<OperacoesEmMassa
				empresaId={empresaAtiva || ''}
				onObrigacaoChange={handleObrigacaoChange}
				obrigacoes={obrigacoesQuery}
				onAtualizar={atualizarDados}
			/>
		);
	};

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Row gutter={[40, 40]} className={estilo.obrigacoesPagina}>
				<Col
					className={estilo.obrigacaoCol}
					style={{ maxWidth: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 300px)' }}
				>
					<Flex vertical gap={13}>
						<BarraFerramentasObrigacoes
							onDateRangeChange={handleDateRangeChange}
							empresaId={empresaAtiva || ''}
							onObrigacaoChange={handleObrigacaoChange}
						/>
						<ObrigacoesTabMenu
							aguardandoAprovacao={tabMenuFiltro.find((d) => d.name === 'Aguardando Aprovação')?.value || 0}
							concluidasNoPrazo={graficoData.find((d) => d.name === 'Concluídas no prazo')?.value || 0}
							concluidasComAtraso={graficoData.find((d) => d.name === 'Concluídas com atraso')?.value || 0}
							vencidasNaoConcluidas={graficoData.find((d) => d.name === 'Vencidas e não concluídas')?.value || 0}
							naoConcluidasNoPrazo={graficoData.find((d) => d.name === 'Não concluídas e no Prazo')?.value || 0}
							todasObrigacoes={tabMenuFiltro.find((d) => d.name === 'Todas Obrigações')?.value || 0}
							onTabChange={handleTabChange}
							onSwitchChange={handleSwitchChange}
							activeTabKey={activeTabKey}
							showCompleted={showCompleted}
							loading={loading}
						/>
					</Flex>
					<FiltrosObrigacoes
						aoAplicarFiltros={aplicarFiltros}
						listaResponsaveis={funcionarios}
						listaAprovadores={funcionarios}
						control={control}
					/>

					{loading ? (
						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
							<Lottie loop animationData={LoadingListaObrigacoes} style={{ width: '50%', height: '50%' }} />
						</div>
					) : (
						<>
							{obrigacoesQuery.length > 0 ? (
								<>
									{obrigacoesQuery.map((obrigacao) => (
										<CartaoObrigacao
											onAtualizar={atualizarDados}
											obrigacao={obrigacao}
											key={obrigacao.id}
											empresaId={empresaAtiva || ''}
											onObrigacaoChange={handleObrigacaoChange}
										/>
									))}
									<Flex justify='center'>
										<Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
									</Flex>
								</>
							) : (
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
									<Flex justify='center'>
										<Typography.Headline>Não há Obrigações a serem listadas.</Typography.Headline>
									</Flex>
								</div>
							)}
						</>
					)}
				</Col>

				<Col>
					<Sider
						width={300}
						collapsible
						collapsed={collapsed}
						collapsedWidth={0}
						trigger={null}
						style={{
							position: 'fixed',
							right: 0,
							top: 0,
							height: 'calc(100vh - 65px)',
							zIndex: 1000,
							marginTop: '65px',
						}}
					>
						<Col id='graficoContainer' className={estilo.conteudoGraficoContainer} style={{ overflowY: 'auto' }}>
							<Flex vertical>
								<Row>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<CustomDateCalendar
												loading={loading}
												obrigacoes={simplifiedData}
												onDateChange={handleDateChange}
											/>
										</Col>
									</LocalizationProvider>
								</Row>
								<Row>
									<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className='pieChart-div'>
										<ResponsiveContainer width='100%' height='100%'>
											<PieChartComp data={graficoData} />
										</ResponsiveContainer>
									</Col>
								</Row>
								<Row>
									<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
										<TotalObrigacoesCard
											total={dadosCard.total}
											legais={dadosCard.legais}
											proprias={dadosCard.proprias}
										/>
									</Col>
								</Row>
							</Flex>
							<Tooltip placement='left' title={'Ocultar menu de calendário e gráfico'}>
								<Button
									style={{
										padding: '16px 4px',
										borderRadius: '0px 8px 8px 0px',
										margin: '0px 0px 20px -20px',
									}}
									onClick={toggleCollapsed}
								>
									<Flex>
										<Typography.Body
											size='small'
											style={{
												fontSize: '12px',
												marginRight: '.5rem',
											}}
										>
											Ocultar
										</Typography.Body>
										<MenuUnfoldOutlined onClick={toggleCollapsed} style={{ fontSize: '16px', cursor: 'pointer' }} />
									</Flex>
								</Button>
							</Tooltip>
						</Col>
					</Sider>
					<div style={{ position: 'fixed', right: '0px', top: 'calc(50% - 65px)' }}>
						<Tooltip placement='left' title={'Exibir menu de calendário e gráfico'}>
							<Button
								style={{
									width: '40px',
									padding: '16px 4px',
									borderRadius: '8px 0px 0px 8px',
								}}
								onClick={toggleCollapsed}
							>
								<Flex style={{ flexDirection: 'column' }}>
									<MenuFoldOutlined />
									<Typography.Body
										size='small'
										style={{
											writingMode: 'vertical-rl',
											transform: 'rotate(180deg)',
											fontSize: '12px',
											marginTop: '.5rem',
										}}
									>
										Calendário e Gráfico
									</Typography.Body>
								</Flex>
							</Button>
						</Tooltip>
					</div>
				</Col>
			</Row>
		</Layout>
	);
}
