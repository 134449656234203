import { Col, Row, Flex, Typography, Icons, useTheme, Switch, Button } from '@jcm/design-system';
import UserAvatar from '../../../../../../../shared/components/userProfileIcon/userProfileIcon';
import TruncatedText from '../../../../../../../shared/components/truncatedTextTooltip/TruncatedTextTooltip';
import ObrigacoesService from '../../../../services/Obrigacoes/Obrigacoes.service';
import { useGovernancaContext } from '../../../../../../../context/GovernancaContext';
import { useState, useEffect } from 'react';
import CustomAlert from '../../../../../../../shared/components/customAlert/customAlert';
import { PerfilFuncionario } from '../../../../ts/enums/FuncaoEnum';
import PopConfirmButton from '../../../../../../../shared/components/popConfirmButton/popConfirmButton';

interface UsuarioProps {
	nome: string;
	email: string;
	empresa: string;
	perfil: string;
	ativo: boolean;
	funcionarioId: string | number;
	login: string;
	onEdit: () => void;
	onStatusChange: () => void;
}

export default function Usuarios({
	nome,
	email,
	empresa,
	perfil,
	ativo: inicialAtivo,
	funcionarioId,
	login,
	onEdit,
	onStatusChange,
}: UsuarioProps): JSX.Element {
	const { colors } = useTheme();
	const { usuario: usuarioLogado, empresaAtiva } = useGovernancaContext();
	const _usuariosService = new ObrigacoesService();

	const [ativo, setAtivo] = useState(inicialAtivo);
	const [alert, setAlert] = useState<{ type: 'success' | 'error'; content: string; title?: string } | null>(null);

	const isComum = usuarioLogado?.funcao === PerfilFuncionario.Comum;

	useEffect(() => {
		setAtivo(inicialAtivo);
	}, [inicialAtivo]);

	async function ativarOuDesativarUsuario(novoAtivo: boolean) {
		try {
			if (empresaAtiva !== null) {
				await _usuariosService.atualizarUsuario(empresaAtiva, funcionarioId, {
					ativo: novoAtivo,
					email,
					login,
					nome,
				});

				setAtivo(novoAtivo);
				setAlert({
					type: 'success',
					content: `Usuário ${novoAtivo ? 'ativado' : 'desativado'} com sucesso.`,
					title: 'Sucesso!',
				});

				onStatusChange();
			}
		} catch (error) {
			console.error('Erro ao atualizar o status do usuário', error);
			setAlert({
				type: 'error',
				content: 'Erro ao atualizar o status do usuário. Tente novamente.',
				title: 'Erro',
			});
		}
	}

	useEffect(() => {
		if (alert && alert.type === 'success') {
			const timer = setTimeout(() => {
				setAlert(null);
			}, 3000);
			return () => clearTimeout(timer);
		}
	}, [alert]);

	return (
		<>
			<Flex
				style={{
					padding: '.5rem 1rem',
					borderBottom: '1px solid #D9D9D9',
					width: '100%',
					alignItems: 'center',
					gap: '1rem',
				}}
			>
				<Flex style={{ gap: '1rem', flex: '1', minWidth: 0 }}>
					<Col
						id='campoUsuario'
						style={{
							flexBasis: '600px',
							flexGrow: 1,
							flexShrink: 1,
							maxWidth: '600px',
							display: 'flex',
							alignItems: 'center',
							gap: '.5rem',
							minWidth: 0,
						}}
					>
						<UserAvatar nome={nome} ativo={ativo} />
						<div style={{ flexGrow: 1, minWidth: 0, overflow: 'hidden' }}>
							<Typography.Body size='medium' variant='default'>
								<TruncatedText text={nome} />
							</Typography.Body>
							<div>
								<Typography.Body size='small' variant='default' id='email'>
									<TruncatedText text={email} />
								</Typography.Body>
							</div>
						</div>
					</Col>
					<Col
						id='empresa'
						style={{
							flexBasis: '600px',
							flexGrow: 1,
							flexShrink: 1,
							minWidth: 0,
							maxWidth: '600px',
							overflow: 'hidden',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<div style={{ flexGrow: 1, minWidth: 0, overflow: 'hidden' }}>
							<Typography.Label style={{ color: colors.onSurfaceVariant }}>Empresa</Typography.Label>
							<div>
								<Typography.Body variant='default'>
									<TruncatedText text={empresa} />
								</Typography.Body>
							</div>
						</div>
					</Col>
					<Col
						id='perfilAcesso'
						style={{
							flexBasis: '200px',
							flexGrow: 0,
							flexShrink: 0,
							minWidth: '100px',
							maxWidth: '200px',
						}}
					>
						<div>
							<Typography.Label>Perfil de acesso</Typography.Label>
							<div>
								<Typography.Body variant='default'>{perfil}</Typography.Body>
							</div>
						</div>
					</Col>
				</Flex>
				<Row id='acoes'>
					<Flex
						style={{
							gap: '1rem',
							alignItems: 'center',
							width: '130px',
							justifyContent: 'end',
						}}
					>
						<Flex style={{ gap: '.25rem', alignItems: 'center' }}>
							<Typography.Label>{ativo ? 'Ativo' : 'Inativo'}</Typography.Label>
							<PopConfirmButton
								title={`Você tem certeza que deseja ${ativo ? 'desativar' : 'ativar'} este usuário?`}
								onConfirm={() => {
									ativarOuDesativarUsuario(!ativo);
								}}
								okText='Sim'
								cancelText='Não'
								buttonText={
									<Switch id='Switch' checked={ativo} size='small' style={{ width: '20px' }} disabled={isComum} />
								}
							/>
						</Flex>

						<Col>
							<Button
								circle
								icon={<Icons.Edit className='userEdit' style={{ fontSize: '20px', cursor: 'pointer' }} />}
								type='text'
								variant='default'
								onClick={onEdit}
							/>
						</Col>
					</Flex>
				</Row>
			</Flex>

			{alert && <CustomAlert type={alert.type} content={alert.content} title={alert.title} />}
		</>
	);
}
