import React, { useState, useEffect } from 'react';
import { Card, Row } from '@jcm/design-system';
import style from './index.module.scss';
import Cabecalho from './Cabecalho';
import Conteudo from './Conteudo';
import Responsabilidades from './Responsabilidades';
import Acoes from './Acoes';
import ObrigacaoService from '../../../services/Obrigacoes/Obrigacoes.service';
import dayjs from 'dayjs';
import { TObrigacao } from '../../../ts/types/Obrigacoes';
import Anexos from '../FormObrigacoes/Anexos/Anexos';
import { CriarAnexoPayload } from '../../../ts/types/Obrigacoes';
import { transformObrigacaoToPayload } from '../../../ts/types/serializers';
import CustomAlert from '../../../../../../shared/components/customAlert/customAlert';

interface CartaoObrigacaoProps {
	obrigacao: TObrigacao;
	parentCodigo?: string;
	empresaId: string | number;
	onAtualizar: () => void;
	onObrigacaoChange: (obrigacaoAtualizada: TObrigacao) => void;
}

const CartaoObrigacao: React.FC<CartaoObrigacaoProps> = ({
	obrigacao,
	parentCodigo,
	empresaId,
	onAtualizar,
	onObrigacaoChange,
}) => {
	const {
		nome,
		codigo,
		aprovadores,
		responsaveis,
		dataVencimento,
		children,
		ehLegal,
		prorrogada,
		concluida,
		anexosAcaoObrigacao,
	} = obrigacao;
	const [showSubCards, setShowSubCards] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [anexoData, setAnexoData] = useState<CriarAnexoPayload>({ arquivo: null, descricao: '' });
	const [hasAnexos, setHasAnexos] = useState(!!(anexosAcaoObrigacao && anexosAcaoObrigacao.length > 0));

	const obrigacaoPayload = transformObrigacaoToPayload(obrigacao);
	const { estadoStatusAlterarData, estadoStatusCancelamento, estadoStatusAprovada } = obrigacaoPayload;

	const formattedDate = dataVencimento ? dayjs(dataVencimento).format('DD/MM/YYYY') : 'Data não disponível';

	useEffect(() => {
		setHasAnexos(!!(anexosAcaoObrigacao && anexosAcaoObrigacao.length > 0));
	}, [anexosAcaoObrigacao?.length]);

	const handleVerEtapasClick = () => {
		setShowSubCards(!showSubCards);
	};

	const fullCodigo = parentCodigo ? `${parentCodigo}.${codigo}` : codigo.toString();

	const isDisabled = !children || children.length === 0;

	const _obrigacoesService = new ObrigacaoService();

	const handleOpenModal = () => {
		setIsModalVisible(true);
	};

	const handleCloseModal = () => {
		setIsModalVisible(false);
	};

	const [alert, setAlert] = useState<{ type: 'success' | 'error'; content: string; title?: string } | null>(null);
	useEffect(() => {
		if (alert) {
			const timer = setTimeout(() => setAlert(null), 1000); // Limpa o alerta após 3 segundos
			return () => clearTimeout(timer); // Limpa o timer quando o componente é desmontado ou o alerta muda
		}
	}, [alert]);

	const handleAnexoSubmit = async () => {
		try {
			if (anexoData.arquivo) {
				setAlert({ type: 'success', content: 'Anexo criado com sucesso.', title: 'Sucesso!' });
				await _obrigacoesService
					.criarAnexoObrigacao(empresaId, obrigacao.id, anexoData.arquivo, anexoData.descricao, 'usuarioAtivo')
					.then((res) => {
						if (res.isSuccess) {
							onAtualizar();
							setAnexoData({ arquivo: null, descricao: '' });
						}
					});
			} else {
				setAlert({ type: 'error', content: 'Nenhum arquivo foi selecionado.' });
			}
		} catch (error) {
			setAlert({ type: 'error', content: 'Ocorreu um erro ao criar o anexo. Tente novamente mais tarde.' });
		}
	};

	const handleAnexoChange = (field: keyof CriarAnexoPayload, value: unknown) => {
		setAnexoData((prev) => ({ ...prev, [field]: value }));
	};

	return (
		<Card className={style.cartaoObrigacoes} variant='default'>
			<Row gutter={[24, 24]}>
				<Cabecalho
					dataVencimento={formattedDate}
					prorrogada={prorrogada}
					ehLegal={ehLegal}
					estadoStatusAlterarData={estadoStatusAlterarData ?? 0}
					estadoStatusAprovada={estadoStatusAprovada ?? 0}
					estadoStatusCancelamento={estadoStatusCancelamento ?? 0}
				/>
				<Conteudo nome={nome} codigo={fullCodigo} />
				<Responsabilidades
					aprovadores={aprovadores}
					responsaveis={responsaveis}
					disabled={isDisabled}
					ehLegal={ehLegal}
					onAttachClick={handleOpenModal}
					hasAnexos={hasAnexos}
				/>
				<Acoes
					onAttachClick={handleOpenModal}
					onVerEtapasClick={handleVerEtapasClick}
					disabled={isDisabled}
					concluida={concluida}
					obrigacao={obrigacao}
					empresaId={empresaId}
					onObrigacaoChange={onObrigacaoChange}
				/>
			</Row>

			<Anexos
				data={anexoData}
				obrigacao={obrigacao}
				onChange={handleAnexoChange}
				onSave={handleAnexoSubmit}
				onAtualizar={onAtualizar}
				isVisible={isModalVisible}
				onClose={handleCloseModal}
			/>

			{showSubCards &&
				children &&
				children.map((child) => (
					<div key={child.codigo} className={style.subCartaoWrapper}>
						<Card className={style.subCartaoObrigacoes} variant='default'>
							<Row gutter={[24, 24]}>
								<Cabecalho
									dataVencimento={dayjs(child.dataVencimento).format('DD/MM/YYYY')}
									prorrogada={prorrogada}
									ehLegal={ehLegal}
									estadoStatusAlterarData={estadoStatusAlterarData ?? 0}
									estadoStatusAprovada={estadoStatusAprovada ?? 0}
									estadoStatusCancelamento={estadoStatusCancelamento ?? 0}
								/>
								<Conteudo
									nome={child.nome}
									codigo={`${fullCodigo}.${child.codigo}`}
									isSubCard
									className={style.conteudoSubCard}
								/>
								<Responsabilidades
									aprovadores={child.aprovadores}
									responsaveis={child.responsaveis}
									disabled={isDisabled}
									ehLegal={ehLegal}
									onAttachClick={handleOpenModal}
									hasAnexos={!!(child.anexosAcaoObrigacao && child.anexosAcaoObrigacao.length > 0)}
								/>
							</Row>
						</Card>
					</div>
				))}
			{alert && <CustomAlert type={alert.type} content={alert.content} />}
		</Card>
	);
};

export default CartaoObrigacao;
