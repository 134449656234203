import { BrowserRouter } from 'react-router-dom';
import { ThemeContextProvider } from './context/ThemeContext';
import AppRoutes from './routes/AppRoutes';
import './assets/sass/style.scss';

export default function App() {
	return (
		<BrowserRouter>
			<ThemeContextProvider>
				<AppRoutes />
			</ThemeContextProvider>
		</BrowserRouter>
	);
}
//Test azure integration
