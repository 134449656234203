import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Box, Input, Collapse } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import styles from './index.module.scss';
import { Anexo, CriarAnexoPayload, TObrigacao } from '../../../../ts/types/Obrigacoes';
import ListaAnexos from './ListaAnexos/ListaAnexos';
import { Button, TextInput } from '@jcm/design-system';
import ModalContainer from '../../../../components/modalContainer/ModalContainer';
import { useGovernancaContext } from '../../../../../../../context/GovernancaContext';
import ObrigacaoService from '../../../../services/Obrigacoes/Obrigacoes.service';

interface AnexosProps {
	empresaId?: number | string;
	data: CriarAnexoPayload;
	obrigacao: TObrigacao;
	onChange: (field: keyof CriarAnexoPayload, value: unknown) => void;
	onSave: () => Promise<void>;
	onAtualizar: () => void;
	isVisible: boolean;
	onClose: () => void;
}

const Anexos: React.FC<AnexosProps> = ({ obrigacao, onChange, onSave, onAtualizar, isVisible, onClose }) => {
	const { usuario, empresaAtiva } = useGovernancaContext();
	const [anexos, setAnexos] = useState<Anexo[]>([]);
	const [aberto, setAberto] = useState(false);
	const [arquivoSelecionado, setArquivoSelecionado] = useState<string | null>(null);
	const [descricaoAnexo, setDescricaoAnexo] = useState<string>('');
	const [exibirMensagem, setExibirMensagem] = useState(false);
	const [loading, setLoading] = useState(false);
	const inputFileRef = useRef<HTMLInputElement | null>(null);

	const _obrigacaoService = new ObrigacaoService();

	const isResponsavel = obrigacao?.responsaveis?.some(
		(responsavel) => responsavel.funcionario.funcionarioId === usuario?.funcionarioId,
	);

	const carregarAnexosAsync = async () => {
		const novosAnexos = (obrigacao.anexosAcaoObrigacao || []).map((acaoAnexo) => {
			const { anexo } = acaoAnexo || {};
			return {
				id: anexo?.id || 0,
				nomeOriginal: anexo?.nomeOriginal || 'Nome do arquivo não disponível',
				descricao: anexo?.descricao || 'Sem descrição',
				dataCriacao: anexo?.dataCriacao ? new Date(anexo.dataCriacao).toLocaleDateString() : 'Data não disponível',
				funcionarioId: anexo?.funcionarioId || 0,
				contentType: anexo?.contentType || '',
				extensao: anexo?.extensao || '',
				tipoAnexo: anexo?.tipoAnexo || 0,
				funcionario: anexo?.funcionario || undefined,
				nomeFisico: anexo?.nomeFisico || '',
				arquivo: anexo?.arquivo || null,
				idObrigacao: obrigacao.id,
			};
		});

		return novosAnexos;
	};

	const fetchObrigacoes = async () => {
		try {
			if (empresaAtiva) {
				const response = await _obrigacaoService.getAllObrigacoes(empresaAtiva);
				const novasObrigacoes: TObrigacao[] = response.data.result;

				const obrigacaoAtualizada = novasObrigacoes.find((obs: TObrigacao) => obs.id === obrigacao.id);
				if (obrigacaoAtualizada && obrigacaoAtualizada.anexosAcaoObrigacao) {
					const novosAnexos: Anexo[] = obrigacaoAtualizada.anexosAcaoObrigacao.map((acaoAnexo) => {
						const { anexo } = acaoAnexo || {};
						return {
							id: anexo?.id || 0,
							nomeOriginal: anexo?.nomeOriginal || 'Nome do arquivo não disponível',
							descricao: anexo?.descricao || 'Sem descrição',
							dataCriacao: anexo?.dataCriacao
								? new Date(anexo.dataCriacao).toLocaleDateString()
								: 'Data não disponível',
							funcionarioId: anexo?.funcionarioId || 0,
							contentType: anexo?.contentType || '',
							extensao: anexo?.extensao || '',
							tipoAnexo: anexo?.tipoAnexo || 0,
							funcionario: anexo?.funcionario || undefined,
							nomeFisico: anexo?.nomeFisico || '',
							arquivo: anexo?.arquivo || null,
							idObrigacao: obrigacao.id,
						};
					});
					setAnexos(novosAnexos);
				} else {
					setAnexos([]);
				}
			}
		} catch (error) {
			console.error('Erro ao buscar obrigações:', error);
		}
	};

	useEffect(() => {
		const fetchAnexos = async () => {
			if (obrigacao && obrigacao.id) {
				const novosAnexos = await carregarAnexosAsync();
				setAnexos(novosAnexos);
			}
		};

		fetchAnexos();
	}, [obrigacao]);

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length > 0) {
			const arquivo = e.target.files[0];
			setArquivoSelecionado(arquivo.name);
			onChange('arquivo', arquivo);
			setExibirMensagem(false);
		}
	};

	const resetForm = () => {
		setArquivoSelecionado(null);
		setDescricaoAnexo('');
		setExibirMensagem(false);
		onChange('descricao', '');
		onChange('arquivo', null);
	};

	const handleSave = async () => {
		setLoading(true);
		try {
			await onSave();

			await fetchObrigacoes();

			const novosAnexos = await carregarAnexosAsync();

			setAnexos(novosAnexos);
			onAtualizar();
		} catch (error) {
			console.error('Erro ao salvar anexo:', error);
		} finally {
			resetForm();
			setAberto(false);
			setLoading(false);
		}
	};

	const handleGetUserEmpresaId = () => {
		return usuario?.participacaoEmpresa?.[0]?.empresaId || usuario?.empresaSalvaId;
	};

	const handleDeleteAnexo = async (index: number) => {
		const anexoId = anexos[index].id;
		try {
			const empresaId = handleGetUserEmpresaId();
			if (empresaId !== undefined) {
				await _obrigacaoService.excluirAnexoObrigacao(empresaId, obrigacao.id, anexoId);
				setAnexos((prevAnexos) => prevAnexos.filter((_, i) => i !== index));
				await fetchObrigacoes();
			} else {
				console.error('ID da empresa não encontrado.');
			}
		} catch (error) {
			console.error('Erro ao excluir anexo:', error);
		}
	};

	return (
		<ModalContainer
			isVisible={isVisible}
			onClose={() => {
				resetForm();
				onClose();
			}}
			className={styles.modalAnexoContainer}
		>
			<div className={styles.modalAnexoContent}>
				<Grid className={styles.containerAnexo}>
					<Grid container spacing={2} className={styles.containerAnexoHeader}>
						<Grid>
							<Typography variant='h6' className={styles.typographyH6}>
								Anexos
							</Typography>
							{exibirMensagem && (
								<Typography variant='h6' className={styles.typographyParagraph}>
									É obrigatório anexar arquivos de evidência para conclusão
								</Typography>
							)}
						</Grid>

						{isResponsavel && obrigacao.aprovada !== 1 && (
							<Grid className={styles.rightAligned}>
								<Button variant='default' type='filled' className={styles.addButton} onClick={() => setAberto(true)}>
									Adicionar anexo
								</Button>
							</Grid>
						)}
					</Grid>

					<Box className={styles.anexosContainer}>
						{isResponsavel && (
							<Collapse in={aberto} className={styles.uploadContainerArea}>
								<Box
									className={styles.uploadArea}
									onDragOver={(e) => e.preventDefault()}
									onDrop={(e) => {
										e.preventDefault();
										const files = e.dataTransfer.files;
										if (files && files.length > 0) {
											handleFileChange({ target: { files } } as React.ChangeEvent<HTMLInputElement>);
										}
									}}
								>
									<Input
										type='file'
										onChange={handleFileChange}
										className={styles.uploadInput}
										disableUnderline
										style={{ display: 'none' }}
										ref={inputFileRef}
										id={`upload-button-file-${obrigacao.id}`}
									/>
									<Button
										variant='default'
										type='outlined'
										className={styles.uploadButton}
										onClick={() => document.getElementById(`upload-button-file-${obrigacao.id}`)?.click()}
									>
										<div className={styles.uploadContainer}>
											<UploadFileIcon className={styles.uploadIcon} />
											<Typography variant='body1' className={styles.uploadTexto}>
												{arquivoSelecionado || 'Clique ou arraste o arquivo para upload'}
											</Typography>
										</div>
									</Button>
								</Box>

								<TextInput
									type='text'
									variant='filled'
									placeholder='Descrição do anexo'
									className={styles.descricaoInputAnexo}
									value={descricaoAnexo}
									onChange={(e) => {
										setDescricaoAnexo(e.target.value);
										onChange('descricao', e.target.value);
										if (!arquivoSelecionado && e.target.value.trim()) {
											setExibirMensagem(true);
										}
									}}
								/>

								<Box className={styles.buttonsContainer}>
									<Button
										variant='error'
										type='outlined'
										onClick={() => setAberto(false)}
										className={styles.cancelButton}
									>
										Cancelar
									</Button>
									<Button
										variant='default'
										type='filled-tonal'
										loading={loading}
										onClick={handleSave}
										className={styles.saveButton}
										disabled={!arquivoSelecionado}
									>
										Salvar
									</Button>
								</Box>
							</Collapse>
						)}

						<ListaAnexos
							key={JSON.stringify(anexos)}
							anexos={anexos}
							onDelete={handleDeleteAnexo}
							onEdit={(index, descricao) => {
								const novosAnexos = [...anexos];
								novosAnexos[index].descricao = descricao;
								setAnexos(novosAnexos);
							}}
							idObrigacao={obrigacao.id}
							atualizarAnexos={onAtualizar}
							disabled={obrigacao.aprovada === 1 || !isResponsavel}
						/>
					</Box>
				</Grid>
			</div>
		</ModalContainer>
	);
};

export default Anexos;
