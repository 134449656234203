import { TipoAcaoEnum } from '../../ts/enums/TipoAcaoEnum';

export const recuperarTipoAcaoLog = (tipoAcao: TipoAcaoEnum): string => {
	switch (tipoAcao) {
		case TipoAcaoEnum.Insercao:
			return 'Inserção';
		case TipoAcaoEnum.Alteracao:
			return 'Alteração';
		case TipoAcaoEnum.Exclusao:
			return 'Exclusão';
		case TipoAcaoEnum.LogIn:
			return 'Log In';
		case TipoAcaoEnum.Acesso:
			return 'Acesso';
		case TipoAcaoEnum.Avaliacao:
			return 'Avaliação';
		case TipoAcaoEnum.Auditoria:
			return 'Auditoria';
	}
};
