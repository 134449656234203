import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import './modal.css';
import UserAvatar from '../../../../../../../shared/components/userProfileIcon/userProfileIcon';
import { Row, TextInput, Flex, Select, Switch, Button } from '@jcm/design-system';
import { TFuncionario } from '../../../../ts/types/Funcionarios';
import { getPerfilFuncionarioDescricao, PerfilFuncionario } from '../../../../ts/enums/FuncaoEnum';
import MultiSelect from '../../../../../../../shared/components/multiSelectTruncated/multiSelect';
import ObrigacoesService from '../../../../services/Obrigacoes/Obrigacoes.service';
import { useGovernancaContext } from '../../../../../../../context/GovernancaContext';
import CustomAlert from '../../../../../../../shared/components/customAlert/customAlert';

const formSchema = z.object({
	nome: z.string().min(1, 'Nome é obrigatório'),
	email: z.string().min(1, 'Email é obrigatório').email('Email inválido'),
	empresa: z.array(z.number()).optional(),
	perfil: z.nativeEnum(PerfilFuncionario, { errorMap: () => ({ message: 'Perfil inválido' }) }),
	ativo: z.boolean(),
	cargo: z.string().optional(),
	funcionarioId: z.number().optional(),
});

interface FormModalProps {
	isOpen: boolean;
	onClose: () => void;
	usuario?: TFuncionario | null;
	onReloadList: () => void;
}

type FormValues = z.infer<typeof formSchema>;

const FormModal: React.FC<FormModalProps> = ({ isOpen, onClose, usuario, onReloadList }) => {
	const { usuario: usuarioLogado, empresaAtiva } = useGovernancaContext();
	const [criandoUsuario, setCriandoUsuario] = useState<boolean>(true);
	const [listagemEmpresas, setListagemEmpresas] = useState<{ label: string; value: number }[]>([]);
	const _obrigacoesService = new ObrigacoesService();
	const [loading, setLoading] = useState(false);
	const isAdminSistema = usuarioLogado?.funcao === 2;
	const [alert, setAlert] = useState<{ type: 'success' | 'error'; content: string; title?: string } | null>(null);

	useEffect(() => {
		if (alert && alert.type === 'success') {
			const timer = setTimeout(() => {
				setAlert(null);
				onClose();
				onReloadList();
				setLoading(false);
			}, 3000);
			return () => clearTimeout(timer);
		}
	}, [alert, onClose, onReloadList]);

	const {
		control,
		handleSubmit,
		setValue,
		reset,
		watch,
		formState: { errors },
	} = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			nome: '',
			email: '',
			empresa: [],
			perfil: PerfilFuncionario.Comum,
			ativo: true,
			cargo: '',
			funcionarioId: undefined,
		},
	});

	const fetchEmpresas = async () => {
		try {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const response = await _obrigacoesService.getEmpresasListagem(empresaAtiva!);
			if (response && Array.isArray(response.entidadesDisponiveis)) {
				const empresasOptions = response.entidadesDisponiveis.map((empresa: { text: string; value: string }) => ({
					label: empresa.text,
					value: parseInt(empresa.value, 10),
				}));
				setListagemEmpresas(empresasOptions);
			}
		} catch (error) {
			console.error('Erro ao buscar a listagem de empresas:', error);
		}
	};

	useEffect(() => {
		if (isOpen) {
			fetchEmpresas();

			if (usuario) {
				setCriandoUsuario(false);

				setValue('nome', usuario.nome);
				setValue('email', usuario.email);
				setValue('perfil', usuario.funcao !== undefined ? usuario.funcao : PerfilFuncionario.Comum);
				setValue(
					'empresa',
					usuario.participacaoEmpresa.map((e) => e.empresaId),
				);
				setValue('ativo', usuario.ativo);
				setValue('cargo', usuario.cargo || '');
				setValue('funcionarioId', usuario.funcionarioId);
			} else {
				setCriandoUsuario(true);
				reset({
					nome: '',
					email: '',
					empresa: [],
					perfil: PerfilFuncionario.Comum,
					ativo: true,
					cargo: '',
				});
			}
		}
	}, [usuario, isOpen, reset, setValue]);

	if (!isOpen) return null;

	const onSubmit = async (data: FormValues) => {
		try {
			setLoading(true);

			const [nome, dominio] = data.email.split('@');
			const dominioSemExtensao = dominio.split('.')[0];
			const login = `${nome}.${dominioSemExtensao}`;

			const payload = {
				...data,
				cargo: data.cargo === '' ? null : data.cargo,
				login,
			};

			if (criandoUsuario) {
				if (empresaAtiva !== null) {
					await _obrigacoesService.criarUsuario(empresaAtiva, {
						...payload,
						tipoFuncionario: data.perfil,
					});
					setAlert({ type: 'success', content: 'Usuário criado com sucesso.', title: 'Sucesso!' });
				}
			} else {
				if (empresaAtiva !== null && data.funcionarioId !== undefined) {
					await _obrigacoesService.atualizarUsuario(empresaAtiva, data.funcionarioId, {
						...payload,
						tipoFuncionario: data.perfil,
					});
					setAlert({ type: 'success', content: 'Usuário atualizado com sucesso.', title: 'Sucesso!' });
				}
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			const errorMessage =
				error.response?.data?.message || 'Ocorreu um erro ao salvar o usuário. Tente novamente mais tarde.';
			setAlert({ type: 'error', content: errorMessage });
			setLoading(false);
		}
	};

	const shouldTruncate = (text: string, maxLength: number) => text.length > maxLength;

	const perfilOptions = Object.values(PerfilFuncionario)
		.filter((value) => {
			if (value === PerfilFuncionario.AdministradorSistema && !isAdminSistema) return false;
			return typeof value === 'number';
		})
		.map((key) => ({
			label: getPerfilFuncionarioDescricao(key as PerfilFuncionario),
			value: key,
		}));

	return (
		<div className='modal-overlay' onClick={onClose}>
			<div className='modal-content' onClick={(e) => e.stopPropagation()}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Flex style={{ width: '100%', justifyContent: 'space-between' }}>
						<UserAvatar ativo={watch('ativo')} tamanho='large' />
						{!criandoUsuario && (
							<Flex style={{ gap: '0.5rem', display: 'flex' }}>
								<span>{watch('ativo') ? 'Ativo' : 'Inativo'}</span>
								<Switch checked={watch('ativo')} onChange={(checked) => setValue('ativo', checked)} size='small' />
							</Flex>
						)}
					</Flex>
					<Row style={{ marginTop: '2rem', gap: '1rem' }}>
						<Row style={{ width: '100%' }}>
							<Controller
								name='nome'
								control={control}
								render={({ field }) => (
									<TextInput
										required
										{...field}
										style={{ width: '100%' }}
										variant='filled'
										id='TextInput'
										label='Nome'
										placeholder='Nome'
										type='text'
									/>
								)}
							/>
							{errors.nome && <span>{errors.nome.message}</span>}
						</Row>
						<Row style={{ width: '100%' }}>
							<Controller
								name='email'
								control={control}
								render={({ field }) => (
									<TextInput
										required
										{...field}
										style={{ width: '100%' }}
										variant='filled'
										id='TextInput'
										label='Email'
										placeholder='email@email.com.br'
										type='email'
									/>
								)}
							/>
							{errors.email && <span>{errors.email.message}</span>}
						</Row>

						{isAdminSistema && (
							<Row style={{ width: '100%', display: 'none' }}>
								<Controller
									name='empresa'
									control={control}
									render={({ field }) => (
										<MultiSelect
											{...field}
											value={field.value || []}
											onChange={field.onChange}
											options={listagemEmpresas.map((option) => ({
												label: shouldTruncate(option.label, 20) ? option.label.slice(0, 20) : option.label,
												value: option.value,
											}))}
											label='Empresa'
										/>
									)}
								/>
								{errors.empresa && <span>{errors.empresa.message}</span>}
							</Row>
						)}

						<Row style={{ width: '100%' }}>
							<Controller
								name='perfil'
								control={control}
								render={({ field }) => (
									<Select
										{...field}
										id='Select'
										label='Perfil de acesso'
										options={perfilOptions}
										placeholder='selecione...'
										style={{ width: '100%' }}
										variant='filled'
									/>
								)}
							/>
							{errors.perfil && <span>{errors.perfil.message}</span>}
						</Row>
						<Row style={{ width: '100%' }}>
							<Controller
								name='cargo'
								control={control}
								render={({ field }) => (
									<TextInput
										{...field}
										style={{ width: '100%' }}
										variant='filled'
										id='TextInput'
										label='Cargo'
										placeholder='Cargo'
										type='text'
									/>
								)}
							/>
						</Row>
						<Flex style={{ gap: '1rem', justifyContent: 'center', width: '100%' }}>
							<Button type='filled-tonal' variant='error' onClick={onClose}>
								Cancelar
							</Button>
							<Button loading={loading} type='filled-tonal' variant='default' onClick={handleSubmit(onSubmit)}>
								Salvar
							</Button>
						</Flex>
					</Row>
				</form>

				{alert && <CustomAlert type={alert.type} content={alert.content} title={alert.title} />}
			</div>
		</div>
	);
};

export default FormModal;
