import React from 'react';
import { TipoRotulo } from './types';
import styles from './index.module.scss';

interface RotuloContagemObrigacoesProps {
	tipo: TipoRotulo;
	quantidade: number;
}

const RotuloContagemObrigacoes: React.FC<RotuloContagemObrigacoesProps> = ({ tipo, quantidade }) => {
	const className = `${styles.rotulo} ${styles[tipo]}`;

	return <span className={className}>{quantidade}</span>;
};

export default RotuloContagemObrigacoes;
