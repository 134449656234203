/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem, Box, Menu, IconButton } from '@mui/material';
import styles from './index.module.scss';
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import React, { useEffect, useMemo, useState } from 'react';
import { TFuncionario } from '../../../../ts/types/Funcionarios';
import { Col, Flex, Icons, Row, Select, Typography } from '@jcm/design-system';
import AvatarGroupComponent from '../AvatarGroupComponent/AvatarGroupComponent';
import ObrigacoesService from '../../../../services/Obrigacoes/Obrigacoes.service';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { obrigacaoEstaConcluida, usuarioApenasAprovador } from '../../../../utils/determinarAcessoObrigacoes';
import { useGovernancaContext } from '../../../../../../../context/GovernancaContext';
import { verificaPermissao } from '../../../../utils/determinarAcessoObrigacoes';

interface EnvioEmailsProps {
	control: Control<any>;
	errors: FieldErrors<any>;
	empresaId: number | string;
	setValue: UseFormSetValue<any>;
	getValues: UseFormGetValues<any>;
	usuarioAprovador: boolean;
	usuarioResponsavel: boolean;
	isEdit: boolean;
}

const fieldTitles: Record<string, string> = {
	listaEmailSelecionados: 'Outros destinatários',
	listaEmailSomenteAtrasoSelecionados: 'Destinatários quando em atraso',
};

const EnvioEmails: React.FC<EnvioEmailsProps> = ({
	control,
	errors,
	empresaId,
	getValues,
	setValue,
	usuarioAprovador,
	usuarioResponsavel,
	isEdit,
}) => {
	const [funcionarios, setFuncionarios] = useState<TFuncionario[]>([]);
	const funcionarioService = new ObrigacoesService();
	const aprovacao = getValues('estadoStatusAprovada');
	const [anchorEls, setAnchorEls] = useState<Record<string, null | HTMLElement>>({});
	const [loading, setLoading] = useState<boolean>(true);
	const { usuario } = useGovernancaContext();

	useEffect(() => {
		setLoading(true);
		funcionarioService
			.getFuncionariosByEmpresa(empresaId)
			.then((funcionarios) => {
				setFuncionarios(funcionarios);
				setLoading(false);
			})
			.catch((error) => console.error('Erro ao carregar funcionários:', error));
	}, []);

	const renderAvatars = useMemo(
		() => (ids: number[] | number) => {
			if (!Array.isArray(ids)) ids = [ids];

			const avatarData = ids
				.filter((id) => id)
				.map((id) => {
					const funcionario = funcionarios.find((f) => f.funcionarioId === id);
					if (!funcionario) console.warn(`Funcionário com ID ${id} não encontrado.`);
					return funcionario
						? { id: funcionario.funcionarioId, name: funcionario.nome.charAt(0), nomeCompleto: funcionario.nome }
						: null;
				})
				.filter(Boolean) as { id: number; name: string; nomeCompleto: string }[];

			return avatarData;
		},
		[funcionarios],
	);

	const handleRemoveAvatar = (
		field: string,
		setValue: UseFormSetValue<any>,
		getValues: UseFormGetValues<any>,
		id: number,
	) => {
		if (field === 'responsavelPrincipal' || field === 'aprovadorPrincipal') {
			setValue(field, 0);
		} else {
			const currentValues = getValues(field);
			const updatedValues = Array.isArray(currentValues) ? currentValues.filter((value: number) => value !== id) : [];
			setValue(field, updatedValues.length > 0 ? updatedValues : []);
		}
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>, field: string) => {
		setAnchorEls((prev) => ({ ...prev, [field]: event.currentTarget }));
	};

	const handleClose = (field: string, id?: number) => {
		setAnchorEls((prev) => ({ ...prev, [field]: null }));
		if (id !== undefined) {
			if (field === 'responsavelPrincipal' || field === 'aprovadorPrincipal') {
				setValue(field, id);
			} else {
				const currentValues = getValues(field) || [];
				const updatedValues = Array.isArray(currentValues) ? [...currentValues, id] : [id];
				setValue(field, Array.from(new Set(updatedValues)));
			}
		}
	};

	const getSelecionados = (field: string) => {
		return field === 'responsavelPrincipal' || field === 'aprovadorPrincipal'
			? [getValues(field)]
			: getValues(field) || [];
	};

	const getFuncionariosDisponiveis = (field: string) => {
		const selecionados = getSelecionados(field);
		return funcionarios.filter((funcionario) => !selecionados.includes(funcionario.funcionarioId));
	};
	return (
		<Box className={styles.envioEmailsContainer}>
			<Typography.Body size='large' strong>
				Regra para envio de e-mails
			</Typography.Body>

			<Row gutter={[16, 16]}>
				<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={7} className={styles.espacamentoTop}>
					<Typography.Body size='medium'>
						Frequência de envio <span style={{ color: 'red' }}>*</span>
					</Typography.Body>
					<Controller
						name='frequenciaSelecionada'
						control={control}
						render={({ field }) => (
							<>
								<Select
									{...field}
									className={styles.selectFormObrigacao}
									placeholder='Selecione'
									options={[
										{
											label: 'Diário',
											value: 1,
										},
										{
											label: 'Semanal',
											value: 2,
										},
										{
											label: 'Quinzenal',
											value: 3,
										},
										{
											label: 'Mensal',
											value: 4,
										},
										{
											label: 'Única',
											value: 5,
										},
									]}
									status={errors.frequenciaSelecionada ? 'error' : ''}
									disabled={verificaPermissao(usuario?.funcao, usuarioResponsavel, aprovacao, usuarioAprovador, isEdit)}
								/>
								{errors.frequenciaSelecionada && (
									<Typography.Label variant='error'>{errors.frequenciaSelecionada.message as string}</Typography.Label>
								)}
							</>
						)}
					/>
				</Col>

				<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={7} className={styles.espacamentoTop}>
					<Typography.Body size='medium'>
						Antecedência de envio <span style={{ color: 'red' }}>*</span>
					</Typography.Body>
					<Controller
						name='antecedenciaSelecionada'
						control={control}
						render={({ field }) => (
							<>
								<Select
									{...field}
									className={styles.selectFormObrigacao}
									placeholder='Selecione'
									options={[
										{
											label: '1 dia',
											value: 1,
										},
										{
											label: '3 dias',
											value: 2,
										},
										{
											label: '1 semana',
											value: 3,
										},
										{
											label: '2 semanas',
											value: 4,
										},
										{
											label: '1 mês',
											value: 5,
										},
										{
											label: 'Criação',
											value: 6,
										},
										{
											label: 'Somente vencimento',
											value: 7,
										},
									]}
									status={errors.antecedenciaSelecionada ? 'error' : ''}
									disabled={verificaPermissao(usuario?.funcao, usuarioResponsavel, aprovacao, usuarioAprovador, isEdit)}
								/>
								{errors.antecedenciaSelecionada && (
									<Typography.Label variant='error'>
										{errors.antecedenciaSelecionada.message as string}
									</Typography.Label>
								)}
							</>
						)}
					/>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={9} className={styles.espacamentoTop}>
					<Typography.Body size='medium'>
						Frequência de envio após vencimento <span style={{ color: 'red' }}>*</span>
					</Typography.Body>
					<Controller
						name='frequenciaVencidaSelecionada'
						control={control}
						render={({ field }) => (
							<>
								<Select
									{...field}
									className={styles.selectFormObrigacao}
									placeholder='Selecione'
									options={[
										{
											label: 'Diário',
											value: 1,
										},
										{
											label: 'Semanal',
											value: 2,
										},
										{
											label: 'Quinzenal',
											value: 3,
										},
										{
											label: 'Mensal',
											value: 4,
										},
										{
											label: 'Única',
											value: 5,
										},
									]}
									status={errors.frequenciaVencidaSelecionada ? 'error' : ''}
									disabled={verificaPermissao(usuario?.funcao, usuarioResponsavel, aprovacao, usuarioAprovador, isEdit)}
								/>
								{errors.frequenciaVencidaSelecionada && (
									<Typography.Label variant='error'>
										{errors.frequenciaVencidaSelecionada.message as string}
									</Typography.Label>
								)}
							</>
						)}
					/>
				</Col>

				<Col xs={24}>
					<Flex vertical>
						{['listaEmailSelecionados', 'listaEmailSomenteAtrasoSelecionados'].map((field, index) => (
							<>
								<Controller
									name={field}
									control={control}
									key={index}
									render={({ field: { value } }) => (
										<Box className={styles.avatarGroupContainer}>
											<PeopleAltOutlinedIcon className={styles.icon} />
											<AvatarGroupComponent
												title={fieldTitles[field] || field.replace(/([A-Z])/g, ' $1')}
												members={renderAvatars(Array.isArray(value) ? value : [value])}
												onRemove={(id) => handleRemoveAvatar(field, setValue, getValues, id)}
												required={
													fieldTitles[field] === 'listaEmailSelecionados' ||
													fieldTitles[field] === 'listaEmailSomenteAtrasoSelecionados'
														? true
														: false
												}
												estadoStatusAprovada={getValues('estadoStatusAprovada')}
											/>
											{!obrigacaoEstaConcluida(getValues('estadoStatusAprovada')) &&
												!(usuarioApenasAprovador(usuarioAprovador, usuarioResponsavel) && isEdit) && (
													<IconButton
														style={{ width: '38px', height: '38px' }}
														onClick={(event) => handleClick(event, field)}
													>
														<Icons.Add />
													</IconButton>
												)}
											<Menu
												anchorEl={anchorEls[field]}
												open={Boolean(anchorEls[field])}
												onClose={() => handleClose(field)}
											>
												<MenuItem>
													<Select
														allowClear={true}
														placeholder='Selecione o funcionário'
														style={{ width: 300 }}
														showSearch={true}
														loading={loading}
														onChange={(value) => {
															handleClose(field, value);
														}}
														label={'Funcionário'}
														filterOption={(input, option) =>
															(option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
														}
														options={getFuncionariosDisponiveis(field).map((func) => ({
															label: func.nome,
															value: func.funcionarioId,
														}))}
													/>
												</MenuItem>
											</Menu>
										</Box>
									)}
								/>
							</>
						))}
					</Flex>
				</Col>

				<Col xs={24}>
					<Flex gap={8} align='center'>
						<Icons.EmergencyHome style={{ fontSize: '16px' }} />
						<Typography.Label>
							O envio de emails para responsáveis e aprovadores são feito automaticamente não sendo necessário
							inserí-los novamente.
						</Typography.Label>
					</Flex>
				</Col>
			</Row>
		</Box>
	);
};

export default EnvioEmails;
