import { Button, Col, Flex, RangePicker, Row } from '@jcm/design-system';
import { useState } from 'react';
import estilo from './index.module.scss';
import dayjs, { Dayjs } from 'dayjs';
import ModalContainer from '../../../components/modalContainer/ModalContainer';
import FormObrigacoes from '../FormObrigacoes/FormObrigacoes';
import { listarIntervaloAnoAtual } from '../../../utils/listarIntervaloAnoAtras';
import { TObrigacao } from '../../../ts/types/Obrigacoes';

interface BarraFerramentasObrigacoesProps {
	onDateRangeChange: (dates: [Dayjs | null, Dayjs | null] | null) => void;
	empresaId: number | string;
	onObrigacaoChange: (obrigacaoAtualizada: TObrigacao) => void;
}

const BarraFerramentasObrigacoes: React.FC<BarraFerramentasObrigacoesProps> = ({
	empresaId,
	onDateRangeChange,
	onObrigacaoChange,
}) => {
	const [isModalVisible, setModalVisible] = useState(false);
	const [modalContent, setModalContent] = useState<React.ReactNode>(null);

	const showModal = (content: React.ReactNode) => {
		setModalContent(content);
		setModalVisible(true);
	};

	const handleCloseModal = () => {
		setModalVisible(false);
	};

	const datas = listarIntervaloAnoAtual();

	return (
		<>
			<Row justify='space-between' align='middle' className={estilo.barraFerramentas}>
				<Col xl={18} xxl={18} className={estilo.colPrincipal}>
					<Flex align='center' gap={12}>
						<RangePicker
							className={estilo.rangePicker}
							onChange={(dates) => onDateRangeChange(dates)}
							defaultValue={[dayjs(datas.dataInicio), dayjs(datas.dataFim)]}
						/>
					</Flex>
				</Col>

				<Col xl={6} xxl={4} className={estilo.colNovaObrigacao}>
					<Flex justify='flex-end'>
						<Button
							type='filled-tonal'
							variant='default'
							children='Nova Obrigação'
							className={estilo.novaObrigacao}
							onClick={() =>
								showModal(
									<FormObrigacoes
										isEdit={false}
										initialData={{}}
										onSave={(data) => console.log('Data Saved:', data)}
										onClose={handleCloseModal}
										empresaId={empresaId}
										onObrigacaoChange={onObrigacaoChange}
									/>,
								)
							}
						/>
					</Flex>
				</Col>

				<ModalContainer
					isVisible={isModalVisible}
					onClose={handleCloseModal}
					className={`form-obrigacao ${estilo.modalFormContainer}`}
					key={isModalVisible ? 'visible' : 'hidden'}
				>
					{modalContent}
				</ModalContainer>
			</Row>
		</>
	);
};

export default BarraFerramentasObrigacoes;
