export enum EnumTipoEmailFrequenciaEnvio {
	Nenhum = 0,
	Diario = 1,
	Semanal = 2,
	Quinzenal = 3,
	Mensal = 4,
	Unica = 5,
}

export enum EnumTipoAntencedenciaEmail {
	Nenhum = 0,
	UmDia = 1,
	TresDias = 2,
	UmaSemana = 3,
	DuasSemanas = 4,
	UmMes = 5,
	Criacao = 6,
	SomenteVencimento = 7,
}
