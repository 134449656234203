/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { useKeycloakContext } from '../../../../../context/KeycloakContext';
import { TApiResponse, TApiResponse2 } from '../helpers/ApiResponse';
import { GetObrigacoesResponse } from './types/Obrigacoes/Obrigacoes';
import { GetUsuariosResponse } from './types/Usuarios/Usuarios';
import { ObrigacoesApi } from './api/ObrigacoesApi';
import {
	CriarAnexosResponse,
	CriarObrigacaoPayload,
	CriarObrigacoesResponse,
	EditarAnexosResponse,
	EditarObrigacoesEmMassaPayload,
	ExcluirAnexosResponse,
	GetObrigacaoResponse,
	ObterListaAnexosResponse,
} from '../../ts/types/Obrigacoes';
import { GetEstatisticasObrigacoesResponse } from './types/Obrigacoes/EstatisticasObrigacoes';
import { getOrigemResponse } from './types/Origem/Origem';
import { TOrigem } from '../../ts/types/Origem';
import { CriarUsuarioResponse, PartialTFuncionario } from './types/Obrigacoes/Usuarios';
import { GetLogsObrigacaoResponse } from './types/Obrigacoes/LogsObrigacao';
import { GetNovidadeResponse } from './types/Novidade/Novidade';

export default class ObrigacoesService {
	private _obrigacaoApi: ObrigacoesApi;
	private _apiInstance: ReturnType<typeof axios.create>;

	constructor() {
		this._apiInstance = this._createApiInstance();
		this._obrigacaoApi = new ObrigacoesApi({ api: this._apiInstance });
	}

	private _createApiInstance() {
		const { accessToken } = useKeycloakContext();

		const apiInstance = axios.create({
			baseURL: 'https://calendarioabrapp-api-hml.jcmsystem.com.br/',
		});

		apiInstance.interceptors.request.use(
			(config) => {
				if (accessToken) {
					config.headers.Authorization = `Bearer ${accessToken}`;
				}
				return config;
			},
			(error) => {
				return Promise.reject(error);
			},
		);

		return apiInstance;
	}

	public async getAllObrigacoes(
		idEmpresa: number | string,
		options?: Record<string, string | number | boolean>,
	): Promise<GetObrigacoesResponse> {
		const response: TApiResponse<GetObrigacoesResponse> = await this._obrigacaoApi.getAllObrigacoes(idEmpresa, options);

		return response;
	}

	public async getAllObrigacoesEssenciais(
		idEmpresa: number | string,
		options?: Record<string, string | number | boolean>,
	): Promise<GetObrigacoesResponse> {
		const response: TApiResponse<GetObrigacoesResponse> = await this._obrigacaoApi.getAllObrigacoesEssenciais(
			idEmpresa,
			options,
		);

		return response;
	}

	public async getMinhasObrigacoes(
		idEmpresa: number | string,
		options?: Record<string, string | number | boolean>,
	): Promise<GetObrigacoesResponse> {
		const response: TApiResponse<GetObrigacoesResponse> = await this._obrigacaoApi.getMinhasObrigacoes(
			idEmpresa,
			options,
		);

		return response;
	}

	public async getMinhasEstatisticasObrigacoes(
		idEmpresa: number | string,
		options?: Record<string, string | number | boolean>,
	): Promise<GetEstatisticasObrigacoesResponse> {
		const response: TApiResponse<GetEstatisticasObrigacoesResponse> =
			await this._obrigacaoApi.getMinhasEstatisticasObrigacoes(idEmpresa, options);

		return response;
	}

	public async getAllEstatisticaObrigacoesEmpresa(
		idEmpresa: number | string,
		options?: Record<string, string | number | boolean>,
	): Promise<GetEstatisticasObrigacoesResponse> {
		const response: TApiResponse<GetEstatisticasObrigacoesResponse> =
			await this._obrigacaoApi.getAllEstatisticasObrigacoesEmpresa(idEmpresa, options);

		return response;
	}

	public async getUsuarioAutenticado(): Promise<GetUsuariosResponse> {
		const response: TApiResponse<GetUsuariosResponse> = await this._obrigacaoApi.getUsuarioAutenticado();

		return response.data.result;
	}

	public async getObrigacaoById(
		idEmpresa: number | string,
		idObrigacao: number | string,
	): Promise<GetObrigacaoResponse> {
		const response: TApiResponse<GetObrigacaoResponse> = await this._obrigacaoApi.getObrigacaoById(
			idEmpresa,
			idObrigacao,
		);
		return response.data.result;
	}

	public async criarObrigacao(
		idEmpresa: string | number,
		payload: unknown,
	): Promise<TApiResponse2<CriarObrigacoesResponse>> {
		return this._obrigacaoApi.criarObrigacao(idEmpresa, payload);
	}

	public async atualizarObrigacao(
		idEmpresa: string | number,
		idObrigacao: number | string,
		payload: CriarObrigacaoPayload,
	): Promise<TApiResponse2<CriarObrigacoesResponse>> {
		return this._obrigacaoApi.editarObrigacao(idEmpresa, idObrigacao, payload);
	}

	public async getAllUsuarios(
		idEmpresa: number | string,
		options?: Record<string, string | number | boolean>,
	): Promise<GetUsuariosResponse> {
		const response: TApiResponse<GetUsuariosResponse> = await this._obrigacaoApi.getAllUsuarios(idEmpresa, options);

		return response.data.result;
	}

	public async getAllUsuariosTodos(
		idEmpresa: number | string,
		currentPage = 1,
		pageSize = 10,
	): Promise<GetUsuariosResponse> {
		const response: TApiResponse<GetUsuariosResponse> = await this._obrigacaoApi.getAllUsuariosTodos(idEmpresa, {
			currentPage,
			pageSize,
		});

		return response.data.result;
	}

	public async getFuncionariosByEmpresa(
		idEmpresa: number | string,
		options?: Record<string, unknown>,
	): Promise<GetUsuariosResponse> {
		const response = await this._obrigacaoApi.getAllUsuarios(idEmpresa, options);

		if (response.data && response.data.result) {
			return response.data.result;
		} else {
			throw new Error('A resposta da API não contém a propriedade "result" com a lista de funcionários.');
		}
	}

	public async criarAnexoObrigacao(
		idEmpresa: number | string,
		idObrigacao: number | string,
		arquivo: File,
		descricao: string,
		username: string,
	): Promise<TApiResponse<CriarAnexosResponse>> {
		const formData = new FormData();
		formData.append('Arquivo', arquivo);
		formData.append('Descricao', descricao);
		formData.append('Username', username);

		const response = await this._obrigacaoApi.criarAnexoObrigacao(idEmpresa, idObrigacao, formData);
		return response;
	}

	public async editarAnexoObrigacao(
		idEmpresa: number | string,
		idAnexo: number | string,
		descricao: string,
	): Promise<TApiResponse<EditarAnexosResponse>> {
		const formData = new FormData();
		formData.append('Descricao', descricao);
		return this._obrigacaoApi.editarAnexoObrigacao(idEmpresa, idAnexo, formData);
	}

	public async excluirAnexoObrigacao(
		idEmpresa: number | string,
		idObrigacao: number | string,
		idAnexo: number | string,
	): Promise<TApiResponse<ExcluirAnexosResponse>> {
		return this._obrigacaoApi.excluirAnexoObrigacao(idEmpresa, idObrigacao, idAnexo);
	}

	public async getListaAnexos(
		idEmpresa: number | string,
		idObrigacao: number | string,
	): Promise<Array<ObterListaAnexosResponse>> {
		try {
			const response: TApiResponse2<Array<ObterListaAnexosResponse>> = await this._obrigacaoApi.getListaAnexos(
				idEmpresa,
				idObrigacao,
			);

			return response.data;
		} catch (error) {
			console.error('getListaAnexos - Error:', error);
			throw error;
		}
	}

	public async downloadAnexoObrigacao(
		idEmpresa: number | string,
		idAnexo: number | string,
		nomeOriginal: string,
	): Promise<void> {
		try {
			await this._obrigacaoApi.downloadAnexoObrigacao(idEmpresa, idAnexo, nomeOriginal);
		} catch (error) {
			console.error('Erro ao baixar o anexo:', error);
			throw error;
		}
	}

	public async getAllOrigem(idEmpresa: number | string): Promise<getOrigemResponse> {
		try {
			const response: TApiResponse<getOrigemResponse> = await this._obrigacaoApi.getAllOrigem(idEmpresa);

			return response.data.result;
		} catch (error) {
			console.error('getListaAnexos - Error:', error);
			throw error;
		}
	}

	public async criarOrigem(idEmpresa: string | number, payload: TOrigem): Promise<TApiResponse<TOrigem>> {
		return this._obrigacaoApi.criarOrigem(idEmpresa, payload);
	}

	public async atualizarOrigem(
		idEmpresa: string | number,
		idOrigem: number | string,
		payload: TOrigem,
	): Promise<TApiResponse<TOrigem>> {
		return this._obrigacaoApi.atualizarOrigem(idEmpresa, idOrigem, payload);
	}

	public async deletarOrigem(idEmpresa: string | number, idOrigem: number | string): Promise<TApiResponse<TOrigem>> {
		return this._obrigacaoApi.deletarOrigem(idEmpresa, idOrigem);
	}

	public async getEmpresasListagem(idEmpresa: number | string): Promise<any> {
		const response: TApiResponse2<any> = await this._obrigacaoApi.getEmpresasListagem(idEmpresa);
		return response.data;
	}

	public async updateEmpresaAtiva(idEmpresa: number | string, idUsuario: number): Promise<TApiResponse<any>> {
		const idEmpresaNumerico = typeof idEmpresa === 'string' ? parseInt(idEmpresa, 10) : idEmpresa;
		return await this._obrigacaoApi.updateEmpresaAtiva(idEmpresaNumerico, {
			idEmpresa: idEmpresaNumerico,
			idUsuario,
		});
	}

	public async criarUsuario(idEmpresa: string | number, payload: unknown): Promise<TApiResponse<CriarUsuarioResponse>> {
		return this._obrigacaoApi.criarUsuario(idEmpresa, payload);
	}

	public async atualizarUsuario(
		idEmpresa: string | number,
		idUsuario: number | string,
		payload: PartialTFuncionario,
	): Promise<TApiResponse<PartialTFuncionario>> {
		return this._obrigacaoApi.atualizarUsuario(idEmpresa, idUsuario, payload);
	}

	public async getLogsObrigacao(idEmpresa: number | string, idLog: number | string): Promise<GetLogsObrigacaoResponse> {
		return await this._obrigacaoApi.getAllObrigacaoLogs(idEmpresa, idLog);
	}

	public async getAllNovidades(idEmpresa: number | string): Promise<GetNovidadeResponse> {
		return await this._obrigacaoApi.getAllNovidades(idEmpresa);
	}

	public async editarObrigacoesEmMassa(
		idEmpresa: string | number,
		payload: EditarObrigacoesEmMassaPayload,
	): Promise<TApiResponse<null>> {
		try {
			const response = await this._obrigacaoApi.editarObrigacoesEmMassa(idEmpresa, payload);
			if (!response.isSuccess) {
				const errorDetail = response.errors ?? 'Erro desconhecido.';
				console.error('Erro na API:', errorDetail);
				throw new Error(`Erro na API: ${errorDetail}`);
			}
			return response;
		} catch (error) {
			console.error('Erro ao realizar edição em massa:', error);
			throw error;
		}
	}

	public async getAllLogs(idEmpresa: number | string): Promise<GetLogsObrigacaoResponse> {
		return await this._obrigacaoApi.getAllLogs(idEmpresa);
	}

	public async excluirObrigacoesEmMassa(idEmpresa: number | string, idsObrigacoes: number[]): Promise<void> {
		try {
			const payload = { idsObrigacoes };
			const response = await this._obrigacaoApi.excluirObrigacoesEmMassa(idEmpresa, payload);

			if (!response.isSuccess) {
				throw new Error('Erro ao excluir obrigações em massa.');
			}
		} catch (error) {
			console.error('Erro ao excluir obrigações:', error);
			throw error;
		}
	}
}
