import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createContext, useContext, useEffect, useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IQueryClientContext extends QueryClient {}

// eslint-disable-next-line
const QueryClientContext = createContext<IQueryClientContext>(undefined!);

export const useQueryClientContext = () => useContext(QueryClientContext);

type Props = { children: React.ReactNode };

export const QueryClientContextProvider: React.FC<Props> = ({ children }) => {
	const queryClient = useMemo(() => new QueryClient({ defaultOptions: { queries: { staleTime: 60 * 1000 } } }), []);

	useEffect(() => {
		queryClient.invalidateQueries();
	});

	const context = useMemo<IQueryClientContext>(() => queryClient, [queryClient]);

	return (
		<QueryClientContext.Provider value={context}>
			<QueryClientProvider client={queryClient}>
				{children}
				<ReactQueryDevtools initialIsOpen={false} position='bottom' />
			</QueryClientProvider>
		</QueryClientContext.Provider>
	);
};
