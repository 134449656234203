import React from 'react';
import { Col, Flex, Icons, Row, Typography, Button } from '@jcm/design-system';
import { TObrigacao } from '../../../../ts/types/Obrigacoes';
import { Popover } from 'antd';

type ResponsabilidadesProps = Pick<TObrigacao, 'aprovadores' | 'responsaveis'> & {
	disabled?: boolean;
	ehLegal: boolean;
	onAttachClick: () => void;
	hasAnexos: boolean;
};

const Responsabilidades: React.FC<ResponsabilidadesProps> = ({
	aprovadores,
	responsaveis,
	onAttachClick,
}) => {

	function getPrimeiroEUltimoNome(nomeCompleto: string): string {
		const nomes = nomeCompleto.split(' ');
		if (nomes.length > 1) {
			return `${nomes[0]} ${nomes[nomes.length - 1]}`;
		}
		return nomeCompleto;
	}

	function getNomeAprovadorPrincipal(aprovadores: TObrigacao['aprovadores']): string {
		const aprovadorPrincipal = aprovadores?.find((item) => item.principal === true);
		const nomeCompleto = aprovadorPrincipal?.funcionario?.nome || '';
		return getPrimeiroEUltimoNome(nomeCompleto);
	}

	function getNomeResponsavelPrincipal(responsaveis: TObrigacao['responsaveis']): string {
		const responsavelPrincipal = responsaveis?.find((item) => item.principal === true);
		const nomeCompleto = responsavelPrincipal?.funcionario?.nome || '';
		return getPrimeiroEUltimoNome(nomeCompleto);
	}

	return (
		<Col span={24}>
			<Flex align='center' justify='space-between'>
				<Row gutter={[47, 47]}>
					<Col>
						<Flex vertical>
							<Typography.Label size='medium'>Responsável</Typography.Label>
							<Typography.Body strong>{getNomeResponsavelPrincipal(responsaveis)}</Typography.Body>
						</Flex>
					</Col>
					<Col>
						<Flex vertical>
							<Typography.Label size='medium'>Aprovador</Typography.Label>
							<Typography.Body strong>{getNomeAprovadorPrincipal(aprovadores)}</Typography.Body>
						</Flex>
					</Col>
				</Row>

				<Row gutter={10}>
					<Popover placement='top' content={'Anexos'}>
						<Col>
							<Button
								type='text'
								variant='default'
								icon={<Icons.AttachFile color='#006A6A' style={{ fontSize: '20px' }} />}
								circle
								onClick={onAttachClick}
							></Button>
						</Col>
					</Popover>
				</Row>
			</Flex>
		</Col>
	);
};

export default Responsabilidades;
