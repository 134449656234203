import React from 'react';
import { Avatar, Box, IconButton, Tooltip } from '@mui/material';
import { Popover } from 'antd';
import CloseIcon from '@mui/icons-material/Close';
import styles from './index.module.scss';
import { obrigacaoEstaConcluida } from '../../../../utils/determinarAcessoObrigacoes';
import { Typography } from '@jcm/design-system';

interface AvatarGroupComponentProps {
	title: string;
	members: { id: number; name: string; nomeCompleto: string }[];
	color?: string;
	bgColor?: string;
	onRemove?: (id: number) => void;
	required?: boolean;
	estadoStatusAprovada?: number;
	disabled?: boolean;
}

const AvatarGroupComponent: React.FC<AvatarGroupComponentProps> = ({
	title,
	members,
	bgColor,
	color,
	onRemove,
	required = false,
	estadoStatusAprovada,
	disabled,
}) => {
	const displayMembers = members.slice(0, 2);
	const remainingCount = members.length - displayMembers.length;

	return (
		<Box className={styles.avatarGroupContainer}>
			<Typography.Body>
				{title} {required && <span style={{ color: 'red' }}>*</span>}
			</Typography.Body>
			<Box className={styles.avatarGroup}>
				{displayMembers.map((member) => (
					<Box key={member.id} sx={{ position: 'relative', marginRight: 1 }}>
						<Popover placement='bottom' content={member.nomeCompleto}>
							<Avatar
								sx={{ bgcolor: bgColor || '#80D4D5', color: color || '#2F2E2E', width: '32px', height: '32px' }}
								className={styles.avatars}
							>
								{member.name}
							</Avatar>
						</Popover>

						{onRemove && !obrigacaoEstaConcluida(estadoStatusAprovada ?? 0) && (
							<IconButton
								size='small'
								sx={{
									position: 'absolute',
									top: -8,
									right: -8,
									backgroundColor: '#fff',
									border: '1px solid #BDBDBD',
									borderRadius: '50%',
									padding: '2px',
									transition: 'background-color 0.3s ease, transform 0.3s ease',
									'&:hover': {
										backgroundColor: '#f0f0f0',
										transform: 'scale(1.1)',
									},
									'& svg': {
										fontSize: '16px',
										color: '#000',
									},
									visibility: disabled ? 'hidden' : 'visible'
								}}
								onClick={() => onRemove(member.id)}
							>
								<CloseIcon fontSize='small' />
							</IconButton>
						)}
					</Box>
				))}
				{remainingCount > 0 && (
					<Tooltip title={`+${remainingCount} more`} arrow>
						<Avatar
							sx={{ bgcolor: bgColor || '#80D4D5', color: color || '#2F2E2E', width: '32px', height: '32px' }}
							className={styles.avatars}
						>
							+{remainingCount}
						</Avatar>
					</Tooltip>
				)}
			</Box>
		</Box>
	);
};

export default AvatarGroupComponent;
