import styled from 'styled-components';
import { Collapse as AntCollapse } from 'antd';

const CustomCollapse = styled(AntCollapse)`
	background-color: #ffffff !important;
	border-radius: 8px !important;
	border: 1px solid #d9d9d9 !important;

	.ant-collapse-item {
		border: none !important;
	}

	.ant-collapse-item-active {
		border-radius: 8px !important;
	}

	.ant-collapse-header {
		font-family: 'Inter', sans-serif !important;
		font-size: 16px !important;
		font-weight: 500 !important;
		padding: 10px 10px !important;
		display: flex !important;
		justify-content: space-between !important;
		align-items: center !important;
		cursor: pointer !important;

		&:hover {
			background-color: #f5f5f5 !important;
		}
	}

	.ant-collapse-content {
		margin-top: -10px;
		background-color: #fafafa !important;
		border-top: none !important;
		border-radius: 0 0 8px 8px !important;

		.ant-collapse-content-box {
			padding: 16px !important;
			font-size: 14px !important;
			color: #333 !important;
		}
	}

	.ant-collapse-arrow {
		display: none !important;
	}
`;

export default CustomCollapse;
