import { Col, Row, Flex, Button, SearchBar, Select, Typography } from '@jcm/design-system';
import CardUsuario from './CartaoUsuario/index';
import ModalCriar from './CriarUsuario/index';
import { useEffect, useState } from 'react';
import { TFuncionario } from '../../../ts/types/Funcionarios';
import ObrigacoesService from '../../../services/Obrigacoes/Obrigacoes.service';
import { useGovernancaContext } from '../../../../../../context/GovernancaContext';
import { PerfilFuncionario, getPerfilFuncionarioDescricao } from '../../../ts/enums/FuncaoEnum';
import Lottie from 'lottie-react';
import LoadingAnimation from '../../../../../../assets/loadings/CarregandoListaObrigacoes.json';
import { Pagination } from '@mui/material';

export default function Usuarios(): JSX.Element {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { empresaAtiva } = useGovernancaContext();
	const usuariosService = new ObrigacoesService();
	const [listagemUsuario, setListagemUsuario] = useState<TFuncionario[]>([]);
	const [usuarioSelecionado, setUsuarioSelecionado] = useState<TFuncionario | null>(null);
	const [loading, setLoading] = useState(true);
	const [statusFiltro, setStatusFiltro] = useState<number>(1);
	const [termoPesquisa, setTermoPesquisa] = useState<string>('');

	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(10);
	const [totalPages, setTotalPages] = useState(1);

	const openModal = (usuario?: TFuncionario) => {
		setUsuarioSelecionado(usuario || null);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setUsuarioSelecionado(null);
	};

	const reloadList = async () => {
		setLoading(true);
		if (empresaAtiva !== undefined && empresaAtiva !== null) {
			const usuariosResponse = await usuariosService.getAllUsuariosTodos(empresaAtiva);
			setListagemUsuario(usuariosResponse);
			setTotalPages(Math.ceil(usuariosResponse.length / pageSize));
		}
		setLoading(false);
	};

	const handleStatusChange = () => {
		reloadList();
	};

	const aplicarFiltros = () => {
		let usuariosFiltrados = listagemUsuario;

		if (statusFiltro === 2) {
			usuariosFiltrados = usuariosFiltrados.filter((usuario) => usuario.ativo === true);
		} else if (statusFiltro === 3) {
			usuariosFiltrados = usuariosFiltrados.filter((usuario) => usuario.ativo === false);
		}

		if (termoPesquisa.trim()) {
			usuariosFiltrados = usuariosFiltrados.filter(
				(usuario) =>
					usuario.nome.toLowerCase().includes(termoPesquisa.toLowerCase()) ||
					usuario.email.toLowerCase().includes(termoPesquisa.toLowerCase()) ||
					usuario.login.toLowerCase().includes(termoPesquisa.toLowerCase()),
			);
		}

		const startIndex = (currentPage - 1) * pageSize;
		const endIndex = startIndex + pageSize;
		return usuariosFiltrados.slice(startIndex, endIndex);
	};

	useEffect(() => {
		if (empresaAtiva) {
			reloadList();
		}
	}, [empresaAtiva]);

	useEffect(() => {
		aplicarFiltros();
	}, [statusFiltro, termoPesquisa, currentPage]);

	const handlePageChange = (_: unknown, page: number) => {
		setCurrentPage(page);
	};

	const usuariosFiltrados = aplicarFiltros();

	return (
		<div style={{ backgroundColor: '#FFFFFF', height: '100%', padding: '1rem' }}>
			<Flex style={{ padding: '1rem', width: '100%', justifyContent: 'space-between', alignItems: 'end' }}>
				<Row style={{ gap: '1rem' }}>
					<Col style={{ alignSelf: 'end' }}>
						<SearchBar
							allowClear
							id='pesquisa'
							placeholder='Pesquisar'
							variant='outlined'
							className='col'
							value={termoPesquisa}
							onChange={(e) => setTermoPesquisa(e.target.value)}
							style={{
								width: '268px',
								height: '39px',
								alignSelf: 'end',
							}}
						/>
					</Col>
					<Col>
						<Select
							defaultValue={1}
							id='status'
							label='Status'
							options={[
								{
									label: 'Todos',
									value: 1,
								},
								{
									label: 'Ativos',
									value: 2,
								},
								{
									label: 'Inativos',
									value: 3,
								},
							]}
							onChange={(value) => {
								setStatusFiltro(value);
							}}
							style={{
								width: '268px',
							}}
							variant='outlined'
						/>
					</Col>
				</Row>
				<Button
					onClick={() => openModal()}
					style={{ height: '36px' }}
					type='filled-tonal'
					variant='default'
					children='Novo usuário'
				/>
			</Flex>

			{loading ? (
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
					<Lottie loop animationData={LoadingAnimation} style={{ width: '50%', height: '50%' }} />
				</div>
			) : (
				<>
					{usuariosFiltrados?.length > 0 ? (
						<>
							{usuariosFiltrados.map((usuario, index) => (
								<CardUsuario
									key={index}
									nome={usuario.nome}
									email={usuario.email}
									empresa={usuario.participacaoEmpresa[0].empresa.nome}
									perfil={getPerfilFuncionarioDescricao(usuario.funcao as PerfilFuncionario)}
									ativo={usuario.ativo}
									onEdit={() => openModal(usuario)}
									funcionarioId={usuario.funcionarioId}
									login={usuario.login}
									onStatusChange={handleStatusChange}
								/>
							))}
							{totalPages > 1 && (
								<Flex justify='center' style={{ marginTop: '1rem' }}>
									<Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
								</Flex>
							)}
						</>
					) : (
						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
							<Flex justify='center'>
								<Typography.Headline>Não há usuários a serem listados.</Typography.Headline>
							</Flex>
						</div>
					)}
				</>
			)}

			<ModalCriar isOpen={isModalOpen} onClose={closeModal} usuario={usuarioSelecionado} onReloadList={reloadList} />
		</div>
	);
}
