// Função para formatar a descrição e substituir tags
export function formatDescricao(descricao: string | null | undefined): string {
	if (!descricao) return ''; // Retorna uma string vazia se descricao for null ou undefined
	return descricao
		.replace(/-DE:/g, '<em>de:</em>') // Aplicando itálico e minúsculo
		.replace(/-PARA:/g, '<em>para:</em>') // Aplicando itálico e minúsculo
		.replace(/Data Vencimento:/g, '<b>Data de Vencimento:</b>')
		.replace(/Justificativa Alteração Data Vencimento:/g, '<b>Justificativa de Alteração de Data:</b>')
		.replace(/Solicitação Alteração Data Vencimento:/g, '<b>Solicitação de Alteração de Data:</b>')
		.replace(/\n/g, '<br />'); // Caso tenha quebras de linha
}

// Função para formatar a descrição sem HTML para Excel
export function formatDescricaoExcel(descricao: string | null | undefined): string {
	if (!descricao) return ''; // Retorna uma string vazia se descricao for null ou undefined
	return descricao
		.replace(/<b>/g, '') // Remove a tag <b>
		.replace(/<\/b>/g, '') // Remove a tag de fechamento </b>
		.replace(/-DE:/g, 'de:') // Formatação sem HTML
		.replace(/-PARA:/g, 'para:') // Formatação sem HTML
		.replace(/Data Vencimento:/g, 'Data de Vencimento:') // Formatação do texto
		.replace(/Justificativa Alteração Data Vencimento:/g, 'Justificativa de Alteração de Data:') // Formatação do texto
		.replace(/Solicitação Alteração Data Vencimento:/g, 'Solicitação de Alteração de Data:') // Formatação do texto
		.replace(/\n/g, ' '); // Substitui quebras de linha por espaço
}
