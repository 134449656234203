/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, MenuItem, Menu, IconButton } from '@mui/material';
import styles from './index.module.scss';
import { CriarObrigacaoPayload } from '../../../../ts/types/Obrigacoes';
import { TFuncionario } from '../../../../ts/types/Funcionarios';
import AvatarGroupComponent from '../AvatarGroupComponent/AvatarGroupComponent';
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { Flex, Icons, Select, Switch, Typography, Row, Col } from '@jcm/design-system';
import estilo from './index.module.scss';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ObrigacoesService from '../../../../services/Obrigacoes/Obrigacoes.service';
import { Input } from 'antd';
import {
	obrigacaoEstaConcluida,
	usuarioApenasAprovador,
	usuarioEhAdmin,
} from '../../../../utils/determinarAcessoObrigacoes';
import { TOrigem } from '../../../../ts/types/Origem';
import { useGovernancaContext } from '../../../../../../../context/GovernancaContext';
import { verificaPermissao } from '../../../../utils/determinarAcessoObrigacoes';

const { TextArea } = Input;

interface GerenciarObrigacaoProps {
	data: CriarObrigacaoPayload;
	onChange: (field: keyof CriarObrigacaoPayload, value: unknown) => void;
	isEdit?: boolean;
	empresaId: number | string;
	control: Control<any>;
	errors: FieldErrors<any>;
	setValue: UseFormSetValue<any>;
	getValues: UseFormGetValues<any>;
	usuarioAprovador: boolean;
	usuarioResponsavel: boolean;
}

const fieldTitles: Record<string, string> = {
	responsavelPrincipal: 'Responsável',
	aprovadorPrincipal: 'Aprovador',
	responsaveisSelecionados: 'Responsáveis',
	aprovadoresSelecionados: 'Aprovadores',
};

const GerenciarObrigacao: React.FC<GerenciarObrigacaoProps> = ({
	empresaId,
	control,
	isEdit = false,
	setValue,
	getValues,
	onChange,
	errors,
	usuarioAprovador,
	usuarioResponsavel,
}) => {
	const [funcionarios, setFuncionarios] = useState<TFuncionario[]>([]);
	const [origens, setOrigens] = useState<TOrigem[]>([]);

	const obrigacoesService = new ObrigacoesService();
	const [anchorEls, setAnchorEls] = useState<Record<string, null | HTMLElement>>({});
	const { usuario } = useGovernancaContext();
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		setLoading(true);
		obrigacoesService
			.getAllUsuarios(empresaId)
			.then((response) => {
				setFuncionarios(response);
				setLoading(false);
			})
			.catch((error) => console.error('Erro ao carregar funcionários:', error));

		obrigacoesService
			.getAllOrigem(empresaId)
			.then((response) => {
				setOrigens(response);
			})
			.catch((error) => console.error('Erro ao carregar funcionários:', error));
	}, [empresaId]);

	const renderAvatars = useMemo(
		() => (ids: number[] | number) => {
			if (!Array.isArray(ids)) ids = [ids];

			const avatarData = ids
				.filter((id) => id)
				.map((id) => {
					const funcionario = funcionarios.find((f) => f.funcionarioId === id);
					if (!funcionario) console.warn(`Funcionário com ID ${id} não encontrado.`);
					return funcionario
						? { id: funcionario.funcionarioId, name: funcionario.nome.charAt(0), nomeCompleto: funcionario.nome }
						: null;
				})
				.filter(Boolean) as { id: number; name: string; nomeCompleto: string }[];

			return avatarData;
		},
		[funcionarios],
	);

	const handleRemoveAvatar = (
		field: string,
		setValue: UseFormSetValue<any>,
		getValues: UseFormGetValues<any>,
		id: number,
	) => {
		if (field === 'responsavelPrincipal' || field === 'aprovadorPrincipal') {
			setValue(field, 0);
		} else {
			const currentValues = getValues(field);
			const updatedValues = Array.isArray(currentValues) ? currentValues.filter((value: number) => value !== id) : [];
			setValue(field, updatedValues.length > 0 ? updatedValues : []);
		}
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>, field: string) => {
		setAnchorEls((prev) => ({ ...prev, [field]: event.currentTarget }));
	};

	const handleClose = (field: string, id?: number) => {
		setAnchorEls((prev) => ({ ...prev, [field]: null }));
		if (id !== undefined) {
			if (field === 'responsavelPrincipal' || field === 'aprovadorPrincipal') {
				setValue(field, id);
			} else {
				const currentValues = getValues(field) || [];
				const updatedValues = Array.isArray(currentValues) ? [...currentValues, id] : [id];
				setValue(field, Array.from(new Set(updatedValues)));
			}
		}
	};

	const aprovacao = getValues('estadoStatusAprovada');

	const getSelecionados = (field: string) => {
		return field === 'responsavelPrincipal' || field === 'aprovadorPrincipal'
			? [getValues(field)]
			: getValues(field) || [];
	};

	const getFuncionariosDisponiveis = (field: string) => {
		const selecionados = getSelecionados(field);
		return funcionarios.filter((funcionario) => !selecionados.includes(funcionario.funcionarioId));
	};

	return (
		<Box className={styles.criacaoObrigacaoContainer}>
			<div className={styles.rowContainer}>
				<Controller
					name='origemSelecionada'
					control={control}
					render={({ field }) => (
						<Row>
							<Col>
								<Flex gap={6} align='center'>
									<Typography.Body>Origem</Typography.Body>
								</Flex>
								<Select
									style={{ minWidth: '200px' }}
									{...field}
									className={styles.selectFormObrigacao}
									variant='outlined'
									placeholder='Origem'
									disabled={verificaPermissao(usuario?.funcao, usuarioResponsavel, aprovacao, usuarioAprovador, isEdit)}
									options={origens.map((origem) => ({ label: origem.nomeOrigem, value: origem.origemId }))}
								/>
							</Col>

							{errors.origem && <Typography.Label variant='error'>{errors.origem.message as string}</Typography.Label>}
						</Row>
					)}
				/>
				<Controller
					name='ehLegal'
					control={control}
					render={({ field }) => (
						<Flex gap={8} align='center'>
							<Typography.Body size='medium'>Obrigação legal</Typography.Body>
							<Switch
								{...field}
								size='small'
								checked={field.value}
								disabled={verificaPermissao(usuario?.funcao, usuarioResponsavel, aprovacao, usuarioAprovador, isEdit)}
							/>
						</Flex>
					)}
				/>
			</div>

			<Grid spacing={2} sx={{ marginTop: 2 }}></Grid>

			<Grid container spacing={2} sx={{ marginTop: 1 }}>
				{['responsavelPrincipal', 'aprovadorPrincipal', 'responsaveisSelecionados', 'aprovadoresSelecionados'].map(
					(field, index) => (
						<Grid item xs={12} md={6} key={index}>
							<Controller
								name={field}
								control={control}
								render={({ field: { value } }) => (
									<Box className={styles.avatarGroupContainer}>
										<PersonOutlineOutlinedIcon className={styles.icon} />
										<AvatarGroupComponent
											title={fieldTitles[field] || field.replace(/([A-Z])/g, ' $1')}
											members={renderAvatars(Array.isArray(value) ? value : [value])}
											onRemove={(id) => handleRemoveAvatar(field, setValue, getValues, id)}
											required={field === 'responsavelPrincipal' || field === 'aprovadorPrincipal'}
											estadoStatusAprovada={getValues('estadoStatusAprovada')}
											disabled={
												obrigacaoEstaConcluida(aprovacao) ||
												(usuarioApenasAprovador(usuarioAprovador, usuarioResponsavel) && isEdit)
											}
										/>
										{!obrigacaoEstaConcluida(getValues('estadoStatusAprovada')) &&
											!(usuarioEhAdmin(usuario?.funcao, usuarioResponsavel) && isEdit) && (
												<IconButton
													style={{ width: '38px', height: '38px' }}
													onClick={(event) => handleClick(event, field)}
												>
													<Icons.Add />
												</IconButton>
											)}
										<Menu
											anchorEl={anchorEls[field]}
											open={Boolean(anchorEls[field])}
											onClose={() => handleClose(field)}
										>
											<MenuItem
												style={{
													borderRadius: '.5rem',
												}}
											>
												<Select
													allowClear={true}
													placeholder='Selecione o funcionário'
													style={{ width: 300 }}
													loading={loading}
													showSearch={true}
													onChange={(value) => {
														handleClose(field, value);
													}}
													label={'Funcionário'}
													filterOption={(input, option) =>
														(option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
													}
													options={getFuncionariosDisponiveis(field).map((func) => ({
														label: func.nome,
														value: func.funcionarioId,
													}))}
													notFoundContent={'Carregando usuários...'}
												/>
											</MenuItem>
										</Menu>
									</Box>
								)}
							/>
						</Grid>
					),
				)}

				<Grid item xs={12}>
					<Controller
						name='nome'
						control={control}
						render={({ field }) => (
							<Flex vertical gap={2}>
								<Typography.Body>
									Descrição <span style={{ color: 'red' }}>*</span>
								</Typography.Body>
								<TextArea
									allowClear
									variant='filled'
									placeholder='Descrição'
									{...field}
									className={estilo.textAreaFormulario}
									disabled={verificaPermissao(usuario?.funcao, usuarioResponsavel, aprovacao, usuarioAprovador, isEdit)}
								/>
								{errors.nome && <Typography.Label variant='error'>{errors.nome.message as string}</Typography.Label>}
							</Flex>
						)}
					/>
				</Grid>

				<Grid item xs={6}>
					<Controller
						name='acompanhamento'
						control={control}
						render={({ field }) => (
							<Flex vertical gap={2}>
								<Typography.Body>Acompanhamento</Typography.Body>
								<TextArea
									allowClear
									variant='filled'
									placeholder='Acompanhamento'
									{...field}
									className={estilo.textAreaFormulario}
									disabled={verificaPermissao(usuario?.funcao, usuarioResponsavel, aprovacao, usuarioAprovador, isEdit)}
								/>
							</Flex>
						)}
					/>
				</Grid>

				<Grid item xs={6}>
					<Controller
						name='fundamentacaoLegal'
						control={control}
						render={({ field }) => (
							<Flex vertical gap={2}>
								<Typography.Body>Fundamentação Legal</Typography.Body>
								<TextArea
									allowClear
									variant='filled'
									placeholder='Fundamentação Legal'
									{...field}
									className={estilo.textAreaFormulario}
									disabled={verificaPermissao(usuario?.funcao, usuarioResponsavel, aprovacao, usuarioAprovador, isEdit)}
								/>
							</Flex>
						)}
					/>
				</Grid>

				<Grid item xs={6}>
					<Controller
						name='datas'
						control={control}
						render={({ field }) => (
							<Flex vertical gap={2}>
								<Typography.Body>Datas</Typography.Body>
								<TextArea
									allowClear
									variant='filled'
									placeholder='Datas'
									{...field}
									className={estilo.textAreaFormulario}
									disabled={verificaPermissao(usuario?.funcao, usuarioResponsavel, aprovacao, usuarioAprovador, isEdit)}
								/>
							</Flex>
						)}
					/>
				</Grid>

				<Grid item xs={6}>
					<Controller
						name='prazoLegal'
						control={control}
						render={({ field }) => (
							<Flex vertical gap={2}>
								<Typography.Body>Prazo legal</Typography.Body>
								<TextArea
									allowClear
									variant='filled'
									placeholder='Prazo legal'
									{...field}
									className={estilo.textAreaFormulario}
									disabled={verificaPermissao(usuario?.funcao, usuarioResponsavel, aprovacao, usuarioAprovador, isEdit)}
								/>
							</Flex>
						)}
					/>
				</Grid>

				<Grid item xs={6}>
					<Controller
						name='temporalidade'
						control={control}
						render={({ field }) => (
							<Flex vertical gap={2}>
								<Typography.Body>Temporalidade</Typography.Body>
								<TextArea
									allowClear
									variant='filled'
									placeholder='Temporalidade'
									{...field}
									className={estilo.textAreaFormulario}
									disabled={verificaPermissao(usuario?.funcao, usuarioResponsavel, aprovacao, usuarioAprovador, isEdit)}
								/>
							</Flex>
						)}
					/>
				</Grid>

				<Grid item xs={6}>
					<Controller
						name='formaCumprimento'
						control={control}
						render={({ field }) => (
							<Flex vertical gap={2}>
								<Typography.Body>Forma de cumprimento</Typography.Body>
								<TextArea
									allowClear
									variant='filled'
									placeholder='Forma de cumprimento'
									{...field}
									className={estilo.textAreaFormulario}
									disabled={verificaPermissao(usuario?.funcao, usuarioResponsavel, aprovacao, usuarioAprovador, isEdit)}
								/>
							</Flex>
						)}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default GerenciarObrigacao;
