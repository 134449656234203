import { z } from 'zod';

export const EditarObrigacaoZodSchema = z
	.object({
		id: z.number().optional().nullable(),
		frequenciaSelecionada: z
			.number()
			.nullable()
			.refine((value) => value !== null && Number.isInteger(value), {
				message: 'Frequência de envio obrigatório',
			}),

		antecedenciaSelecionada: z
			.number()
			.nullable()
			.refine((value) => value !== null && Number.isInteger(value), {
				message: 'Antecedência de envio obrigatória',
			}),
		frequenciaVencidaSelecionada: z
			.number()
			.nullable()
			.refine((value) => value !== null && Number.isInteger(value), {
				message: 'Frequência de envio após vencimento obrigatória',
			}),

		// Calculo Periodicidade
		tipoVencimento: z.number().optional().nullable(), // Se isEdit for true, o campo é opcional

		dataVencimento: z.string().min(1, 'Data de vencimento obrigatória'),

		periodicidadeSelecionada: z.number().optional().nullable(),

		diaUtil: z.string().optional(),

		nome: z.string().min(1, 'Descrição obrigatória'),
		acompanhamento: z.string().optional(),
		fundamentacaoLegal: z.string().optional(),
		datas: z.string().optional(),
		prazoLegal: z.string().optional(),
		temporalidade: z.string().optional(),
		formaCumprimento: z.string().optional(),
		responsavelPrincipal: z.number().min(1, 'Repsonsável principal obrigatório'),
		aprovadorPrincipal: z.number().min(1, 'Aprovador principal obrigatório'),
		responsaveisSelecionados: z.array(z.number()).optional(),
		aprovadoresSelecionados: z.array(z.number()).optional(),
		ehLegal: z.boolean().optional(),
		periodoSelecionado: z.number().nullable().optional(),
		casoNaoUtilSelecionado: z.number().nullable(),
		listaEmailSelecionados: z.array(z.number()).optional(),
		listaEmailSomenteAtrasoSelecionados: z.array(z.number()).optional(),

		//ViewModel
		justificativaAtraso: z.string().nullable().optional(),
		justificativaImplementacao: z.string().nullable().optional(),
		justificativaNaoAprovacao: z.string().optional(),
		pontoMelhoria: z.string().nullable().optional(),
		reaprovada: z.boolean().optional(),
		obrigacaoConcluida: z.boolean().optional(),
		emAtraso: z.boolean().optional(),
		emAtrasoDataFinalSolicitada: z.boolean().optional(),
		podeConcluir: z.boolean().optional(),
		origemSelecionada: z.number().nullable().optional(),
		codigo: z.number().nullable().optional(),

		// Conclusao
		observacao: z.string().nullable().optional(),
		dataConclusaoObrigacao: z.date().nullable().optional(),

		// Cancelamento
		solicitacaoCancelamento: z.boolean().optional().nullable(),
		pedidoDeCancelamentoClicado: z.boolean().optional().nullable(),
		dataHoje: z.date(),

		// Alteração data final
		pedidoAlteracaoDataFinal: z.boolean().nullable().optional(),
		novaDataFinal: z.string().nullable().optional(),
		justificativaDataAlterada: z.string().optional(),
		solicitaAlterarDataFinal: z.boolean().optional().nullable(),
		aprovarAlteracaoDataFinal: z.number().optional().nullable(),
		justificativaNaoAprovacaoDataAlterada: z.string().nullable().optional(),
		dataFinalAnterior: z.string().optional(),
		estadoStatusAlterarData: z.number().optional().nullable(),

		// Aprovação
		aprovada: z.number().optional(),
		JustificativaNaoAprovacao: z.string().optional(),

		// Cancelamento
		statusAprovarCancelamento: z.number().optional(),
		justificativaNaoAprovarCancelamento: z.string().optional(),
		estadoStatusAprovada: z.number().optional(),
		justificativaCancelar: z.string().nullable().optional(),
		bCancelar: z.boolean().nullable().optional(),
		dataCancelamento: z.date().optional().nullable(),

		estadoStatusCancelamento: z.number().optional(),
	})
	.superRefine((data, ctx) => {
		if (data.tipoVencimento === 1 && (data.diaUtil === '' || data.diaUtil === null || data.diaUtil === undefined)) {
			ctx.addIssue({
				path: ['diaUtil'],
				message: 'Dia útil obrigatório',
				code: z.ZodIssueCode.custom,
			});
		}
	});
