import React from 'react';
import { Col, Row, Typography } from '@jcm/design-system';
import { TObrigacao } from '../../../../ts/types/Obrigacoes';
import style from './index.module.scss';
import TruncatedTooltip from '../../../../../../../shared/components/truncatedTextTooltip/TruncatedTextTooltip';

type ConteudoProps = Pick<TObrigacao, 'nome' | 'codigo'> & {
	isSubCard?: boolean;
	className?: string;
};

const Conteudo: React.FC<ConteudoProps> = ({ nome, codigo, isSubCard, className }) => {
	const codigoClasse = isSubCard ? style.codigoObrigacaoSub : style.codigoObrigacao;
	const nomeClasse = isSubCard ? style.nomeObrigacaoSub : style.nomeObrigacao;

	return (
		<Col span={24}>
			<Row justify='start'>
				<Col xs={24} sm={24} md={24} lg={10} xl={2} xxl={2}>
					<Typography.Body className={codigoClasse} strong>
						{codigo}
					</Typography.Body>
				</Col>
				<Col xs={24} sm={24} md={24} lg={10} xl={22} xxl={22}>
					<div
						className={className || style.conteudoWrapper}
						style={{ maxWidth: '100%', overflow: 'hidden', cursor: 'pointer' }}
					>
						<Typography.Body className={nomeClasse}>
							<TruncatedTooltip text={nome}></TruncatedTooltip>
						</Typography.Body>
					</div>
				</Col>
			</Row>
		</Col>
	);
};

export default Conteudo;
