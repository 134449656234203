import BaseApi, { GetByIdFn, GetFn } from '../../../../../../shared/services/base-api.service';
import { TFuncionario } from '../../../ts/types/Funcionarios';
import {
	CriarAnexosResponse,
	CriarObrigacaoPayload,
	CriarObrigacoesResponse,
	EditarAnexosResponse,
	ExcluirAnexosResponse,
	GetObrigacaoResponse,
	ObterListaAnexosResponse,
} from '../../../ts/types/Obrigacoes';
import { TOrigem } from '../../../ts/types/Origem';
import { TApiResponse, TApiResponse2 } from '../../helpers/ApiResponse';
import { getQueryParams } from '../../helpers/queryParams';
import { IModuloObrigacoesApi } from '../interfaces/IObrigacoesApi';
import { GetNovidadeResponse } from '../types/Novidade/Novidade';
import { GetEstatisticasObrigacoesResponse } from '../types/Obrigacoes/EstatisticasObrigacoes';
import { GetLogsObrigacaoResponse } from '../types/Obrigacoes/LogsObrigacao';
import { GetObrigacoesResponse } from '../types/Obrigacoes/Obrigacoes';
import { PartialTFuncionario } from '../types/Obrigacoes/Usuarios';
import { getOrigemResponse } from '../types/Origem/Origem';
import { GetUsuariosResponse } from '../types/Usuarios/Usuarios';

export class ObrigacoesApi extends BaseApi implements IModuloObrigacoesApi {
	public getAllObrigacoes: GetByIdFn<GetObrigacoesResponse> = (
		idEmpresa: number | string,
		options?: Record<string, unknown>,
	) => {
		return this.getRequest<TApiResponse<GetObrigacoesResponse>>(
			`/api/v1/cs/empresas/${idEmpresa}/obrigacoes?${getQueryParams(options)}`,
		);
	};

	public getAllObrigacoesEssenciais: GetByIdFn<GetObrigacoesResponse> = (
		idEmpresa: number | string,
		options?: Record<string, unknown>,
	) => {
		return this.getRequest<TApiResponse<GetObrigacoesResponse>>(
			`/api/v1/cs/empresas/${idEmpresa}/obrigacoes/essenciais?${getQueryParams(options)}`,
		);
	};

	public getUsuarioAutenticado: GetFn<GetUsuariosResponse> = () => {
		return this.getRequest<TApiResponse<GetUsuariosResponse>>(`/api/v1/cs/autenticacao/usuarioAutenticado`);
	};

	public getObrigacaoById = (
		idEmpresa: number | string,
		idObrigacao: number | string,
	): Promise<TApiResponse<GetObrigacaoResponse>> => {
		return this.getRequest<TApiResponse<GetObrigacaoResponse>>(
			`/api/v1/cs/empresas/${idEmpresa}/obrigacoes/${idObrigacao}`,
		);
	};

	public criarObrigacao = (
		idEmpresa: string | number,
		payload: unknown,
	): Promise<TApiResponse2<CriarObrigacoesResponse>> => {
		return this.postRequest<TApiResponse2<CriarObrigacoesResponse>>(
			`/api/v1/cs/empresas/${idEmpresa}/obrigacoes`,
			payload,
		);
	};

	public editarObrigacao = (
		idEmpresa: string | number,
		idObrigacao: number | string,
		payload: CriarObrigacaoPayload,
	): Promise<TApiResponse2<CriarObrigacoesResponse>> => {
		return this.putRequest<CriarObrigacaoPayload, TApiResponse2<CriarObrigacoesResponse>>(
			`/api/v1/cs/empresas/${idEmpresa}/obrigacoes/${idObrigacao}`,
			payload,
		);
	};

	public getMinhasObrigacoes: GetByIdFn<GetObrigacoesResponse> = (
		idEmpresa: number | string,
		options?: Record<string, unknown>,
	) => {
		return this.getRequest<GetObrigacoesResponse>(
			`/api/v1/cs/empresas/${idEmpresa}/obrigacoes/minhas?${getQueryParams(options)}`,
		);
	};

	public getMinhasEstatisticasObrigacoes: GetByIdFn<GetEstatisticasObrigacoesResponse> = (
		idEmpresa: number | string,
		options?: Record<string, unknown>,
	) => {
		return this.getRequest<GetEstatisticasObrigacoesResponse>(
			`/api/v1/cs/empresas/${idEmpresa}/obrigacoes/estatisticas/minhas?${getQueryParams(options)}`,
		);
	};

	public getAllEstatisticasObrigacoesEmpresa: GetByIdFn<GetEstatisticasObrigacoesResponse> = (
		idEmpresa: number | string,
		options?: Record<string, unknown>,
	) => {
		return this.getRequest<GetEstatisticasObrigacoesResponse>(
			`/api/v1/cs/empresas/${idEmpresa}/obrigacoes/estatisticas?${getQueryParams(options)}`,
		);
	};

	public getAllUsuarios: GetByIdFn<GetUsuariosResponse> = (
		idEmpresa: number | string,
		options?: Record<string, unknown>,
	) => {
		return this.getRequest<GetObrigacoesResponse>(
			`/api/v1/cs/empresas/${idEmpresa}/Usuarios?${getQueryParams(options)}`,
		);
	};

	public getAllUsuariosTodos: GetByIdFn<GetUsuariosResponse> = (idEmpresa: number | string) => {
		return this.getRequest<GetUsuariosResponse>(`/api/v1/cs/empresas/${idEmpresa}/usuarios/todos`);
	};

	public criarAnexoObrigacao = async (
		idEmpresa: number | string,
		idObrigacao: number | string,
		formData: FormData,
	): Promise<TApiResponse<CriarAnexosResponse>> => {
		const requestUrl = `/api/v1/cs/empresas/${idEmpresa}/obrigacoes/${idObrigacao}/anexos`;

		const { data } = await this.api.post<TApiResponse<CriarAnexosResponse>>(requestUrl, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		return data;
	};

	public editarAnexoObrigacao = async (
		idEmpresa: number | string,
		idAnexo: number | string,
		formData: FormData,
	): Promise<TApiResponse<EditarAnexosResponse>> => {
		const requestUrl = `/api/v1/cs/empresas/${idEmpresa}/obrigacoes/anexos?IdAnexo=${idAnexo}`;

		const { data } = await this.api.put<TApiResponse<EditarAnexosResponse>>(requestUrl, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		return data;
	};

	public excluirAnexoObrigacao = async (
		idEmpresa: number | string,
		idObrigacao: number | string,
		idAnexo: number | string,
	): Promise<TApiResponse<ExcluirAnexosResponse>> => {
		const requestUrl = `/api/v1/cs/empresas/${idEmpresa}/obrigacoes/${idObrigacao}/anexos?IdAnexo=${idAnexo}`;

		return this.deleteRequest<TApiResponse<ExcluirAnexosResponse>>(requestUrl);
	};

	public getListaAnexos = (
		idEmpresa: number | string,
		idObrigacao: number | string,
	): Promise<TApiResponse2<Array<ObterListaAnexosResponse>>> => {
		return this.getRequest<TApiResponse2<Array<ObterListaAnexosResponse>>>(
			`/api/v1/cs/empresas/${idEmpresa}/obrigacoes/${idObrigacao}/anexos`,
		).then((response) => {
			return response;
		});
	};

	public downloadAnexoObrigacao = async (
		idEmpresa: number | string,
		idAnexo: number | string,
		nomeOriginal: string, // novo parâmetro
	): Promise<void> => {
		const requestUrl = `/api/v1/cs/empresas/${idEmpresa}/obrigacoes/anexos?IdAnexo=${idAnexo}`;

		const response = await this.api.get(requestUrl, {
			responseType: 'blob',
		});

		const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', nomeOriginal);
		document.body.appendChild(link);
		link.click();
		link.remove();
	};

	public getAllOrigem = async (idEmpresa: number | string): Promise<TApiResponse<getOrigemResponse>> => {
		return this.getRequest<TApiResponse<getOrigemResponse>>(`/api/v1/cs/empresas/${idEmpresa}/obrigacoes/origem`).then(
			(response) => {
				return response;
			},
		);
	};

	public criarOrigem = (idEmpresa: string | number, payload: TOrigem): Promise<TApiResponse<getOrigemResponse>> => {
		return this.postRequest<TApiResponse<getOrigemResponse>>(
			`/api/v1/cs/empresas/${idEmpresa}/obrigacoes/origem`,
			payload,
		);
	};

	public atualizarOrigem = (
		idEmpresa: string | number,
		idOrigem: string | number,
		payload: TOrigem,
	): Promise<TApiResponse<getOrigemResponse>> => {
		return this.putRequest<TOrigem, TApiResponse<getOrigemResponse>>(
			`/api/v1/cs/empresas/${idEmpresa}/obrigacoes/origem/${idOrigem}`,
			payload,
		);
	};

	public deletarOrigem = (
		idEmpresa: string | number,
		idOrigem: string | number,
	): Promise<TApiResponse<getOrigemResponse>> => {
		return this.deleteRequest(`/api/v1/cs/empresas/${idEmpresa}/obrigacoes/origem/${idOrigem}`);
	};

	public getEmpresasListagem = (
		idEmpresa: number | string,
	): Promise<TApiResponse2<Array<ObterListaAnexosResponse>>> => {
		return this.getRequest<TApiResponse2<Array<ObterListaAnexosResponse>>>(
			`/api/v1/cs/empresas/${idEmpresa}/empresa/listagem`,
		).then((response) => {
			return response;
		});
	};

	public updateEmpresaAtiva = (
		idEmpresa: number | string,
		payload: { idEmpresa: number; idUsuario: number },
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	): Promise<TApiResponse<any>> => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return this.patchRequest<{ idEmpresa: number; idUsuario: number }, TApiResponse<any>>(
			`/api/v1/cs/empresas/${idEmpresa}/empresa`,
			payload,
		);
	};

	public criarUsuario = (idEmpresa: string | number, payload: unknown): Promise<TApiResponse<TFuncionario>> => {
		return this.postRequest<TApiResponse<TFuncionario>>(`/api/v1/cs/empresas/${idEmpresa}/usuarios`, payload);
	};

	public atualizarUsuario = (
		idEmpresa: string | number,
		idUsuario: number | string,
		payload: PartialTFuncionario,
	): Promise<TApiResponse<PartialTFuncionario>> => {
		return this.putRequest<PartialTFuncionario, TApiResponse<PartialTFuncionario>>(
			`/api/v1/cs/empresas/${idEmpresa}/usuarios/${idUsuario}`,
			payload,
		);
	};

	public async getAllObrigacaoLogs(
		idEmpresa: number | string,
		idObrigacao: number | string,
	): Promise<GetLogsObrigacaoResponse> {
		const response = await this.getRequest<GetLogsObrigacaoResponse>(
			`/api/v1/cs/empresas/${idEmpresa}/logs/${idObrigacao}`,
		);
		return response;
	}

	public async getAllLogs(idEmpresa: number | string): Promise<GetLogsObrigacaoResponse> {
		const response = await this.getRequest<GetLogsObrigacaoResponse>(`/api/v1/cs/empresas/${idEmpresa}/logs`);
		return response;
	}

	public async getAllNovidades(idEmpresa: number | string): Promise<GetNovidadeResponse> {
		const response = await this.getRequest<GetNovidadeResponse>(`/api/v1/cs/empresas/${idEmpresa}/novidades`);
		return response;
	}

	public async editarObrigacoesEmMassa(idEmpresa: string | number, payload: any): Promise<TApiResponse<any>> {
		try {
			const response = await this.putRequest<typeof payload, TApiResponse<any>>(
				`/api/v1/cs/empresas/${idEmpresa}/obrigacoes/operacao`,
				payload,
			);
			return response;
		} catch (error) {
			console.error('Erro ao realizar edição em massa:', error);
			throw error;
		}
	}

	public excluirObrigacoesEmMassa = (
		idEmpresa: number | string,
		payload: { idsObrigacoes: number[] }
	): Promise<TApiResponse<null>> => {
		const requestUrl = `/api/v1/cs/empresas/${idEmpresa}/obrigacoes`;
	
		return this.api.request<TApiResponse<null>>({
			url: requestUrl,
			method: 'DELETE',
			data: payload, 
		}).then(response => response.data); 
	};
	

}
