import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import estilo from './index.module.scss';

interface SessionTimeoutModalProps {
	isVisible: boolean;
	onConfirm: () => void;
	onLogout: () => void;
	timeoutDuration: number;
}

const SessionTimeoutModal: React.FC<SessionTimeoutModalProps> = ({
	isVisible,
	onConfirm,
	onLogout,
	timeoutDuration,
}) => {
	const [tempoRestante, setTempoRestante] = useState(timeoutDuration / 1000);
	// Função para focar a aba e/ou enviar notificação
	const triggerAlert = () => {
		// Tenta focar a janela
		window.focus();

		// Envia notificação se a aba não estiver visível
		if (document.visibilityState === 'hidden' && Notification.permission === 'granted') {
			const notification = new Notification('Sessão prestes a expirar!', {
				body: 'Clique aqui para continuar logado.',
				requireInteraction: true, // Fica até o usuário interagir
			});

			// Foca a janela quando a notificação for clicada
			notification.onclick = () => {
				window.focus();
			};
		}
	};

	useEffect(() => {
		if (!isVisible) return;
		triggerAlert();
		const interval = setInterval(() => {
			setTempoRestante((prevTime) => {
				if (prevTime <= 1) {
					clearInterval(interval);
					onLogout();
					return 0;
				}
				return prevTime - 1;
			});
		}, 1000);

		return () => clearInterval(interval);
	}, [isVisible, onLogout]);

	useEffect(() => {
		if (Notification.permission !== 'granted') {
			Notification.requestPermission();
		}
	}, []);

	if (!isVisible) {
		return null;
	}

	return (
		<Modal centered open={isVisible} footer={null} closable={false}>
			<h2>Você ainda está aqui?</h2>
			<p>Sua sessão vai expirar em {tempoRestante} segundos. Clique em &quot;Sim&quot; para continuar logado.</p>

			<Button className={estilo.botaoConfirmar} onClick={onConfirm}>
				Sim, estou aqui
			</Button>
		</Modal>
	);
};

export default SessionTimeoutModal;
