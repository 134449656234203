import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Grid, IconButton, InputBase } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import styles from './index.module.scss';
import AvatarGroupComponent from '../../AvatarGroupComponent/AvatarGroupComponent';
import TruncatedTextTooltip from '../../../../../../../../shared/components/truncatedTextTooltip/TruncatedTextTooltip';
import ObrigacoesService from '../../../../../services/Obrigacoes/Obrigacoes.service';
import { Anexo } from '../../../../../ts/types/Obrigacoes';
import { Icons } from '@jcm/design-system';
import { useGovernancaContext } from '../../../../../../../../context/GovernancaContext';
import CustomAlert from '../../../../../../../../shared/components/customAlert/customAlert';
import PopConfirmButton from '../../../../../../../../shared/components/popConfirmButton/popConfirmButton';

interface ListaAnexosProps {
	anexos: Anexo[];
	onDelete: (index: number) => void;
	onEdit: (index: number, descricao: string) => void;
	idObrigacao: number;
	atualizarAnexos: () => void;
	disabled: boolean;
}

const ListaAnexos: React.FC<ListaAnexosProps> = ({
	anexos,
	onDelete,
	onEdit,
	idObrigacao,
	atualizarAnexos,
	disabled,
}) => {
	const { usuario } = useGovernancaContext();
	const [editIndex, setEditIndex] = useState<number | null>(null);
	const [newDescricao, setNewDescricao] = useState<string>('');
	const obrigacoesService = new ObrigacoesService();
	const wrapperRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
				handleCancelEdit();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleEditClick = (index: number, descricao: string) => {
		if (!disabled) {
			setEditIndex(index);
			setNewDescricao(descricao);
		}
	};

	const [alert, setAlert] = useState<{ type: 'success' | 'error'; content: string; title?: string } | null>(null);

	useEffect(() => {
		if (alert) {
			const timer = setTimeout(() => setAlert(null), 1000);
			return () => clearTimeout(timer);
		}
	}, [alert]);

	const handleSaveEdit = async () => {
		if (editIndex !== null) {
			if (!newDescricao || newDescricao.trim() === '') {
				console.error('A descrição não pode estar vazia.');
				return;
			}

			try {
				const anexo = anexos[editIndex];
				const empresaId = handleGetUserEmpresaId();

				await obrigacoesService.editarAnexoObrigacao(empresaId || '', anexo.id, newDescricao);
				onEdit(editIndex, newDescricao);

				setEditIndex(null);
				setNewDescricao('');
				await atualizarAnexos();
			} catch (error) {
				console.error('Erro ao editar o anexo. Tente novamente.');
			}
		}
	};

	const handleDelete = async (index: number) => {
		try {
			const anexo = anexos[index];
			const empresaId = handleGetUserEmpresaId();

			await obrigacoesService.excluirAnexoObrigacao(empresaId || '', idObrigacao || '', anexo.id);
			onDelete(index);
			setAlert({ type: 'success', content: 'Anexo excluído com sucesso!', title: 'Sucesso' });
			await atualizarAnexos();
		} catch (error) {
			setAlert({ type: 'error', content: 'Erro ao excluir o anexo. Tente novamente.', title: 'Erro' });
		}
	};

	const handleGetUserEmpresaId = () => {
		return usuario?.participacaoEmpresa?.[0]?.empresaId || usuario?.empresaSalvaId;
	};

	const handleCancelEdit = () => {
		setEditIndex(null);
		setNewDescricao('');
	};

	const renderAvatars = (nome: string) => {
		return [{ id: 1, name: nome.charAt(0), nomeCompleto: nome }];
	};

	const handleDownload = async (anexoId: number, empresaSalvaId: number | undefined, nomeOriginal: string) => {
		try {
			if (!empresaSalvaId) {
				setAlert({ type: 'error', content: 'ID da empresa não encontrado.', title: 'Erro' });
				return;
			}

			await obrigacoesService.downloadAnexoObrigacao(empresaSalvaId, anexoId, nomeOriginal);
		} catch (error) {
			setAlert({ type: 'error', content: 'Erro ao baixar o anexo. Tente novamente.', title: 'Erro' });
		}
	};

	return (
		<Box className={styles.listaAnexosContainer}>
			<>
				<Grid container spacing={2} className={styles.headerRow}>
					<Grid item xs={4}>
						<Typography variant='subtitle2'>Arquivo</Typography>
					</Grid>
					<Grid item xs={3}>
						<Typography variant='subtitle2'>Descrição</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography variant='subtitle2'>Data</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography variant='subtitle2'>Autor</Typography>
					</Grid>
					<Grid item xs={1}>
						<Typography variant='subtitle2'>Excluir</Typography>
					</Grid>
				</Grid>

				{anexos.length === 0 ? (
					<Typography variant='body2' className={styles.noAnexosText}>
						Nenhum arquivo anexado
					</Typography>
				) : (
					anexos.map((anexo, index) => (
						<Grid container spacing={1} key={index} className={styles.anexoRow}>
							<Grid item xs={4}>
								<Typography
									variant='body2'
									onClick={() => handleDownload(anexo.id, handleGetUserEmpresaId(), anexo.nomeOriginal)}
									className={styles.typographyClickable}
									style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
								>
									<TruncatedTextTooltip className='anexo-pointer' text={anexo.nomeOriginal} maxWidth='200px' />
								</Typography>
							</Grid>

							<Grid item xs={3} className={styles.descricaoCell}>
								{editIndex === index ? (
									<div className={styles.descricaoWrapper} ref={wrapperRef}>
										<InputBase
											value={newDescricao}
											onChange={(e) => setNewDescricao(e.target.value)}
											autoFocus
											multiline
											className={styles.descricaoInput}
											fullWidth
											disabled={disabled}
										/>
										<IconButton
											onClick={handleSaveEdit}
											size='small'
											className={styles.descricaoBotaoCheck}
											disabled={disabled}
										>
											<CheckIcon fontSize='small' />
										</IconButton>
										<IconButton
											onClick={handleCancelEdit}
											size='small'
											className={styles.descricaoBotaoNoCheck}
											disabled={disabled}
										>
											<CloseIcon fontSize='small' />
										</IconButton>
									</div>
								) : (
									<div className={styles.descricaoTextoWrapper}>
										<Typography
											variant='body2'
											onClick={() => handleEditClick(index, anexo.descricao)}
											className={styles.descricaoTexto}
											style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
										>
											{anexo.descricao}
										</Typography>
										{!disabled && <Icons.Edit className={styles.hoverIcon} style={{ fontSize: '16px' }} />}{' '}
									</div>
								)}
							</Grid>
							<Grid item xs={2}>
								<Typography variant='body2'>{anexo.dataCriacao}</Typography>
							</Grid>
							<Grid item xs={2}>
								<AvatarGroupComponent
									title=''
									members={renderAvatars(anexo.funcionario?.nome || '')}
									color='#2F2E2E'
									bgColor='#80D4D5'
								/>
							</Grid>
							<Grid item xs={1}>
								<PopConfirmButton
									onConfirm={() => handleDelete(index)}
									title='Você tem certeza que deseja excluir?'
									okText='Sim, excluir!'
									cancelText='Cancelar'
									buttonText={<Icons.Delete style={{ fontSize: '22px' }} />}
									disabled={disabled}
								/>
							</Grid>
						</Grid>
					))
				)}
			</>
			{alert && <CustomAlert type={alert.type} content={alert.content} title={alert.title} />}
		</Box>
	);
};

export default ListaAnexos;
