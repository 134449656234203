import React from 'react';
import { Progress } from 'antd';
import styles from './BarraDeProgresso.module.scss';

interface BarraDeProgressoProps {
	progresso: number;
	status?: 'active' | 'exception' | 'normal' | 'success';
	tempoEstimado?: number;
	cor?: string;
}

const BarraDeProgresso: React.FC<BarraDeProgressoProps> = ({
	progresso,
	status = 'active',
	tempoEstimado,
	cor = '#1890ff',
}) => {
	const formatarTempo = (segundos: number) => {
		const minutos = Math.floor(segundos / 60);
		const segundosRestantes = Math.floor(segundos % 60);
		return `${minutos}m ${segundosRestantes}s`;
	};

	return (
		<div className={styles.barraDeProgressoContainer}>
			<Progress
				percent={Math.round(progresso)}
				status={status}
				strokeWidth={10}
				strokeColor={cor}
				showInfo
				type='circle'
			/>
			{tempoEstimado !== undefined && (
				<div className={styles.tempoEstimado}>Estimado: {formatarTempo(tempoEstimado)}</div>
			)}
		</div>
	);
};

export default BarraDeProgresso;
