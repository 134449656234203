import { Modal, Row, Space } from 'antd';
import { Button, Icons, Themes, Typography } from '@jcm/design-system';
import Keycloak, { KeycloakConfig, KeycloakLoginOptions, KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
// import { useInterval } from 'usehooks-ts';

import { ThemeContextProvider } from './ThemeContext';
import { AppConfigModuloObrigacoes } from '../modules/modulo-obrigacoes/configs/config';
import SessionTimeoutModal from './../shared/components/fimDeSessaoModal/modalFimSessao';

export interface IAuthUser {
	sub: string;
	preferred_username: string;

	email?: string;
	email_verified?: boolean;

	name?: string;
	given_name: string;
	family_name: string;
	funcionarioId?: number;
}

export interface IAuthProfile extends KeycloakProfile {}

export interface IKeycloakContext {
	isLoading: boolean;
	isAuthenticated?: boolean;

	user?: IAuthUser;
	profile?: IAuthProfile;
	idToken?: string;
	accessToken?: string;
	tokenExpires?: Date;
	tokenParsed?: KeycloakTokenParsed;
	isAdmin?: boolean | null;

	login: (options?: KeycloakLoginOptions) => Promise<void>;
	logout: () => Promise<void>;
	verificarExpiracaoSessao: () => void;
}

// eslint-disable-next-line
const KeycloakContext = createContext<IKeycloakContext>(undefined!);

export const useKeycloakContext = () => useContext(KeycloakContext);

type ModalSessaoExpiradaProps = { isOpen: boolean; onOk: () => void };
const ModalSessaoExpirada: React.FC<ModalSessaoExpiradaProps> = ({ isOpen, onOk }) => {
	return (
		// Modal não acompanha o tema da página
		<ThemeContextProvider overrides={{ dark: Themes.getLightTheme() }}>
			<Modal
				open={isOpen}
				onOk={onOk}
				closable={false}
				maskClosable={false}
				keyboard={false}
				title={
					<Space>
						<Typography.Title variant='error' size='large'>
							<Icons.Error variant='outlined' />
						</Typography.Title>
						<Typography.Title variant='default' size='medium'>
							Sua sessão expirou!
						</Typography.Title>
					</Space>
				}
				footer={
					<Row style={{ justifyContent: 'end' }}>
						<Button variant='default' type='filled' onClick={onOk}>
							Atualizar
						</Button>
					</Row>
				}
				children={
					<Typography.Body size='medium' variant='default' style={{ marginLeft: '1rem' }}>
						Por favor, atualize a página para continuar.
					</Typography.Body>
				}
			/>
		</ThemeContextProvider>
	);
};

type Props = { children: React.ReactNode; admin?: boolean; isProduction?: boolean; clientId: string };
export const KeycloakContextProvider: React.FC<Props> = ({ children, admin, isProduction, clientId }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [expiracaoSessao, setExpiracaoSessao] = useState(new Date());
	const [modalVisivel, setModalVisivel] = useState(false);
	const [ultimaAcaoUsuario, setUltimaAcaoUsuario] = useState<Date>(new Date());

	const registrarAcaoUsuario = () => {
		const now = new Date();
		setUltimaAcaoUsuario(now);
	};

	const keycloakConfig = useMemo(() => {
		const { keycloakConfig } = AppConfigModuloObrigacoes;

		const config: KeycloakConfig = {
			url: keycloakConfig.url,
			realm: keycloakConfig.realm,
			clientId: clientId,
		};

		return config;
	}, [admin, isProduction]);

	const [isLoading, setIsLoading] = useState(true);
	const [user, setUser] = useState<IAuthUser>();
	const [profile, setProfile] = useState<IAuthProfile>();
	// const [isAdmin, setIsAdmin] = useState<boolean | null>(null);

	const keycloak = useMemo(() => new Keycloak(keycloakConfig), [keycloakConfig]);
	const isAuthenticated = keycloak.authenticated;
	const idToken = keycloak.idToken;
	const accessToken = keycloak.token;
	const tokenParsed = keycloak.tokenParsed;

	const logout = useCallback(() => {
		setIsLoading(true);
		return keycloak.logout();
	}, [keycloak]);

	const login = useCallback((options?: KeycloakLoginOptions) => keycloak.login(options), [keycloak]);

	// Inicializa o keycloak
	const keycloakInit = useCallback(async () => {
		await keycloak
			.init({
				onLoad: 'login-required',
				checkLoginIframe: true,
				silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
			})
			.then(() => {
				atualizarExpiracaoSessao(keycloak);
				if (Notification.permission !== 'granted') {
					Notification.requestPermission();
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [keycloak]);

	useEffect(() => {
		keycloakInit();
	}, [keycloakInit]);

	useEffect(() => {
		if (!isLoading) return;

		keycloakInit().catch((err) => console.error(`AuthContext: ${err?.message}`));
	}, [keycloakInit, isLoading]);

	// Carrega info do usuário
	const keycloakLoadUserInfo = useCallback(async () => {
		await keycloak.loadUserInfo().then((info) => setUser(info as IAuthUser));
	}, [keycloak]);

	useEffect(() => {
		if (isLoading || !isAuthenticated) return;

		keycloakLoadUserInfo().catch((err) => console.error(err));
	}, [keycloakLoadUserInfo, isLoading, isAuthenticated]);

	// Carrega perfil do usuário
	const keycloakLoadUserProfile = useCallback(async () => {
		await keycloak.loadUserProfile().then((profile) => setProfile(profile));
	}, [keycloak]);

	useEffect(() => {
		if (isLoading) return;

		keycloakLoadUserProfile().catch((err) => console.error(err));
	}, [keycloakLoadUserProfile, isLoading]);

	const atualizarExpiracaoSessao = (instance: Keycloak.KeycloakTokenParsed) => {
		const expirationTime = new Date(instance.tokenParsed?.exp * 1000);
		setExpiracaoSessao(expirationTime);
	};

	const atualizaToken = async () => {
		if (keycloak) {
			try {
				await keycloak.updateToken(-1); // -1 para atualizar no momento que fizer a requisicao
				atualizarExpiracaoSessao(keycloak);
			} catch (error) {
				console.error('Failed to refresh token', error);
			}
		}
	};

	useEffect(() => {
		const handleUserAction = () => {
			registrarAcaoUsuario();
		};

		document.addEventListener('click', handleUserAction);
		document.addEventListener('keydown', handleUserAction);

		return () => {
			document.removeEventListener('click', handleUserAction);
			document.removeEventListener('keydown', handleUserAction);
		};
	}, []);

	const confirmar  = async () => {
		try {
			const refreshed = await keycloak.updateToken(-1);
			if (refreshed) {
				atualizarExpiracaoSessao(keycloak);
			} else {
				console.log('Token ainda é válido, nenhuma atualização foi feita');
			}
			setModalVisivel(false);
		} catch (error) {
			console.error('Falha ao atualizar o token', error);
		}
	};

	const fazerLogout = () => {
		setModalVisivel(false);
		keycloak.logout();
	};

	useEffect(() => {
		const interval = setInterval(() => {
			const now = new Date();
			const timeUntilExpiration = expiracaoSessao.getTime() - now.getTime();

			if (timeUntilExpiration <= 60000 && timeUntilExpiration > 0) {
				const diferencaTempo = (now.getTime() - ultimaAcaoUsuario.getTime()) / 60000;
				if (diferencaTempo <= 5) {
					atualizaToken();
				} else {
					setModalVisivel(true);
				}
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [expiracaoSessao]);

	// Checa a sessão
	const verificarExpiracaoSessao = useCallback(() => setIsModalOpen(keycloak.isTokenExpired()), [keycloak]);

	const context = useMemo<IKeycloakContext>(() => {
		return {
			user,
			profile,
			isAuthenticated,
			isLoading,
			idToken,
			accessToken,
			tokenParsed,
			login,
			logout,
			verificarExpiracaoSessao,
		};
	}, [
		user,
		profile,
		isAuthenticated,
		isLoading,
		idToken,
		accessToken,
		tokenParsed,
		login,
		logout,
		verificarExpiracaoSessao,
	]);

	return (
		<KeycloakContext.Provider value={context}>
			{isLoading ? null : (
				<>
					<ModalSessaoExpirada isOpen={isModalOpen} onOk={() => keycloak.login()} />
					{children}
					<SessionTimeoutModal
						isVisible={modalVisivel}
						onConfirm={confirmar}
						onLogout={fazerLogout}
						timeoutDuration={60000}
					/>
				</>
			)}
		</KeycloakContext.Provider>
	);
};
