/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import styles from './ModalConcluirObrigacao.module.scss';
import ModalContainer from '../../modalContainer/ModalContainer';
import { Control, Controller, UseFormGetValues, UseFormSetValue, UseFormUnregister, useWatch } from 'react-hook-form';
import { Button, Checkbox, Flex, Typography } from '@jcm/design-system';
import dayjs from 'dayjs';
import { Input } from 'antd';

const { TextArea } = Input;
interface ModalConcluirObrigacaoProps {
	isVisible: boolean;
	onClose: () => void;
	setValue: UseFormSetValue<any>;
	getValues: UseFormGetValues<any>;
	control: Control<any>;
	unregister: UseFormUnregister<any>;
	usuarioAprovador: boolean;
	usuarioResponsavel: boolean;
	handleSubmit: (data: any) => void;
}

const ModalConcluirObrigacao: React.FC<ModalConcluirObrigacaoProps> = ({
	isVisible,
	onClose,
	control,
	getValues,
	setValue,
	unregister,
	usuarioAprovador,
	usuarioResponsavel,
	handleSubmit,
}) => {
	const [estadoStatusAprovada, setEstadoStatusAprovada] = useState(null);
	const aprovada = useWatch({
		control,
		name: 'aprovada',
	});
	const justificativaNaoAprovacao = useWatch({
		control,
		name: 'justificativaNaoAprovacao',
	});
	const justificativaAtraso = useWatch({
		control,
		name: 'justificativaAtraso',
	});

	useEffect(() => {
		setEstadoStatusAprovada(getValues('estadoStatusAprovada'));
	}, [getValues]);

	function dataAtrasada() {
		const dataConclusao = getValues('dataConclusaoObrigacao');
		const dataVencimento = getValues('dataVencimento');

		if (!dataConclusao || !dataVencimento) {
			return false;
		}

		return dayjs(dataConclusao).isAfter(dayjs(dataVencimento), 'day');
	}

	return (
		<ModalContainer
			title='Concluir Obrigação'
			isVisible={isVisible}
			onClose={onClose}
			className={styles.modalConcluirObrigacaoForm}
		>
			{dataAtrasada() && (
				<Controller
					name='justificativaAtraso'
					control={control}
					render={({ field }) => (
						<Flex vertical gap={2}>
							<Typography.Body>
								Justificativa para o atraso da conclusão <span style={{ color: 'red' }}>*</span>
							</Typography.Body>

							<TextArea
								placeholder='Justificativa'
								disabled={!usuarioResponsavel}
								{...field}
								allowClear
								variant='filled'
							/>
						</Flex>
					)}
				/>
			)}

			<Controller
				name='observacao'
				control={control}
				render={({ field }) => (
					<Flex vertical gap={2}>
						<Typography.Body>Observação</Typography.Body>

						<TextArea {...field} disabled={!usuarioResponsavel} placeholder='Observação' allowClear variant='filled' />
					</Flex>
				)}
			/>
			{usuarioAprovador &&
				(!usuarioResponsavel || (usuarioAprovador && usuarioResponsavel && estadoStatusAprovada === 1)) && (
					<>
						<Controller
							name='aprovada'
							control={control}
							render={({ field }) => (
								<Flex gap={16}>
									<Flex gap={4}>
										<Checkbox
											type='checkbox'
											checked={field.value === 1}
											onChange={() => {
												setValue('aprovada', field.value === 1 ? null : 1);
												setValue('estadoStatusAprovada', 2);
											}} // 1 Status Aprovada
										/>
										<Typography.Body>Aprovar</Typography.Body>
									</Flex>
									<Flex gap={4}>
										<Checkbox
											type='checkbox'
											checked={field.value === 2}
											onChange={() => {
												setValue('aprovada', field.value === 2 ? null : 2);
												setValue('estadoStatusAprovada', 3);
											}} // 2 Status Reprovada
										/>
										<Typography.Body>Reprovar</Typography.Body>
									</Flex>
								</Flex>
							)}
						/>

						{aprovada === 2 && (
							<Controller
								name='justificativaNaoAprovacao'
								control={control}
								render={({ field }) => (
									<Flex vertical gap={2}>
										<Typography.Body>
											Justificativa de Reprovação <span style={{ color: 'red' }}>*</span>
										</Typography.Body>
										<TextArea {...field} placeholder='Justificativa de Reprovação' allowClear variant='filled' />
									</Flex>
								)}
							/>
						)}
					</>
				)}

			<Flex style={{ gap: '1rem', justifySelf: 'center' }}>
				<Button
					type='filled-tonal'
					variant='error'
					onClick={() => {
						unregister('aprovada');
						unregister('estadoStatusAprovada');
						unregister('obrigacaoConcluida');
						unregister('justificativaAtraso');
						unregister('observacao');

						onClose();
					}}
					className={styles.cancelButton}
				>
					Cancelar
				</Button>
				<Button
					type='filled-tonal'
					variant='default'
					className={styles.approveButton}
					disabled={
						(usuarioAprovador && aprovada !== 1 && !usuarioResponsavel && !justificativaNaoAprovacao?.trim()) ||
						(dataAtrasada() && usuarioResponsavel && !justificativaAtraso?.trim())
					}
					onClick={() => {
						onClose();

						if (
							usuarioResponsavel &&
							usuarioAprovador &&
							(getValues('estadoStatusAprovada') === 2 || getValues('estadoStatusAprovada') === 0)
						) {
							setValue('aprovada', 1);
						}

						setValue('obrigacaoConcluida', true);
						handleSubmit(getValues());
					}}
				>
					{usuarioResponsavel && usuarioAprovador
						? 'Concluir obrigação e salvar'
						: usuarioResponsavel
							? 'Solicitar conclusão e salvar'
							: usuarioAprovador
								? 'Responder conclusão e salvar'
								: 'Solicitar conclusão e salvar'}
				</Button>
			</Flex>
		</ModalContainer>
	);
};

export default ModalConcluirObrigacao;
