import { Outlet } from 'react-router-dom';
import { DrawerBarItem, Icons, useTheme } from '@jcm/design-system';
import usePermissao, { useNavigateWithSearchParams } from '../../hooks';
import { SearchIconButton } from '../../components/inputs/SearchIconButton/SearchIconButton';
import { UserAvatar } from '../../components/icons/AvatarUsuario/UserAvatar';
import { NotificationsIconButton } from '../../components/icons/IconeNotificacao';
import { Popover, Layout, Menu, Select } from 'antd';
import { useState } from 'react';
import * as logos from '../../assets/images/logos';
import './layout.css';
import FreshdeskWidget from '../../../../../shared/components/freshdeskWidget/FreshdeskWidget';
import { useGovernancaContext } from '../../../../../context/GovernancaContext';
import Lottie from 'lottie-react';
import LoadingListaObrigacoes from '../../../../../assets/loadings/CarregandoListaObrigacoes.json';
import { Permissoes } from '../../ts/enums/PermissoesEnum';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

export default function ModuloObrigacaoAppLayout({ children }: { children?: React.ReactNode }) {
	const navigateWithSearchParams = useNavigateWithSearchParams();
	const [collapsed, setCollapsed] = useState(false);
	const theme = useTheme();
	const { empresas, empresaAtiva, setEmpresaAtiva } = useGovernancaContext();
	const temPermissaoAdministrador = usePermissao([Permissoes.AdministradorEmpresa, Permissoes.AdministradorSistema]);

	// Checar se as empresas estão carregadas antes de renderizar o select
	if (!empresas || empresas.length === 0) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
				<Lottie loop animationData={LoadingListaObrigacoes} style={{ width: '30%', height: '50%' }} />
			</div>
		);
	}

	const drawerBarItems: DrawerBarItem[] = [
		{
			label: (
				<Popover placement='right' content={'Em desenvolvimento'}>
					Principal
				</Popover>
			),
			key: `principal`,
			icon: <Icons.Home variant='outlined' />,
			disabled: true,
		},
		{
			label: 'Obrigações',
			key: `listagem`,
			icon: <Icons.ContractEdit variant='outlined' />,
		},
		{
			label: 'Gerenciar obrigações',
			key: `areaGerenciarObrigacoes`,
			icon: <Icons.BackupTable variant='outlined' />,
			children: [
				{
					label: (
						<Popover placement='right' content={'Em desenvolvimento'}>
							Calendário Abrapp
						</Popover>
					),
					key: `calendarioObrigacoes`,
					icon: <Icons.CalendarMonth variant='outlined' />,
					disabled: true,
				},
				{
					label: 'Origem',
					key: `origem`,
					icon: <Icons.ReceiptLong variant='outlined' />,
				},
				{
					label: 'Operações em massa', 
					key: `operacoes-em-massa`, 
					icon: <Icons.Edit variant='outlined' />,
				},
			],
		},
	];

	if (temPermissaoAdministrador) {
		drawerBarItems.push(
			{
				label: 'Administração',
				key: `areaAdm`,
				icon: <Icons.Badge variant='outlined' />,
				children: [
					{
						label: 'Usuários',
						key: `usuarios`,
						icon: <Icons.Person variant='outlined' />,
					},
					{
					label: 'Registro de atividades',
					key: `registros-atividades`,
					icon: <PendingActionsIcon />,
				},
			],
			},
			{
				label: <Popover placement='right'>Relatórios</Popover>,
				key: `relatorios`,
				icon: <Icons.BrowseActivity variant='outlined' />,
			},
		);
	}

	const { Header, Content, Sider } = Layout;

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Sider collapsible collapsed={collapsed} onCollapse={setCollapsed} width='250px'>
				<div
					className='logo'
					style={{ justifyItems: 'center', display: 'grid', height: '100px', alignItems: 'center' }}
				>
					<img
						className='col'
						src={collapsed ? logos.logoAbrappMini : logos.logoAbrappCor}
						alt='Logo'
						style={{ width: collapsed ? 40 : 180, transition: 'width 0.2s', margin: '1.25rem', justifySelf: 'center' }}
					/>
				</div>
				<Menu
					theme='light'
					defaultSelectedKeys={['obrigacoes']}
					mode='inline'
					items={drawerBarItems}
					onClick={({ key }) => navigateWithSearchParams(key)}
					style={{ backgroundColor: theme.colors.surface, color: 'black !important' }}
				/>
				<div className='logoIso'>
					<img
						src={collapsed ? logos.logoJcmMini : logos.logoByJcm}
						alt='Logo Abrapp'
						style={{ width: collapsed ? 50 : 80 }}
					/>
				</div>
			</Sider>
			<Layout className='site-layout'>
				<Header style={{ padding: 0, background: '#fff' }}>
					<div className='siteLayout'>
						<div className='cabecalhoMenu'>
							<Select
								style={{ width: 200 }}
								value={empresaAtiva}
								onChange={(value) => {
									try {
										setEmpresaAtiva(value); // Envia a requisição ao contexto
									} catch (error) {
										console.error('Erro ao atualizar empresa ativa:', error);
									}
								}}
								options={empresas.map((empresa) => ({
									label: empresa.nome,
									value: empresa.id,
								}))}
								placeholder='Selecione uma empresa'
							/>
							<div className='iconsRight'>
								<SearchIconButton />
								<NotificationsIconButton />
								<UserAvatar />
							</div>
						</div>
					</div>
				</Header>

				<Content style={{ margin: '16px', marginLeft: collapsed ? 106 : 276, transition: 'margin-left 0.2s' }}>
					{children ?? <Outlet />}
				</Content>
			</Layout>
			<div className='App'>
				<FreshdeskWidget />
			</div>
		</Layout>
	);
}
