import { NavigateOptions, useNavigate, useSearchParams } from 'react-router-dom';
import { useGovernancaContext } from '../../../../context/GovernancaContext';
import { Permissoes } from '../ts/enums/PermissoesEnum';
import { useEffect, useState } from 'react';

/**
 * Retorna uma função de navegação, que mantém todos os searchParams da URL
 */
export const useNavigateWithSearchParams = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	return (to: string, options?: NavigateOptions) => {
		const params: string[] = [];
		searchParams.forEach((value, key) => params.push(`${key}=${value}`));
		const path = `${to}?${params.join('&')}`;

		return navigate(path, options);
	};
};

/**
 * Retorna uma função de permissão do usuário
 */
export const usePermissao = (permissoesNecessarias: Permissoes[]): boolean => {
	const { usuario, carregando } = useGovernancaContext();
	const [temPermissao, setTemPermissao] = useState<boolean>(false);

	useEffect(() => {
		if (!carregando && usuario) {
			setTemPermissao(permissoesNecessarias.includes(usuario.funcao));
		}
	}, [carregando, usuario, temPermissao]);

	if (carregando) {
		return false;
	}

	return temPermissao;
};

export default usePermissao;
