import React from 'react';
import { Card, Flex, Typography } from '@jcm/design-system';
import styles from './index.module.scss';

type TotalObrigacoesCardProps = {
	total: number;
	legais: number;
	proprias: number;
};

const TotalObrigacoesCard: React.FC<TotalObrigacoesCardProps> = ({ total, legais, proprias }) => {
	return (
		<Card className={styles.cartaoTotalObrigacoes}>
			<Flex className={styles.containerTotalObrigacoes} vertical align='flex-start' gap={12}>
				<Flex className={styles.obrigacoesEncontradas} gap={6}>
					<span style={{ marginTop: total > 999 ? '1px' : '' }}>
						<Typography.Title size={total > 999 ? 'medium' : 'large'} strong>
							{total}
						</Typography.Title>
					</span>
					<Typography.Title size='small' style={{ marginTop: '3px' }}>
						Obrigações encontradas
					</Typography.Title>
				</Flex>

				<Flex align='center' justify='center' gap={16}>
					<label>
						<Flex gap={3} align='center'>
							<span>
								<Typography.Title style={{ marginLeft: '4px' }} size='medium' strong>
									{legais}
								</Typography.Title>
							</span>
							<Typography.Body style={{ marginLeft: '4px' }} size='small'>
								Legais
							</Typography.Body>
						</Flex>
					</label>
					<label>
						<Flex gap={3} align='center'>
							<span>
								<Typography.Title size='medium' strong>
									{proprias}
								</Typography.Title>
							</span>
							<Typography.Body style={{ marginLeft: '4px' }} size='small'>
								Próprias
							</Typography.Body>
						</Flex>
					</label>
				</Flex>
			</Flex>
		</Card>
	);
};

export default TotalObrigacoesCard;
