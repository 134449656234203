import React, { useRef, useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import 'antd/dist/reset.css';
import styles from './TruncatedTextTooltip.module.scss';

interface TruncatedTextTooltipProps {
	text: string;
	style?: React.CSSProperties;
	maxWidth?: string;
	className?: string;
	[key: string]: unknown;
}

const TruncatedTextTooltip: React.FC<TruncatedTextTooltipProps> = ({
	text,
	style,
	maxWidth = '100%',
	className,
	...props
}) => {
	const textRef = useRef<HTMLSpanElement>(null);
	const [isTruncated, setIsTruncated] = useState(false);
	const [hoveringEllipsis, setHoveringEllipsis] = useState(false);

	const checkTruncation = () => {
		const element = textRef.current;
		if (element) {
			setIsTruncated(element.scrollWidth > element.clientWidth);
		}
	};

	useEffect(() => {
		checkTruncation();
		window.addEventListener('resize', checkTruncation);
		return () => {
			window.removeEventListener('resize', checkTruncation);
		};
	}, [text]);

	const handleMouseEnterEllipsis = () => {
		setHoveringEllipsis(true);
	};

	const handleMouseLeaveEllipsis = () => {
		setHoveringEllipsis(false);
	};

	return (
		<span className={`${styles.truncatedContainer} ${className}`} style={{ maxWidth: maxWidth, ...style }}>
			<span
				ref={textRef}
				className={`${styles.textContent} ${isTruncated ? '' : ''}`}
				style={{
					textOverflow: isTruncated ? 'clip' : 'unset',
					maxWidth: maxWidth,
				}}
			>
				{text}
			</span>
			{isTruncated && (
				<Tooltip
					title={text}
					color='rgba(0, 0, 0, 0.7)'
					overlayClassName={styles.tooltipOverlay}
					open={hoveringEllipsis ? undefined : false}
					{...props}
				>
					<span
						onMouseEnter={handleMouseEnterEllipsis}
						onMouseLeave={handleMouseLeaveEllipsis}
						className={styles.pointerCursor}
					>
						...
					</span>
				</Tooltip>
			)}
		</span>
	);
};

export default TruncatedTextTooltip;
