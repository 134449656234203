import { Popover } from 'antd';
import { Button, Card, Flex, Icons, SearchBar, Typography, useTheme } from '@jcm/design-system';
import { memo, useEffect, useState } from 'react';
import styles from './index.module.scss';
import './index.css';

type SearchPopoverProps = { children: React.ReactNode; isOpen: boolean; onOpenChange: (newValue: boolean) => void };
const SearchPopover = memo<SearchPopoverProps>(({ children, isOpen, onOpenChange }) => {
	const { colors, shapes } = useTheme();

	const [pesquisa, setPesquisa] = useState('');
	const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
	const [resultados, setResultados] = useState<string[]>([]);

	useEffect(() => {
		const timeout = setTimeout(() => setIsSearchBarOpen(isOpen), 250);
		return () => clearTimeout(timeout);
	}, [isOpen]);

	useEffect(() => {
		setResultados(() => {
			const resultado: string[] = [];
			if (pesquisa === '') return resultado;
			for (let i = 1; i <= pesquisa.length; i++) resultado.push('Resultado ' + i);
			return resultado;
		});
	}, [pesquisa]);

	const content = (
		<Flex gap={shapes.sizes.medium} vertical>
			<Flex gap={shapes.sizes.small} style={{ width: '100%', minWidth: '400px' }} vertical>
				<Card
					id='popover-seach-bar-card'
					className={`${styles.searchBarCard} ${isSearchBarOpen || pesquisa !== '' ? styles.isOpen : ''}`}
					variant='default'
					style={{}}
				>
					<Flex justify='center'>
						<SearchBar
							className={`${styles.searchBar} ${isSearchBarOpen || pesquisa !== '' ? styles.isOpen : ''}`}
							variant='outlined'
							placeholder='Pesquisa...'
							allowClear
							value={pesquisa}
							onChange={(e) => setPesquisa(e.target.value)}
						/>
					</Flex>
				</Card>

				{resultados.length > 0 && (
					<Card
						id='popover-seach-bar-card'
						variant='default'
						style={{
							maxHeight: '50vh',
							overflowX: 'auto',
						}}
					>
						<Flex gap={shapes.sizes.small} vertical>
							{resultados.map((resultado) => (
								<Typography.Body key={resultado} variant='default' size='medium'>
									{resultado}
								</Typography.Body>
							))}
						</Flex>
					</Card>
				)}
			</Flex>
		</Flex>
	);

	return (
		<Popover
			overlayClassName={styles.popover}
			color={colors.secondaryContainer}
			placement='bottomLeft'
			trigger='click'
			arrow={true}
			content={content}
			open={isOpen}
			onOpenChange={onOpenChange}
			destroyTooltipOnHide
		>
			{children}
		</Popover>
	);
});

export type SearchIconButtonProps = { id?: string };
export const SearchIconButton = memo<SearchIconButtonProps>(({ id }) => {
	const [isOpen, setIsOpen] = useState(false);

	const Icon = isOpen ? Icons.Close : Icons.Search;

	return (
		<SearchPopover isOpen={isOpen} onOpenChange={setIsOpen}>
			<Popover placement='bottom' content={'Em desenvolvimento'}>
				<Button
					circle
					id={id}
					className='bt-pesquisa'
					disabled
					type='text'
					variant='default'
					icon={<Icon variant='outlined' />}
				/>
			</Popover>
		</SearchPopover>
	);
});
