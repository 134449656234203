export enum TipoDadoLogEnum {
	Processo = 2,
	Obrigacao = 6,
	Anexo = 7,
	Ocorrencia = 8,
	Origem = 9,
	Classificacao = 10,
	Usuario = 12,
	Acesso = 13,
}
