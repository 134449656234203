export enum PerfilFuncionario {
	Comum = 0,
	AdministradorEmpresa = 1,
	AdministradorSistema = 2,
}

export function getPerfilFuncionarioDescricao(funcao: PerfilFuncionario): string {
	switch (funcao) {
		case PerfilFuncionario.Comum:
			return 'Comum';
		case PerfilFuncionario.AdministradorEmpresa:
			return 'Administrador Empresa';
		case PerfilFuncionario.AdministradorSistema:
			return 'Administrador Sistema';
		default:
			return 'Desconhecido';
	}
}
